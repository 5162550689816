import React from 'react';
import PropTypes from 'prop-types';

import './input.style.scss';
import {
  checkNumericOnInputNumber,
  checkDecimalRegexTwoDigits,
  checkDigitRegex,
} from 'utils/tools_functions';

const Input = ({
  defaultStyle,
  disabled,
  name,
  required,
  type,
  value,
  onChange,
  color,
  placeholder,
  dataType,
  readOnly,
}) => {
  return (
    <input
      // OnKeyDown is use here because it's call before onChange
      onKeyDown={(e) => checkNumericOnInputNumber(e)}
      onChange={(e) => {
        if (checkDecimalRegexTwoDigits(e) && checkDigitRegex(e)) {
          onChange(e.target.value);
        } else {
          e.preventDefault();
        }
      }}
      value={value}
      className={disabled ? `disabled ${defaultStyle}` : `${defaultStyle}`}
      disabled={disabled}
      name={name}
      id={name}
      required={required}
      type={type}
      style={{ borderColor: color }}
      placeholder={placeholder}
      aria-label={name}
      data-type={dataType}
      readOnly={readOnly}
    />
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultStyle: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  placeholder: PropTypes.string,
};

Input.defaultProps = {
  defaultStyle: '',
  disabled: false,
  name: '',
  required: false,
  type: 'text',
  color: '',
  placeholder: '',
};

export default Input;
