import { createTheme } from '@mui/material';

export const customButtonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#FFF',
          backgroundColor: '#277134',
          borderRadius: '4px',
          padding: '12px 22px',
          fontSize: '15px',
          fontFamily: 'Rubik-Medium',
          boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
          textTransform: 'uppercase',
          letterSpacing: '0.5px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          transition: '0.1s',
          '&:hover': {
            backgroundColor: '#e69636',
          },
        },
      },
    },
  },
});

export const customInvertedButtonTheme = createTheme(customButtonTheme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#e69636',
          '&:hover': {
            backgroundColor: '#277134',
          },
        },
      },
    },
  },
});
