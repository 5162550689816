import * as React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useSingleClickEditDataGrid from '../../components/pages/Exploitation/Comparator/useSingleClickEditDataGrid';
import { customForecastDataGridTheme } from './CustomForecastDataGridUtils';
import {
  CustomToolbar,
  DataGridCheckbox,
  customToolBarStyle,
} from 'utils/Datagrid/CustomGenericDataGridUtils';
import localeTextConstants from './CustomDataGridLocaleTextConstants';

export default function CustomForecastDataGrid({
  rows,
  columns,
  processRowUpdate,
  loading,
  onRowSelectionModelChange,
  rowSelectionModel,
  sortModel,
}) {
  const [cellModesModel, handleCellClick, handleCellModesModelChange] =
    useSingleClickEditDataGrid();

  const groupingColDef = {
    width: 5,
    valueFormatter: () => '',
    headerName: '',
    field: 'group',
    align: 'right',
    hideDescendantCount: true,
  };

  const getTreeDataPath = (row) => row.parentId;

  return (
    <Box
      sx={{
        height: '70%',
      }}
    >
      <ThemeProvider theme={customForecastDataGridTheme}>
        <DataGridPro
          rows={rows}
          columns={columns}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.log(error)}
          localeText={localeTextConstants}
          getRowClassName={(params) => {
            if (params.row.parentId.length > 1) {
              return 'MuiDataGrid-ParcelChild--row';
            }

            return params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd';
          }}
          getRowHeight={() => 'auto'}
          loading={loading}
          disableColumnMenu
          autoHeight
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            onRowSelectionModelChange(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick
          disableColumnResize={true}
          disableColumnReorder={true}
          sortModel={sortModel}
          slots={{
            Checkbox: DataGridCheckbox,
            toolbar: CustomToolbar,
          }}
          slotProps={{
            basePopper: {
              sx: customToolBarStyle,
            },
          }}
          hideScrollbar
          isCellEditable={(params) => {
            if (params.field === 'forecast') {
              return params.row.fixed ? false : params.row.parentId.length > 1 ? false : true;
            }
          }}
          groupingColDef={groupingColDef}
          treeData
          getTreeDataPath={getTreeDataPath}
          isRowSelectable={(params) => params.row.parentId.length === 1}
          defaultGroupingExpansionDepth={-1}
        />
      </ThemeProvider>
    </Box>
  );
}
