import React from 'react';

import actionsProps from 'components/propTypes/actions';
import coProductProps from 'components/propTypes/coProduct';

import ActionList from 'components/generics/Table/ActionList';

const CoProductTableRow = ({ data, actions }) => (
  <tr>
    <td>{data.category.displayName}</td>
    <td>
      {data.coProducts.map((coProduct, index) =>
        data.coProducts.length > index + 1 ? coProduct.displayName + ', ' : coProduct.displayName
      )}
    </td>
    <td>
      <ActionList data={data} actions={actions} />
    </td>
  </tr>
);

CoProductTableRow.propTypes = {
  data: coProductProps,
  actions: actionsProps,
};

CoProductTableRow.defaultProps = {
  data: {},
  actions: [],
};

export default CoProductTableRow;
