import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Contraintes from './Contraintes';
import KPI from './KPI';
import Cooperative from './Cooperative';

const Administration = () => {
  return (
    <div className="Administration">
        <Routes>
          <Route path="/" element={
           <Navigate to="Cooperative" />
          }/>
          <Route path="Cooperative" element={<Cooperative />} />
          <Route path="KPI" element={<KPI />} />
          <Route path="Contraintes" element={<Contraintes />} />
          <Route element={
            <Navigate to="/*" />
          }/>
        </Routes>
    </div>
  );
};

export default Administration;
