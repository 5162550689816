import { toLowerCaseWithFirstLetterUpper } from '../Generics/utilsFunctions';
import fleche from 'assets/images/fleche.png';

const ObligationRestrictionTable = ({ truncatedObligationRestriction, messageDuplicate }) => {
  return (
    <article className="pdf-page">
      <div className="tableau_center">
        <h6 className="title_h6">
          {messageDuplicate !== ''
            ? `OBLIGATION ET RESTRICTION ${messageDuplicate}`
            : `OBLIGATION ET RESTRICTION`}
        </h6>
        <table className="table">
          <thead className="table_head">
            <tr className="table_tr" key="trHead">
              <th className="column_name" key="name">
                Parcel / cultures
              </th>
              <th className="column_name" key="campagne">
                Campagnes
              </th>
              <th className="column_name" key="state">
                Etat
              </th>
            </tr>
          </thead>
          <tbody>
            {truncatedObligationRestriction?.map((parcel, index) => {
              return (
                <>
                  <tr className="table_tr" key={index + 'parcel'}>
                    <th className="head_row" key={index + 'name'} colSpan="3">
                      {parcel.name} {parcel?.suite ? '(suite)' : ''}
                    </th>
                  </tr>
                  {parcel.cultures.map((culture, idx) => {
                    let tempRows = [];
                    if (culture?.restriction.length > 0) {
                      tempRows.push(
                        <tr className="table_tr grey_row" key={index + 'restriction' + idx}>
                          <td className="value_table" key={index + 'name' + idx}>
                            <div className="inline_icon_cell">
                              <img className="cell-icon" src={fleche} alt="Logo fleche" />
                              <span>{toLowerCaseWithFirstLetterUpper(culture?.name)}</span>
                            </div>
                          </td>
                          <td className="value_table" key={index + 'campagne' + idx}>
                            {culture.restriction.map((restrict, idxRestrict) => (
                              <span key={idxRestrict}>
                                {restrict}
                                {idxRestrict !== culture.restriction.length - 1 ? '-' : ''}
                              </span>
                            ))}
                          </td>
                          <td className="value_table" key={index + 'state' + idx}>
                            interdite
                          </td>
                        </tr>
                      );
                    }
                    if (culture?.obligation.length > 0) {
                      tempRows.push(
                        <tr className="table_tr grey_row" key={idx + 'obligation'}>
                          <td className="value_table" key={idx + 'name'}>
                            <div className="inline_icon_cell">
                              <img className="cell-icon" src={fleche} alt="Logo fleche" />
                              <span>{toLowerCaseWithFirstLetterUpper(culture?.name)}</span>
                            </div>
                          </td>
                          <td className="value_table" key={idx + 'campagne'}>
                            {culture.obligation.map((oblig, idxOblig) => (
                              <span key={idxOblig}>
                                {oblig}
                                {idxOblig !== culture.obligation.length - 1 ? '-' : ''}
                              </span>
                            ))}
                          </td>
                          <td className="value_table" key={idx + 'state'}>
                            obligatoire
                          </td>
                        </tr>
                      );
                    }

                    return tempRows;
                  })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export default ObligationRestrictionTable;
