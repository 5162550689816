import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { v1 } from 'uuid';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import secteurProp from 'components/propTypes/secteur';
import exploitationProp from 'components/propTypes/exploitation';
import SearchBar from 'components/generics/SearchBar';
import { SimulationContext } from 'components/pages/Exploitation/SimulationContext';
import { sendEvent } from 'utils/Event';
import { LoadingContext } from 'utils/context';

const ExploitationHome = ({ secteurs, setExploitation, exploitation }) => {
  const [input, setInput] = useState('');
  const navigate = useNavigate();
  const [secteursDefault, setSecteursDefault] = useState(secteurs);
  const [secteursFiltered, setSecteursFiltered] = useState(secteurs);
  const { setSimulationContext } = React.useContext(SimulationContext);
  const { setFullScreen } = useContext(LoadingContext);

  /**
   * Filter on exploitation for secteurs
   * @param arr
   * @param fiterText
   * @returns
   */
  function filterExploitation(arr, fiterText) {
    const rows = [];
    arr.forEach((secteur) => {
      const filteredExp = secteur.exploitations.filter((exp) => {
        return exp.name.toLowerCase().includes(fiterText.toLowerCase());
      });

      if (filteredExp.length > 0) {
        rows.push({ id: secteur.id, name: secteur.name, exploitations: filteredExp });
      }
    });
    return rows;
  }
  useEffect(() => {
    let exploitId = localStorage.getItem('exploitation');
    if (exploitId && exploitId !== '') {
      setExploitation(+exploitId);
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (secteurs) {
      setSecteursDefault(secteurs);
      setSecteursFiltered(secteurs);
    }
  }, [secteurs]);

  /**
   * Update filtered exploitations
   * @param input
   */
  const updateInput = async (input) => {
    const filtered = filterExploitation(secteursDefault, input.target.value);
    setInput(input);
    setSecteursFiltered(filtered);
  };

  return (
    <div className="Content">
      <div className="Exploitation">
        <div className="searchbar_align">
          <SearchBar
            defaultStyle="searchbar"
            className="primary"
            input={input}
            onChange={updateInput}
            placeholder={'Rechercher dans une exploitation...'}
          />
        </div>

        {secteursFiltered.map((secteur) => (
          <div key={v1()}>
            <h2 className="title">{secteur.name}</h2>
            <div className="containerGrid">
              {secteur.exploitations.map((exp) => (
                <div
                  key={v1()}
                  onClick={() => {
                    setFullScreen(true);
                    setSimulationContext();
                    setExploitation(exp.id)
                      .then((resp) => {
                        localStorage.setItem('exploitation', exp.id);
                        toast.success(`${exp.name} selectionné`);
                        navigate('/exploitation/parcels', resp.data.data);
                      })
                      .finally(() => setFullScreen(false));
                  }}
                  className={`itemGrid${exp.id === exploitation?.id ? ' active' : ''}`}
                >
                  <p>{exp.name}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ExploitationHome.propTypes = {
  secteurs: PropTypes.arrayOf(secteurProp),
  setExploitation: PropTypes.func.isRequired,
  exploitation: exploitationProp,
};

ExploitationHome.defaultProps = {
  secteurs: [],
  exploitation: {},
};

export default ExploitationHome;
