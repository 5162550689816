// YARN
import { createTheme } from '@mui/material/styles';
// SERVICES
import { mediaQuery } from './CustomGenericDataGridUtils';

export const CustomCommonListDataGridTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: '1px solid #277134',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            border: 'none',
            outline: 'none',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
            border: 'none',
            outline: 'none',
            '& :focus-visible': {
              border: 'none',
              outline: 'none',
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            maxHeight: '75vh',
            overflowY: 'auto !important',
            overflowX: 'hidden !important',
            [`@media (max-width: ${mediaQuery.breakpoints.values.lg}px)`]: {
              maxHeight: '55vh',
            },
          },
          '& .MuiDataGrid-columnHeadersInner': { width: '100%' },
          '& .MuiDataGrid-columnHeadersInner .css-yrdy0g-MuiDataGrid-columnHeaderRow': {
            width: '100%',
          },
          '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
            backgroundColor: 'transparent ',
          },
        },
        main: {
          fontFamily: 'Rubik-Medium',
          fontSize: '16px',
          color: '#707070',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          borderBottomLeftRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#e69636',
            color: 'white',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #e69636',
          borderLeft: '1px solid #e69636',
          borderRight: '1px solid #e69636',
          fontFamily: 'Rubik',
          fontSize: '14px',
          color: '#707070',
          ':hover': {
            color: '#e69636',
            fontFamily: 'Rubik-Medium',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingBottom: '1px',
          paddingTop: '1px',
          '& li:nth-of-type(1)': {
            borderTop: '1px solid #e69636',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: '#e69636 !important',
          '&.Mui-checked': {
            color: '#277134 !important',
            '&.MuiDataGrid-columnHeaderTitleContainer': {
              color: '#277134 !important',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'unset !important',
          borderWidth: '0px !important',
        },
      },
    },
  },
});

export const CustomCommonListDataGridThemeWithCursorPointer = createTheme(
  CustomCommonListDataGridTheme,
  {
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
          },
        },
      },
    },
  }
);

export const CustomLogoCell = (logo: string | null, name: string | null): React.JSX.Element => {
  return (
    <>
      {logo ? (
        <img className="structure-data-grid__img" src={logo} alt={`Logo ${name}`} />
      ) : (
        <div>-</div>
      )}
    </>
  );
};
