import API from 'services/API';

export const getSecteurInformations = (id) => API.get(`/sectors/${id}`);

// CULTURES

export const getCultures = (id) => API.get(`/sectors/${id}/cultures`);

export const updateCulture = (id, data, idCulture) =>
  API.patch(`/sectors/${id}/cultures/edit/${idCulture}`, data);

export const resetSectorCultures = (sector) => API.patch(`/sectors/${sector.id}/cultures/reset`);

// ROTATE EFFECTS

export const getSecteurCultures = (id) => API.get(`/sectors/${id}/cultures`);

// GROUND EFFECTS

export const updateSecteurGroundEffect = (data) =>
  API.patch(`/sectors/${data.secteurId}/ground-type-effect/${data.id}`, {
    effect: +data.effect,
    from: data?.from,
  });

// EXPLOITATIONS

export const addExploitation = (data) => API.post('/exploitations/add', data);

export const updateExploitation = (data, id) => API.patch(`/exploitations/edit/${id}`, data);

export const removeExploitation = (id) => API.delete(`/exploitations/remove/${id}`);

export const addFarmer = (farmer) => API.post('/users/add', farmer);

export const removeFarmer = (id) => API.delete(`/users/remove/${id}`);

export default {
  getSecteurInformations,
};
