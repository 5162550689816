import React from 'react';
import PropTypes from 'prop-types';

import './searchbar.style.scss';

const SearchBar = ({
  defaultStyle,
  disabled,
  name,
  required,
  type,
  onChange,
  color,
  placeholder,
}) => (
  <input
    onChange={onChange}
    className={defaultStyle}
    disabled={disabled}
    name={name}
    id={name}
    required={required}
    type={type}
    style={{ borderColor: color }}
    placeholder={placeholder}
    aria-label={name}
  />
);

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultStyle: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  defaultStyle: '',
  disabled: false,
  name: '',
  required: false,
  type: 'text',
  color: '',
  placeholder: '',
};

export default SearchBar;
