import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  surface: PropTypes.number,
  isIrrigable: PropTypes.bool,
  cultureN: PropTypes.object,
  cultureN1: PropTypes.object,
  groundType: PropTypes.object,
});
