import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useAuth from 'providers/Auth/useAuth';

import { addGroundType, updateGroundType } from 'services/API/Cooperative';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';

import { inputRequired } from 'components/generics/Validators/validator';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { LoadingContext } from 'utils/context';
import { displayMessage } from 'utils/tools_functions';

const DEFAULT_FORM = { name: '' };

const GroundTypeForm = ({ onSubmitSuccess, edit }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState(edit || DEFAULT_FORM);
  const [loading, setLoading] = useState(false);
  const setTextLoader = useContext(LoadingContext)
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired]
    }
  });

  const onSubmit = (e) => {
    errorsFormDefault.formError = false;
    setErrorsFormDefault({ ...validateFormData(formData, errorsFormDefault) });
    if (e) e.preventDefault();
    if (!errorsFormDefault.formError) {
      setLoading(true);
      if (edit) {
        setTextLoader.customText = displayMessage('UPDATE_GROUNDTYPE_LOADER');
        updateGroundType(formData)
          .then(() => {
            toast.success(`Type de sol ${formData.name} mis à jour`);
            setFormData(DEFAULT_FORM);
            onSubmitSuccess();
          })
          .catch((err) => toast.error(err.response.data['message']));
      } else {
        setTextLoader.customText = displayMessage('CREATE_GROUNDTYPE_LOADER');
        let cooperativeId = -1;
        if (user?.role === 'SUPER_ADMIN')
          cooperativeId = Number(localStorage.getItem('cooperative'));
        addGroundType(formData, cooperativeId)
          .then(() => {
            toast.success(`Type de sol ${formData.name} créé`);
            setFormData(DEFAULT_FORM);
            onSubmitSuccess();
          })
          .catch((err) => toast.error(err.response.data['message']));
      }
    }
    setLoading(false);
  };

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  return (
    <>
      <h1>{edit ? 'Modifier' : 'Ajouter'} un type de sol</h1>
      <form onSubmit={onSubmit}>
      {errorsFormDefault?.name.message && <p className="errorForm">{errorsFormDefault?.name?.message}</p>}
        <InputLabel
          name="name"
          value={formData.name}
          onChange={(e) => {
            updateForm('name', e);
          }}
          defaultStyle="secondary"
          placeholder="Entrez un nom"
          label="Nom"
          disabled={loading}
        />
        <Button
          defaultStyle="m-auto d-block form"
          type="submit"
          text={edit ? 'Enregistrer' : 'Ajouter'}
          disabled={loading}
        />
      </form>
    </>
  );
};

GroundTypeForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  edit: PropTypes.oneOf([PropTypes.shape({ name: PropTypes.string }), null]).isRequired,
};

GroundTypeForm.defaultProp = {
  edit: null,
};

export default GroundTypeForm;
