// REACT
import React, { useState, useContext, SyntheticEvent } from 'react';

// YARN
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { Clear } from '@mui/icons-material';

// SERVICES
import {
  checkTypeStringRegexDatagrid,
  displayErrorMessage,
  displayMessage,
} from 'utils/tools_functions';
import { inputRequired, inputVerifyMail } from 'components/generics/Validators/validator';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { LoadingContext } from 'utils/context';
import { customDialogCommonTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_culture';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { addTechnician, updateTechnician } from 'services/API/Cooperative';

// Interface
import { IFormErrorObjects, IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';
import { IErrorResponse } from '../../../generics/Interface/Commons/IErrorResponse';

type FormDataType = {
  email: string;
  firstname: string;
  lastname: string;
};

const DEFAULT_FORM: FormDataType = { email: '', firstname: '', lastname: '' };

type AddEditTechnicianModalParams = {
  setEditData: React.Dispatch<React.SetStateAction<FormDataType | null>>;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitSuccess: () => void;
  secteur: number | null;
  edit?: FormDataType | null;
  isEdit: boolean;
};

const AddEditTechnician = ({
  setEditData,
  modal,
  setModal,
  onSubmitSuccess,
  secteur,
  edit,
  isEdit,
}: AddEditTechnicianModalParams): React.JSX.Element => {
  const [formData, setFormData] = useState<FormDataType>(edit || DEFAULT_FORM);
  const [loading, setLoading] = useState(false);
  const cooperativeId = Number(localStorage.getItem('cooperative'));
  const setTextLoader = useContext(LoadingContext);
  const [errorsFormTechnician, setErrorsFormTechnician] = useState<IFormErrors>({
    formError: false,
    firstname: {
      message: '',
      validator: [inputRequired],
    },
    lastname: {
      message: '',
      validator: [inputRequired],
    },
    email: {
      message: '',
      validator: [inputRequired, inputVerifyMail],
    },
  });

  const onSubmit = async (e: SyntheticEvent) => {
    errorsFormTechnician.formError = false;
    setErrorsFormTechnician({ ...validateFormData(formData, errorsFormTechnician) });
    if (e) e.preventDefault();
    if (!errorsFormTechnician.formError) {
      setLoading(true);
      setModal(false);
      if (edit) {
        setTextLoader.customText = displayMessage('UPDATE_TECHNICIAN_LOADER');
        try {
          await updateTechnician(
            cooperativeId ? { ...formData, cooperative: cooperativeId } : formData
          );
          toast.success(`Utilisateur ${formData.email} mis à jour`);
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        } catch (err) {
          const apiError = err as IErrorResponse;
          if (apiError?.response?.status === 409) {
            return toast.error(displayErrorMessage('ERR_UPDATE_EXISTING_EMAIL', formData as any));
          }
          toast.error(apiError.message);
        }
      } else {
        secteur
          ? (setTextLoader.customText = displayMessage('ADD_TECHNICIAN_TO_SECTOR_LOADER'))
          : (setTextLoader.customText = displayMessage('ADD_TECHNICIAN_TO_COOP_LOADER'));
        const user = {
          ...{ sectors: secteur ? [secteur] : [] },
          ...{ role: ['ROLE_TECHNICIAN'] },
          ...formData,
        };
        try {
          await addTechnician(cooperativeId ? { ...user, cooperative: cooperativeId } : user);
          toast.success(`Utilisateur ${formData.email} créé`);
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        } catch (err) {
          const apiError = err as IErrorResponse;
          if (apiError?.response?.status === 409) {
            return toast.error(displayErrorMessage('ERR_ADD_EXISTING_EMAIL', formData as any));
          }
          toast.error(apiError.message);
        }
      }
    }
    setLoading(false);
  };

  const updateForm = (key: string, value: string) => setFormData({ ...formData, [key]: value });
  return (
    <ThemeProvider theme={customDialogCommonTheme}>
      <Dialog
        maxWidth="md"
        fullWidth
        open={modal}
        onClose={() => {
          setModal(false), setEditData(null);
        }}
      >
        <div className="add_culture_container">
          <Box>
            <DialogTitle>
              {!isEdit ? 'Ajouter un technicien' : 'Modifier un technicien'}
            </DialogTitle>
            <Clear
              className="close-icon"
              onClick={() => {
                setModal(false), setEditData(null);
              }}
            />
          </Box>
          <DialogContent>
            <Box id="custom-box-content">
              <Box>
                <InputLabel id="nameLabel" htmlFor="email">
                  E-mail<span className="label_asterisk">*</span>
                </InputLabel>
                <TextField
                  id="email"
                  value={formData.email}
                  onChange={(e) => {
                    updateForm('email', e.target.value);
                  }}
                  placeholder="Entrez une adresse mail"
                  disabled={loading}
                  className={
                    (errorsFormTechnician['email'] as IFormErrorObjects)?.message
                      ? 'MuiErrorForm'
                      : ''
                  }
                  error={
                    (errorsFormTechnician['email'] as IFormErrorObjects)?.message ? true : false
                  }
                  helperText={(errorsFormTechnician['email'] as IFormErrorObjects)?.message}
                />
              </Box>
              <Box>
                <InputLabel id="firstnameLabel" htmlFor="firstname">
                  Prénom<span className="label_asterisk">*</span>
                </InputLabel>
                <TextField
                  id="firstname"
                  value={formData.firstname}
                  onChange={(e) => {
                    updateForm('firstname', checkTypeStringRegexDatagrid(e.target.value));
                  }}
                  placeholder="Entrez un prénom"
                  disabled={loading}
                  className={
                    (errorsFormTechnician['firstname'] as IFormErrorObjects)?.message
                      ? 'MuiErrorForm'
                      : ''
                  }
                  error={
                    (errorsFormTechnician['firstname'] as IFormErrorObjects)?.message ? true : false
                  }
                  helperText={(errorsFormTechnician['firstname'] as IFormErrorObjects)?.message}
                />
              </Box>
              <Box>
                <InputLabel id="lastnameLabel" htmlFor="lastname">
                  Nom<span className="label_asterisk">*</span>
                </InputLabel>
                <TextField
                  id="lastname"
                  value={formData.lastname}
                  onChange={(e) => {
                    updateForm('lastname', checkTypeStringRegexDatagrid(e.target.value));
                  }}
                  placeholder="Entrez un nom"
                  disabled={loading}
                  className={
                    (errorsFormTechnician['lastname'] as IFormErrorObjects)?.message
                      ? 'MuiErrorForm'
                      : ''
                  }
                  error={
                    (errorsFormTechnician['lastname'] as IFormErrorObjects)?.message ? true : false
                  }
                  helperText={(errorsFormTechnician['lastname'] as IFormErrorObjects)?.message}
                />
              </Box>
              <ThemeProvider theme={customButtonTheme}>
                <Box>
                  <Button onClick={onSubmit} disabled={loading}>
                    Enregistrer
                  </Button>
                </Box>
              </ThemeProvider>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddEditTechnician;
