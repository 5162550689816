// REACT
import React, { useState, useEffect } from 'react';

// YARN
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

// SERVICES
import Button from 'components/generics/Button';
import dropletLogo from 'assets/images/laissez-tomber.png';
import BooleanCheckbox from 'components/generics/BooleanCheckbox';
import './CulturesMapTab.style.scss';

// INTERFACES
import { IParcelSimu } from '../Interface/Simulation/IParcelSimu';

type ResultsInfoParams = {
  resultsInfo: IParcelSimu;
  cultures: IParcelSimu[];
  year: number;
  updateFromMap: (
    parcelId: number,
    previousCultureId: number,
    cultureSelected: number,
    year: number,
    irrigationChecked: boolean
  ) => void;
};

const ResultsInfo = ({
  resultsInfo,
  cultures,
  year,
  updateFromMap,
}: ResultsInfoParams): React.JSX.Element => {
  const checkCultureByYear = () => {
    const culture = resultsInfo.cultures.find((culture) => culture.year === year);
    if (culture) {
      return culture.id;
    }
    return resultsInfo.cultureN.id;
  };
  const [currentCulture, setCurrentCulture] = useState<number>(checkCultureByYear());
  const [irrigationChecked, setChecked] = useState<boolean>(false);
  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(false);
  const [isAllIrrigable, setIsAllIrrigable] = useState<boolean>(false);

  const handleCultureChange = (event: SelectChangeEvent<number>) => {
    setCurrentCulture(event.target.value as number);
  };
  const handleUpdate = (
    parcelId: number,
    previousCultureId: number,
    cultureSelected: number,
    year: number,
    irrigationChecked: boolean
  ) => {
    updateFromMap(parcelId, previousCultureId, cultureSelected, year, irrigationChecked);
  };

  const filteredCultures = resultsInfo.isIrrigableOnly
    ? cultures
    : cultures.filter((culture) => culture.isIrrigableOnly === false);

  useEffect(() => {
    if (currentCulture) {
      const selectedCulture = cultures.find((culture) => culture.id === currentCulture);
      if (selectedCulture?.isIrrigableOnly) {
        setIsAllIrrigable(true);
        setIsSwitchActive(false);
      } else if (!resultsInfo.isIrrigableOnly && !selectedCulture?.isIrrigableOnly) {
        setIsAllIrrigable(false);
        setIsSwitchActive(false);
      } else setIsSwitchActive(true);
    }
  }, [currentCulture]);

  return (
    <div className="update_modal">
      <div className="content">
        <h1>{resultsInfo?.name} </h1>
        <p>
          <span>Surface :</span>
          <span>{resultsInfo?.surface}</span>
        </p>
        {resultsInfo?.groupSurface && (
          <p>
            <span>Surface du regroupement :</span>
            <span>{Number(resultsInfo?.groupSurface.toFixed(2))}</span>
          </p>
        )}
        <p>
          <span>
            {resultsInfo?.cultureN?.year - 1} - {resultsInfo?.cultureN?.year} :
          </span>
          <span>{resultsInfo?.cultureN?.name}</span>
        </p>
        <p>
          {resultsInfo?.cultureN1?.year - 1} - {resultsInfo?.cultureN1?.year} :
          <span>{resultsInfo?.cultureN1?.name}</span>
        </p>
        <p>
          <span>Type de sol : </span>
          <span> {resultsInfo?.groundType?.name}</span>
        </p>
        <p>
          <span>Parcelle irrigable :</span>
          <span>{resultsInfo?.isIrrigableOnly ? 'Oui' : 'Non'}</span>
        </p>
        {resultsInfo?.groupParcelNames && (
          <Stack
            spacing={1}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            className="result-info-stack"
          >
            <span>Regroupée avec :</span>
            {resultsInfo?.groupParcelNames.map((parcelName, index) => (
              <Chip
                key={index}
                label={parcelName}
                variant="outlined"
                className="result-info-chip"
              />
            ))}
          </Stack>
        )}
        <h1>
          {year - 1} - {year}
        </h1>
        <div className="results_modal_form">
          {!isAllIrrigable && (
            <div>
              {!isSwitchActive ? (
                <div className="irrigation_disabled">
                  <BooleanCheckbox checked={false} onChange={() => {}} label="Culture à irriguer" />
                </div>
              ) : (
                <BooleanCheckbox
                  checked={irrigationChecked}
                  onChange={() => {
                    setChecked(!irrigationChecked);
                  }}
                  label="Culture à irriguer"
                />
              )}
            </div>
          )}

          {isAllIrrigable && (
            <div>
              <img src={dropletLogo} alt="Logo irrigation" style={{ width: '10%' }} />
              <span>Irrigation Obligatoire</span>
            </div>
          )}

          <Select
            labelId="cultureList"
            id="cultureList"
            label="Culture"
            value={currentCulture}
            onChange={handleCultureChange}
          >
            {filteredCultures.map((culture) => (
              <MenuItem key={culture.id} value={culture.id}>
                {culture.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className="update_button">
          <Button
            type="submit"
            text="Valider"
            onClick={() =>
              handleUpdate(
                resultsInfo?.id,
                resultsInfo?.cultures.find((c) => c.year === year)?.id ?? 0,
                currentCulture,
                year,
                irrigationChecked
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ResultsInfo;
