// REACT
import React, { useState } from 'react';

// YARN
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';

// SERVICES
import dropletLogo from 'assets/images/laissez-tomber.png';
import logoCover from 'assets/images/logo-cover-min.png';
import { StyledTooltip } from 'utils/Datagrid/CustomGenericDataGridUtils';

// INTERFACES
import { IParcelCulture } from 'components/generics/Interface/Api/Response/Exploitation/Parcels/IParcelCulture';
import { GridRowModel, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-pro';

export const CustomSimulationCell = (
  params: GridValidRowModel,
  rows: GridRowModel[],
  irrigationInformation: { irrigationDistribution: number[] },
  editedSynthesisCellsRef: React.MutableRefObject<string[]>
): React.JSX.Element => {
  const rowIndex = rows?.map((row) => row.id)?.indexOf(params?.id);
  const isIrrigated =
    irrigationInformation &&
    'irrigationDistribution' in irrigationInformation &&
    irrigationInformation?.irrigationDistribution[rowIndex] > 0
      ? true
      : false;
  const cover = params?.value?.cover;
  const isCover = cover === 0 || cover === 1;

  let isCellEdited = false;
  if (editedSynthesisCellsRef?.current.length > 0) {
    if (editedSynthesisCellsRef.current.includes(`${params?.id.toString()}-${params?.field}`)) {
      isCellEdited = true;
    }
  }

  return (
    <>
      <Box className={`cell-box ${isCellEdited ? 'MuiCell--editing' : ''}`}>
        <div
          className="cell-main-content"
          style={{ marginTop: cover !== -1 || isIrrigated ? 15 : 0 }}
        >
          <span>{params?.value?.name}</span>
          <div className="cultures-icons-wrapper">
            {isIrrigated && (
              <div className="culture-infos-icon">
                <img src={dropletLogo} alt="Logo irrigation" className="dropletLogo" />
              </div>
            )}
            {isCover && (
              <div className="culture-infos-icon">
                <StyledTooltip
                  title={`${
                    cover === 0
                      ? 'Autre couvert possible avant la culture '
                      : 'Couvert à base légumineuse avant la culture'
                  }`}
                  placement="top"
                >
                  <img
                    src={logoCover}
                    alt="Logo couvert"
                    className={`${
                      cover === 0 ? 'disabled-cover dropletLogo' : 'dropletLogo not-disabled-cover'
                    }`}
                  />
                </StyledTooltip>
              </div>
            )}
          </div>
        </div>
        <EditIcon sx={{ padding: '0px !important' }} />
      </Box>
    </>
  );
};

type CustomEditSelectCellParams = {
  params: GridRowModel;
  cultures: IParcelCulture[];
};

export const CustomEditSelectCell = ({
  params,
  cultures,
}: CustomEditSelectCellParams): React.JSX.Element => {
  const { id, field, value } = params;
  const [checked, setChecked] = useState(value?.cover === 1);
  const apiRef = useGridApiContext();
  const cover = params.value?.cover;
  const isCover = cover === 0 || cover === 1;

  const handleCultureChange = (e: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = e;

    const newValue = {
      id: value,
      name: cultures?.find((culture) => culture.id === +value)?.name,
      cover,
      cellId: `${id.toString()}-${field}`,
      isEdited: true,
    };

    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const handleCoverChange = (isChecked: boolean) => {
    setChecked(isChecked);

    const newValue = {
      ...value,
      cover: isChecked ? 1 : 0,
      cellId: `${id.toString()}-${field}`,
      isEdited: true,
    };

    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Box className="parcel-synthesis-select-wrapper">
      <Select
        defaultOpen={false}
        value={value.id}
        onChange={(e) => handleCultureChange(e)}
        className="parcel-synthesis-select"
        IconComponent={KeyboardArrowDownIcon}
      >
        {cultures?.map((culture) => (
          <MenuItem key={culture.id} value={culture.id}>
            {culture.name}
          </MenuItem>
        ))}
      </Select>
      {value?.cover !== -1 && (
        <div className="wrapper-cover">
          {isCover && (
            <Box className="wrapper-cover-icon" onClick={() => handleCoverChange(!checked)}>
              <img
                src={logoCover}
                alt="Logo couvert"
                className={`${cover === 0 ? 'disabled-cover' : ''}`}
              />
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};
