// NO REVIEW

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import InputLabel from 'components/generics/InputLabel';
import Button from 'components/generics/Button';

import groundTypeProp from 'components/propTypes/groundType';
import cultureProp from 'components/propTypes/cultureProp';
import { updateSecteurGroundEffect } from 'services/API/Secteur';
import { displayErrorMessage, displayMessage, emptyFieldToZero } from 'utils/tools_functions';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { LoadingContext } from 'utils/context';
import { useContext } from 'react';
import {
  inputIntegerValidatorEffect,
  inputNumberRequired,
} from 'components/generics/Validators/validator';

const DEFAULT_FORM = { effect: '', culture: '', groundType: '' };

const GroundTypeEffectForm = ({ onSubmitSuccess, culture, groundTypes, edit, sectorId }) => {
  const [formData, setFormData] = useState(edit || DEFAULT_FORM);
  const [loading, setLoading] = useState(false);
  const setTextLoader = useContext(LoadingContext);
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    effect: {
      message: '',
      validator: [inputNumberRequired, inputIntegerValidatorEffect],
    },
  });

  useEffect(() => {
    !edit && setFormData({ effect: '', culture, groundType: groundTypes[0].id });
  }, [culture, groundTypes]);

  const onSubmit = (e) => {
    errorsFormDefault.formError = false;
    setErrorsFormDefault({ ...validateFormData(formData, errorsFormDefault) });
    if (e) e.preventDefault();
    if (!errorsFormDefault.formError) {
      if (edit) {
        formData.secteurId = sectorId;
        setTextLoader.customText = displayMessage('UPDATE_GROUNDTYPE_EFFECT_LOADER');
        updateSecteurGroundEffect(formData)
          .then(() => {
            toast.success('Effet mis à jour');
            onSubmitSuccess();
          })
          .catch((err) => toast.error(displayErrorMessage('ERR_UPDATE_EFFECT')));
      }
    }
    setLoading(false);
  };

  const updateForm = (key, value) => {
    setFormData({ ...formData, [key]: emptyFieldToZero(value) });
  };

  return (
    <>
      <h1>Modifier un effet de type de sol</h1>
      <form onSubmit={onSubmit}>
        {errorsFormDefault?.effect.message && (
          <p className="errorForm">{errorsFormDefault?.effect?.message}</p>
        )}
        <InputLabel
          name="effect"
          value={formData.effect}
          onChange={(e) => {
            updateForm('effect', e);
          }}
          dataType="number"
          defaultStyle="secondary"
          placeholder="Entrez l'effet"
          label="Effet"
          disabled={loading}
        />
        <Button
          defaultStyle="m-auto d-block form"
          type="submit"
          text="Enregistrer"
          disabled={loading}
        />
      </form>
    </>
  );
};

GroundTypeEffectForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  culture: cultureProp.isRequired,
  edit: PropTypes.oneOf([PropTypes.shape({ name: PropTypes.string }), null]),
  groundTypes: PropTypes.arrayOf(groundTypeProp),
  sectorId: PropTypes.number,
};

GroundTypeEffectForm.defaultProps = {
  edit: null,
  groundTypes: [],
  sectorId: -1,
};

export default GroundTypeEffectForm;
