import { emptyCulture, getColorByName, getisArableByName } from 'utils/culturesColors';

export const sortList = (culturesByYear, year) => {
  const sortedList = [...culturesByYear];
  return sortedList
    ?.find((e) => e.year === year)
    .cultures?.sort((a, b) => a.name.localeCompare(b.name));
};

export const parcelHoverCultureOpacity = (cultureName, parcelHoverCultureName) => {
  if (parcelHoverCultureName) {
    return cultureName === parcelHoverCultureName ? '1' : '0.3';
  }
  return 'unset';
};

export const cultureHover = (cultureName, parentCallback) => {
  parentCallback(cultureName);
};

export const loadResultMapTabData = (
  data,
  setCulturesByYear,
  setTotalSurface,
  culturesColors,
  minYear,
  maxYear,
  fromParcels = false
) => {
  let totalSurfaceIncrement = 0;
  let allCulturesByYear = [];

  function addCulture(culture, parcel) {
    if (culture) {
      const currentYear = allCulturesByYear?.find((e) => e?.year === culture?.year);
      if (!currentYear) {
        allCulturesByYear?.push({
          year: culture?.year,
          cultures: [],
          totalArableSurface: 0,
        });
        addNewCultureByYear(allCulturesByYear, culture, parcel);
      } else {
        const cultureInList = currentYear.cultures?.find(
          (currentYearCulture) => currentYearCulture.name === culture?.name
        );
        if (!cultureInList) {
          addNewCultureByYear(allCulturesByYear, culture, parcel);
        } else {
          let cultureAllParcelSurface = parcel.surface;
          allCulturesByYear
            .find((culturesByYear) => culturesByYear.year === culture.year)
            .cultures.forEach((c) => {
              if (c.name == culture.name) {
                c.surface = cultureAllParcelSurface + c.surface;
              }
            });
        }
      }
    }
  }

  function addNewCultureByYear(allCulturesByYear, culture, parcel) {
    allCulturesByYear
      ?.find((e) => e.year === culture?.year)
      .cultures?.push({
        name: culture?.name,
        color: getColorByName(culture?.name, culturesColors),
        year: culture?.year,
        surface: parcel.surface,
        isArable: getisArableByName(culture?.name, culturesColors),
        id: culture.id,
      });
  }

  if (fromParcels) {
    data.map((parcel) => {
      if (parcel) {
        if (parcel.cultureN?.year) {
          addCulture(parcel.cultureN, parcel);
        } else {
          addCulture({ ...emptyCulture, year: maxYear }, parcel);
        }
        if (parcel.cultureN1?.year) {
          addCulture(parcel.cultureN1, parcel);
        } else {
          addCulture({ ...emptyCulture, year: minYear }, parcel);
        }
        totalSurfaceIncrement = totalSurfaceIncrement + Number(parcel.surface);
      }
    });
  } else {
    data.map((parcel) => {
      if (parcel.cultureN?.year) {
        addCulture(parcel.cultureN, parcel);
        parcel.cultures.map((culture, i) => {
          let newYear = parcel.cultureN.year + (i + 1);
          culture.year = newYear;
          addCulture(culture, parcel);
        });
      }
      totalSurfaceIncrement = totalSurfaceIncrement + Number(parcel.surface);
    });
  }

  allCulturesByYear.forEach((year) => {
    let totalArableSurfaceForYear = 0;
    year.cultures.forEach((culture) => {
      if (culture.isArable) {
        totalArableSurfaceForYear = totalArableSurfaceForYear + culture.surface;
      }
    });
    year.totalArableSurface = totalArableSurfaceForYear;
  });

  setCulturesByYear(allCulturesByYear);
  setTotalSurface(totalSurfaceIncrement);
};
