const { createTheme } = require('@mui/material');

export const customDialogAddCultureTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            border: '3px solid #e69636 !important',
            borderRadius: '20px',
            textAlign: 'center',
            fontSize: '18px',
            color: '#e69636 !important',
            width: '80vw',
            height: '80vh',
            maxWidth: 'unset !important',
            maxHeight: 'unset !important',
            overflowY: 'hidden',
          },
          '& .MuiDialog-paper .MuiBox-root': {
            justifyContent: 'center !important',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          },
          '& .MuiDialog-paper .MuiBox-root .MuiSvgIcon-root': {
            fontSize: '36px',
            color: '#277134 !important',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '25px',
          color: '#e69636',
          fontFamily: 'Rubik-Bold !important',
          color: '#277134 !important',
          userSelect: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          maxWidth: '100%',
          flexWrap: 'wrap',
          overflowX: 'hidden !important',
          padding: ' 20px 60px 0px 24px !important',
          '& > label': {
            padding: '10px',
            overflow: 'unset !important',
            maxWidth: '160px',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Rubik-Medium !important',
            color: '#277134 !important',
            userSelect: 'none',
          },
          '& .MuiBox-root': {
            width: '30% !important',
            minWidth: '340px !important',
            flexWrap: 'wrap',
            margin: '5px',
            marginBottom: '32px',
            label: {
              minWidth: '160px',
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
              maxWidth: '180px',
              overflow: 'unset !important',
              color: '#277134 !important',
              fontFamily: 'Rubik-Medium !important',
              textAlign: 'center',
              paddingRight: '10px',
            },
            '.MuiInputBase-root': {
              width: '50%',
              height: '55px',
              outline: '1px solid #e69636 !important',
              borderRadius: '4px !important',
              fontSize: '15px',
              ':hover': {
                outline: '2px solid #e69636 !important',
              },
              '.MuiInputBase-root': {
                width: '100% !important',
              },
              '&.Mui-error': {
                border: '2px solid #d32f2f !important',
                borderRadius: '4px !important',
                outline: 'unset !important',

                ':hover': {
                  outline: '2.5px solid #d32f2f !important',
                },
                '&.Mui-focused': {
                  outline: '2.5px solid #d32f2f !important',
                },
              },
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              outline: '2px solid #e69636 !important',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#d32f2f !important',
          marginLeft: '2px !important',
          letterSpacing: 'unset !important',
          fontStyle: 'italic',
          fontWeight: 'bold',
          textAlign: 'center',
          width: '100%',
          whiteSpace: 'pre-wrap !important',
          fontSize: '0.7rem',
          lineHeight: '1',
          position: 'absolute',
          top: '55px',
          bottom: '0px',
          right: '0px',
          left: '0px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '50%',
          '.MuiInputBase-root': {
            width: '100% !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'unset !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          maxWidth: '100%',
          whiteSpace: 'pre-wrap',
        },
      },
    },
  },
});

export const customDialogCommonTheme = createTheme(customDialogAddCultureTheme, {
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            width: '600px',
            height: 'auto',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: ' 20px 60px 0px 50px !important',
          '& #custom-box-content': {
            width: '100% !important',
            '& .MuiBox-root': {
              width: 'unset !important',
            },
            '& .MuiBox-root:last-child': {
              width: '100% !important',
              marginTop: 20,
              display: 'flex',
              justifyContent: 'center !important',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: '230px !important',
        },
      },
    },
  },
});
