import React from 'react';

import actionsProps from 'components/propTypes/actions';
import userProps from 'components/propTypes/user';

import ActionList from 'components/generics/Table/ActionList';

import { formatDate } from 'utils';

const TechnicianTableRow = ({ data, actions }) => (
  <tr>
    <td>{data.email}</td>
    <td>{data.lastname}</td>
    <td>{data.firstname}</td>
    <td>{formatDate(data.createdAt)}</td>
    <td>
      <ActionList data={data} actions={actions} />
    </td>
  </tr>
);

TechnicianTableRow.propTypes = {
  data: userProps,
  actions: actionsProps,
};

TechnicianTableRow.defaultProps = {
  data: {},
  actions: [],
};

export default TechnicianTableRow;
