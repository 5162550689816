import { useState, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import './cooperativeForm.scss';
import {
  addNewAdmin,
  addStructure,
  removeAdmin,
  updateStructure,
} from 'services/API/Administration';
import { inputRequired, inputVerifyMail } from 'components/generics/Validators/validator';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { LoadingContext } from 'utils/context';
import {
  checkTypeStringRegexDatagrid,
  displayErrorMessage,
  displayMessage,
} from 'utils/tools_functions';
import { customDialogAddEditParcelsTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_edit_parcel';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Add, Close } from '@mui/icons-material';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';

const DEFAULT_FORM = { name: '', hasRights: true, isActive: true };
const DEFAULT_ADMIN = {
  firstname: '',
  lastname: '',
  email: '',
  roles: ['ROLE_ADMIN'],
  cooperative: 0,
};

const status = [
  { active: 'Actif', status: 'Actif' },
  { active: 'Inactif', status: 'Inactif' },
];
const AddEditStructureModal = ({
  modal,
  setModal,
  onSubmitSuccess,
  isEdit,
  edit,
  getStructures,
  setEditData,
}) => {
  const [data, setData] = useState(isEdit ? edit : DEFAULT_FORM);
  const [isActive, setIsActive] = useState(
    isEdit ? (edit.status === 'Actif' ? true : false) : true
  );
  const [currentAdmins, setCurrentAdmins] = useState(
    isEdit
      ? edit.users.filter(
          (user) => user.roles?.includes('ROLE_ADMIN') || user.roles?.includes('ROLE_SUPER_ADMIN')
        )
      : []
  );
  const [avatar, setAvatar] = useState(null);
  const [newAdmin, setNewAdmin] = useState([]);
  const [addAdmin, setAddAdmin] = useState(DEFAULT_ADMIN);
  const [removeId, setRemoveId] = useState([]);
  const [loading, setLoading] = useState(false);
  const updateNewAdmin = (key, value) => setAddAdmin({ ...addAdmin, [key]: value });
  const updateForm = (key, value) => setData({ ...data, [key]: value });
  const [uploadedFile, setUploadedFile] = useState(null);
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired],
    },
  });
  const [errorsFormAdmin, setErrorsFormAdmin] = useState({
    formError: false,
    firstname: {
      message: '',
      validator: [inputRequired],
    },
    lastname: {
      message: '',
      validator: [inputRequired],
    },
    email: {
      message: '',
      validator: [inputRequired, inputVerifyMail],
    },
  });
  const setTextLoader = useContext(LoadingContext);

  const ref = useRef();

  const handleChosenImage = (e) => {
    const file = e.target.files[0];
    if (file?.size < 50000) {
      setUploadedFile(file);
      setAvatar(URL.createObjectURL(file));
    } else {
      toast.warning('La taille de votre image dépasse la limite autorisée de 50ko.');
    }
  };

  const checkDisabledAddAdminButton =
    addAdmin && Object.keys(addAdmin).length
      ? !addAdmin.firstname || !addAdmin.lastname || !addAdmin.email
        ? true
        : false
      : true;

  const handleSetActive = (e) => {
    if (e === 'Actif') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const addAdminHandle = () => {
    if (addAdmin !== DEFAULT_ADMIN) {
      errorsFormAdmin.formError = false;
      setErrorsFormAdmin({ ...validateFormData(addAdmin, errorsFormAdmin) });
      if (!errorsFormAdmin.formError) {
        const newArr = newAdmin;
        newArr.push(addAdmin);
        setNewAdmin(newArr);
        setAddAdmin(DEFAULT_ADMIN);
      }
    }
  };

  const deleteCurrentAdmin = (admin) => {
    const newDeleteArray = removeId;
    newDeleteArray.push(admin.id);
    setRemoveId(newDeleteArray);
    const newCurrentAdmins = currentAdmins.filter((currentAdmin) => currentAdmin.id !== admin.id);
    setCurrentAdmins(newCurrentAdmins);
  };

  const deleteNewAdmin = (index) => {
    const arr = [];
    for (let i = 0; i < newAdmin.length; i++) {
      if (i !== index) {
        arr.push(newAdmin[i]);
      }
    }
    setNewAdmin(arr);
  };

  const triggerShortcut = (e) => {
    if (e.keyCode === 13) {
      handleOnSubmit();
    }
  };

  const handleOnSubmit = () => {
    errorsFormDefault.formError = false;
    setErrorsFormDefault({ ...validateFormData(data, errorsFormDefault) });
    if (!errorsFormDefault.formError) {
      setLoading(true);
      setTextLoader.customText = displayMessage('UPDATE_STRUCTURE_LOADER');
      if (isEdit) {
        let formData = new FormData();
        if (uploadedFile) {
          formData.append('logo', uploadedFile);
        }
        formData.append('name', data.name);
        formData.append('hasRights', isActive);
        formData.append('isActive', isActive);
        removeId.forEach((id) => {
          removeAdmin(id)
            .then(() => {
              toast.success(`Admin supprimé`);
              getStructures();
            })
            .catch((err) => {
              toast.error(err.message);
            });
        });
        newAdmin.forEach((admin) => {
          admin.cooperative = data.id;
          addNewAdmin(admin)
            .then(() => {
              toast.success(`Admin ${admin.firstname} ${admin.lastname} créé`);
              getStructures();
            })
            .catch((err) => {
              if (err.response.data.status === 409) {
                return toast.error(displayErrorMessage('ERR_ADD_EXISTING_EMAIL', admin));
              }
              toast.error(err.response.data.message);
            });
        });
        updateStructure(formData, data.id)
          .then(() => {
            toast.success(`Structure ${data.name} mise à jour`);
            getStructures();
          })
          .catch((err) => {
            toast.error(err.message);
          });
        onSubmitSuccess();
      } else {
        let formData = new FormData();
        formData.append('logo', uploadedFile);
        formData.append('name', data.name);
        // HasRights have the same value than isActive for now
        formData.append('hasRights', isActive);
        formData.append('isActive', isActive);
        setTextLoader.customText = displayMessage('CREATE_STRUCTURE_LOADER');
        addStructure(formData)
          .then((response) => {
            toast.success(`Structure ${data.name} créée`);
            const structureId = response.data.data.id;
            newAdmin.forEach((admin) => {
              admin.cooperative = structureId;
              addNewAdmin(admin)
                .then(() => {
                  toast.success(`Admin ${admin.firstname} ${admin.lastname} créé`);
                  getStructures();
                })
                .catch((err) => {
                  if (err.response.data.status === 409) {
                    return toast.error(displayErrorMessage('ERR_ADD_EXISTING_EMAIL', admin));
                  }
                  toast.error(err.response.data.message);
                });
            });
            getStructures();
          })
          .catch((err) => {
            toast.error(err.message);
          });
        onSubmitSuccess();
      }
    }
    setLoading(false);
  };

  return (
    <ThemeProvider theme={customDialogAddEditParcelsTheme}>
      <Dialog
        maxWidth="md"
        fullWidth
        open={modal}
        ref={ref}
        PaperProps={{ ref: ref }}
        onClose={() => {
          setModal(false), setEditData(null);
        }}
        onKeyDown={(e) => triggerShortcut(e)}
        id="custom-add-dialog"
      >
        <div className="add_culture_container">
          <Box className="modal_header">
            <DialogTitle>
              {!isEdit ? 'Ajouter une structure' : 'Modifier une structure'}
            </DialogTitle>
            <ClearIcon className="close-icon" onClick={() => setModal(false)} />
          </Box>
          <DialogContent className="modal-container">
            <div className="add-header-container">
              <Box>
                <InputLabel id="nameLabel" htmlFor="name">
                  Nom<span className="label_asterisk">*</span>
                </InputLabel>
                <TextField
                  id="name"
                  onChange={(e) => {
                    updateForm('name', checkTypeStringRegexDatagrid(e.target.value));
                  }}
                  value={data.name}
                  placeholder="Nom"
                  disabled={loading}
                  className={errorsFormDefault?.name?.message ? 'MuiErrorForm' : ''}
                  error={errorsFormDefault?.name?.message ? true : false}
                  helperText={errorsFormDefault?.name?.message}
                />
              </Box>
              <Box>
                <InputLabel id="statusLabel" htmlFor="status">
                  Statut
                </InputLabel>
                <Select
                  id="status"
                  onChange={(e) => handleSetActive(e.target.value)}
                  name="status"
                  disabled={loading}
                  value={isActive === true ? 'Actif' : 'Inactif'}
                >
                  {status.map((item) => (
                    <MenuItem key={item.active} value={item.active}>
                      {item.status}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </div>
            <fieldset className="add_culture_fieldset">
              <legend className="inputLabel-admin">
                Ajouter un admin
                <ThemeProvider theme={customButtonTheme}>
                  <Button
                    onClick={() => addAdminHandle()}
                    type="button"
                    id="add_coProduct_button"
                    disabled={checkDisabledAddAdminButton}
                  >
                    Ajouter <Add id="MuiSvgAddIcon" />
                  </Button>
                </ThemeProvider>
              </legend>
              <Box className="add-admin-list">
                <Box>
                  {currentAdmins.length > 0 &&
                    currentAdmins.map((admin) => (
                      <div key={admin.id}>
                        <Button
                          variant="contained"
                          onClick={() => deleteCurrentAdmin(admin)}
                          className="admin-button"
                          disabled={loading}
                          endIcon={<Close width={13} height={13} />}
                        >
                          {`${admin.firstname} ${admin.lastname}`}
                        </Button>
                      </div>
                    ))}
                  {newAdmin.map((admin, index) => (
                    <div key={index}>
                      <Button
                        variant="contained"
                        onClick={() => deleteNewAdmin(index)}
                        className="admin-button"
                        disabled={loading}
                        endIcon={<Close width={13} height={13} />}
                      >
                        {`${admin.firstname} ${admin.lastname}`}
                      </Button>
                    </div>
                  ))}
                </Box>
              </Box>
              <>
                <Box>
                  <InputLabel name="firstnameLabel" htmlFor="firstname">
                    Prénom
                  </InputLabel>
                  <TextField
                    onChange={(e) => {
                      updateNewAdmin('firstname', checkTypeStringRegexDatagrid(e.target.value));
                    }}
                    value={addAdmin.firstname}
                    id="firstname"
                    placeholder="Prénom"
                    disabled={loading}
                    className={errorsFormAdmin?.firstname?.message ? 'MuiErrorForm' : ''}
                    error={errorsFormAdmin?.firstname?.message ? true : false}
                    helperText={errorsFormAdmin?.firstname?.message}
                  />
                </Box>
                <Box>
                  <InputLabel name="lastnameLabel" htmlFor="lastname">
                    Nom de famille
                  </InputLabel>
                  <TextField
                    onChange={(e) => {
                      updateNewAdmin('lastname', checkTypeStringRegexDatagrid(e.target.value));
                    }}
                    value={addAdmin.lastname}
                    id="lastname"
                    placeholder="Nom de famille"
                    disabled={loading}
                    className={errorsFormAdmin?.name?.message ? 'MuiErrorForm' : ''}
                    error={errorsFormAdmin?.lastname?.message ? true : false}
                    helperText={errorsFormAdmin?.lastname?.message}
                  />
                </Box>
                <Box>
                  <InputLabel name="emailLabel" htmlFor="email">
                    Email
                  </InputLabel>
                  <TextField
                    onChange={(e) => {
                      updateNewAdmin('email', e.target.value);
                    }}
                    value={addAdmin.email}
                    id="email"
                    placeholder="Email"
                    type="email"
                    disabled={loading}
                    className={errorsFormAdmin?.name?.message ? 'MuiErrorForm' : ''}
                    error={errorsFormAdmin?.email?.message ? true : false}
                    helperText={errorsFormAdmin?.email?.message}
                  />
                </Box>
              </>
            </fieldset>
            <fieldset className="add_culture_fieldset">
              <legend className="inputLabel-admin">
                Logo
                <InputLabel htmlFor="file" className="label-input-file">
                  <Input
                    id="file"
                    type="file"
                    className="input-file"
                    onChange={handleChosenImage}
                  />
                  <ThemeProvider theme={customButtonTheme}>
                    <Button
                      variant="contained"
                      component="span"
                      type="button"
                      id="add_coProduct_button"
                    >
                      Parcourir
                    </Button>
                  </ThemeProvider>
                </InputLabel>
              </legend>
              <Box className="add-logo-structure">
                <Box className="container-logo">
                  {avatar ? (
                    <Box>
                      <img src={avatar} alt="logo"></img>
                    </Box>
                  ) : data?.logo ? (
                    <img src={data?.logo} alt="logo"></img>
                  ) : (
                    <span>Aucun logo à afficher</span>
                  )}
                </Box>
              </Box>
            </fieldset>
            <Box className="button-submit-structure">
              <ThemeProvider theme={customButtonTheme}>
                <Box>
                  <Button onClick={() => handleOnSubmit()} disabled={loading}>
                    Enregistrer
                  </Button>
                </Box>
              </ThemeProvider>
            </Box>
          </DialogContent>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddEditStructureModal;
