// NO REVIEW

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { getSecteurInformations } from 'services/API/Secteur';
import { ROLE_TECHNICIAN } from 'utils/const';
import { sendEvent } from 'utils/Event';

import ExploitationForm from 'components/pages/Sector/form/Exploitations';
import ExploitationTableRow from 'components/pages/Sector/table/ExploitationTableRow';

import secteurFull from 'components/propTypes/secteurFull';

import Table from 'components/generics/Table/Table';
import IconButton from 'components/generics/IconButton';
import More from 'components/icons/More';
import SearchBar from 'components/generics/SearchBar';
import Sector from 'components/icons/Sector';
import { FormModal } from 'components/generics/Modals/FormModal';

const SecteurExploitations = ({ secteur, user }) => {
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const [input, setInput] = useState('');
  const [defaultSector, setDefaultSector] = useState(secteur);
  const [filteredSector, setFilteredSector] = useState(secteur);
  const [sector] = useState(secteur);

  const loadDataSector = (id) => {
    getSecteurInformations(id)
      .then((resp) => {
        setDefaultSector(resp.data.data);
        setFilteredSector(resp.data.data);
      })
      .catch((err) => toast.error(err.data));
  };

  const formSuccess = () => {
    setModal(false);
    setIsEdit(false);
    setEditData(null);
    loadDataSector(defaultSector.id);
  };

  /**
   * Filter on exploitation for one secteur
   * @param arr
   * @param fiterText
   * @returns filetered secteur
   */
  function filterExploitation(arr, fiterText) {
    const rows = {};
    const filteredExp = arr.exploitations.filter((exp) => {
      return exp.name.toLowerCase().includes(fiterText.toLowerCase());
    });

    if (filteredExp.length > 0) {
      rows.id = defaultSector.id;
      rows.name = defaultSector.name;
      rows.exploitations = filteredExp;
    }

    return rows;
  }

  /**
   * Update filtered exploitations
   * @param input
   */
  const updateInput = async (input) => {
    const filtered = filterExploitation(defaultSector, input.target.value);
    setInput(input);
    setFilteredSector(filtered);
  };

  useEffect(() => {
    if (!sector) {
      if (!sector && localStorage.getItem('sector')) {
        loadDataSector(+localStorage.getItem('sector'));
      } else {
        toast.info('Selectionnez un secteur');
        navigate('/sector');
      }
    } else {
      setDefaultSector(sector);
      setFilteredSector(sector);
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  const actions = [
    {
      label: 'Modifier',
      func: (e) => {
        let newE = e;
        if (!newE.farmers) {
          newE = { ...newE, farmers: [] };
        }
        if (!newE.farmersToRemove) {
          newE = { ...newE, farmersToRemove: [] };
        }
        setEditData(newE);
        setIsEdit(true);
        setModal(true);
      },
      minimalRole: ROLE_TECHNICIAN,
    },
  ];
  return (
    <div className="section">
      <div className="main_container">
        <p className="title_spacing">
          <span className="subtitle_spacing">
            <Sector width={30.0} height={30} fill="$secondary" />
          </span>
          <span className="green_text subtitle_spacing">{filteredSector?.name} </span>
        </p>

        <div className="title_searchbar">
          <h1 className="title_section">Exploitations</h1>
        </div>
        <div className="contain-header-seachbar">
          <div className="add_button_container add_button_spacing">
            <IconButton
              text="Ajouter"
              onClick={() => {
                setModal(!modal);
                setIsEdit(false);
              }}
              icon={<More width={13} height={13} />}
            />
          </div>
          <div className="searchbar_align div_searchbar_spacing">
            <SearchBar
              defaultStyle="searchbar searchbar_spacing"
              input={input}
              onChange={updateInput}
              placeholder={'Rechercher dans une exploitation...'}
            />
          </div>
        </div>

        <Table
          header={['Nom', 'Code', '']}
          data={filteredSector?.exploitations}
          RowElement={ExploitationTableRow}
          actions={actions}
        />
      </div>
      {modal && (
        <FormModal
          onClick={() => {
            setEditData(null);
            setModal(false);
          }}
          customTemplate={
            <ExploitationForm
              onSubmitSuccess={formSuccess}
              edit={editData}
              isEdit={isEdit}
              secteur={filteredSector}
              cooperativeId={user.cooperative.id}
              loadDataSector={loadDataSector}
            />
          }
        ></FormModal>
      )}
    </div>
  );
};

SecteurExploitations.propTypes = {
  secteur: secteurFull,
};

SecteurExploitations.defaultProps = {
  secteur: {},
};

export default SecteurExploitations;
