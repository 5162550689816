import React from 'react';

import Cooperative from 'components/icons/Cooperative';
import Sector from 'components/icons/Sector';
import Exploitation from 'components/icons/Exploitation';
import Edit from 'components/icons/Edit';

export const ROLE_SUPER_ADMIN = 50;
export const ROLE_ADMIN = 40;
export const ROLE_TECHNICIAN = 30;
export const ROLE_FARMER = 20;
export const ROLE_USER = 10;

export const mainNav = [
  {
    id: 0,
    text: 'Administration',
    path: 'administration',
    icon: <Edit />,
    minimalRole: ROLE_SUPER_ADMIN,
  },
  {
    id: 1,
    text: 'Structure',
    path: 'cooperative',
    icon: <Cooperative />,
    minimalRole: ROLE_ADMIN,
  },
  { id: 2, text: 'Secteurs', path: 'sector', icon: <Sector />, minimalRole: ROLE_TECHNICIAN },
  {
    id: 3,
    text: 'Exploitations',
    path: 'exploitation',
    icon: <Exploitation />,
    minimalRole: ROLE_FARMER,
  },
];

export const routing = {
  administration: [
    { path: 'administration/Cooperative', text: 'Structures', minimalRole: ROLE_SUPER_ADMIN },
    { path: 'administration/KPI', text: 'KPI', minimalRole: ROLE_SUPER_ADMIN },
    { path: 'administration/contraintes', text: 'Contraintes', minimalRole: ROLE_SUPER_ADMIN },
  ],
  cooperative: [
    { path: 'cooperative/sectors', text: 'Secteurs', minimalRole: ROLE_ADMIN },
    { path: 'cooperative/technicians', text: 'Techniciens', minimalRole: ROLE_ADMIN },
    { path: 'cooperative/cultures', text: 'Cultures', minimalRole: ROLE_ADMIN },
    { path: 'cooperative/groundTypes', text: 'Types de sol', minimalRole: ROLE_ADMIN },
    { path: 'cooperative/rotateEffects', text: 'Effets de rotations', minimalRole: ROLE_ADMIN },
    {
      path: 'cooperative/groundTypesEffects',
      text: 'Effets types de sol',
      minimalRole: ROLE_ADMIN,
    },
    { path: 'cooperative/contstaints', text: 'Contraintes Co-Produits', minimalRole: ROLE_ADMIN },
    { path: 'cooperative/import', text: 'Import', minimalRole: ROLE_ADMIN },
  ],
  sector: [
    { path: 'sector', text: 'Secteurs', minimalRole: ROLE_TECHNICIAN },
    { path: 'sector/exploitations', text: 'Exploitations', minimalRole: ROLE_TECHNICIAN },
    { path: 'sector/cultures', text: 'Cultures', minimalRole: ROLE_TECHNICIAN },
    { path: 'sector/rotateEffects', text: 'Effets de rotations', minimalRole: ROLE_TECHNICIAN },
    {
      path: 'sector/groundTypesEffects',
      text: 'Effets types de sol',
      minimalRole: ROLE_TECHNICIAN,
    },
    { path: 'sector/import', text: 'Import', minimalRole: ROLE_TECHNICIAN },
  ],
  exploitation: [
    { path: 'exploitation', text: 'Exploitations', minimalRole: ROLE_FARMER },
    { path: 'exploitation/parcels', text: 'Parcelles', minimalRole: ROLE_FARMER },
    { path: 'exploitation/cultures', text: 'Cultures', minimalRole: ROLE_FARMER },
    { path: 'exploitation/rotateEffects', text: 'Effets de rotations', minimalRole: ROLE_FARMER },
    {
      path: 'exploitation/groundTypesEffects',
      text: 'Effets types de sol',
      minimalRole: ROLE_FARMER,
    },
    { path: 'exploitation/simulation', text: 'Simulation', minimalRole: ROLE_FARMER },
    { path: 'exploitation/import', text: 'Import', minimalRole: ROLE_FARMER },
    { path: 'exploitation/comparator', text: 'Comparateur', minimalRole: ROLE_TECHNICIAN },
  ],
};

export const simulationSteps = [
  {
    title: 'CHOIX DES PARCELLES',
  },
  {
    title: 'CHOIX DES CULTURES',
  },
  {
    title: 'CHOIX DES CONTRAINTES',
  },
  {
    title: 'CHOIX DES OBJECTIFS',
  },
  {
    title: 'CHOIX DES OBLIGATIONS / RESTRICTIONS',
  },
  {
    title: 'RECAPITULATIF',
  },
];

export const colors = [
  '#b71c1c',
  '#d32f2f',
  '#f44336',
  '#e57373',
  '#ffcdd2',
  '#880e4f',
  '#c2185b',
  '#e91e63',
  '#f06292',
  '#f8bbd0',
  '#4a148c',
  '#7b1fa2',
  '#9c27b0',
  '#ba68c8',
  '#e1bee7',
  '#311b92',
  '#512da8',
  '#673ab7',
  '#9575cd',
  '#d1c4e9',
  '#1a237e',
  '#303f9f',
  '#3f51b5',
  '#7986cb',
  '#c5cae9',
  '#0d47a1',
  '#1976d2',
  '#2196f3',
  '#64b5f6',
  '#bbdefb',
  '#01579b',
  '#0288d1',
  '#03a9f4',
  '#4fc3f7',
  '#b3e5fc',
  '#006064',
  '#0097a7',
  '#00bcd4',
  '#4dd0e1',
  '#b2ebf2',
  '#004d40',
  '#00796b',
  '#009688',
  '#4db6ac',
  '#b2dfdb',
  '#194D33',
  '#388e3c',
  '#4caf50',
  '#81c784',
  '#c8e6c9',
  '#33691e',
  '#689f38',
  '#8bc34a',
  '#aed581',
  '#dcedc8',
  '#827717',
  '#afb42b',
  '#cddc39',
  '#dce775',
  '#f0f4c3',
  '#f57f17',
  '#fbc02d',
  '#ffeb3b',
  '#fff176',
  '#fff9c4',
  '#ff6f00',
  '#ffa000',
  '#ffc107',
  '#ffd54f',
  '#ffecb3',
  '#e65100',
  '#f57c00',
  '#ff9800',
  '#ffb74d',
  '#ffe0b2',
  '#bf360c',
  '#e64a19',
  '#ff5722',
  '#ff8a65',
  '#ffccbc',
  '#3e2723',
  '#5d4037',
  '#795548',
  '#a1887f',
  '#d7ccc8',
  '#263238',
  '#455a64',
  '#607d8b',
  '#90a4ae',
  '#cfd8dc',
  '#000000',
  '#525252',
  '#969696',
  '#d9d9d9',
  '#ffffff',
];
