import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/generics/Button';

const IconButton = ({
  text,
  type,
  textColor,
  backgroundColor,
  onClick,
  disabled,
  defaultStyle,
  icon,
}) => (
  <Button
    style={{ color: textColor, backgroundColor }}
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={defaultStyle}
    text={text}
    icon={icon}
  />
);

IconButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  defaultStyle: PropTypes.string,
};

IconButton.defaultProps = {
  type: 'button',
  textColor: '',
  backgroundColor: '',
  disabled: false,
  defaultStyle: '',
};

export default IconButton;
