import { useContext } from 'react';
import { PdfContext } from 'utils/context';
import ObligationRestrictionTable from '../Tables/ObligationRestrictionTable';

const ObligationRestriction = () => {
  const { obligationRestrictions, cultures, exploitation } = useContext(PdfContext);
  let arrangedObligationRestrictionData = [];

  let allObligationParcels = [];
  obligationRestrictions.forEach((obligationRestriction) => {
    let currentObligationParcels = obligationRestriction.Parcel_Id;
    allObligationParcels = [...allObligationParcels, ...currentObligationParcels];
  });

  let cleanedAllObligationParcelIds = [];
  allObligationParcels.forEach((parcelId) => {
    if (!cleanedAllObligationParcelIds.includes(parcelId)) {
      cleanedAllObligationParcelIds.push(parcelId);
    }
  });

  cleanedAllObligationParcelIds.forEach((parcelId) => {
    obligationRestrictions.forEach((obligationRestriction) => {
      if (
        obligationRestriction.Parcel_Id.includes(parcelId) &&
        !arrangedObligationRestrictionData.some(
          (obligationRestriction) => obligationRestriction.parcelId === parcelId
        )
      ) {
        if (obligationRestriction.constraint === true) {
          arrangedObligationRestrictionData.push({
            parcelId: parcelId,
            cultures: obligationRestriction.Exploitation_Culture_Id.map((cultureId) => ({
              cultureId: cultureId,
              obligation: [...obligationRestriction.years],
              restriction: [],
            })),
          });
        }
        if (obligationRestriction.constraint === false) {
          arrangedObligationRestrictionData.push({
            parcelId: parcelId,
            cultures: obligationRestriction.Exploitation_Culture_Id.map((cultureId) => ({
              cultureId: cultureId,
              obligation: [],
              restriction: [...obligationRestriction.years],
            })),
          });
        }
      } else if (
        obligationRestriction.Parcel_Id.includes(parcelId) &&
        arrangedObligationRestrictionData.some(
          (obligationRestriction) => obligationRestriction.parcelId === parcelId
        )
      ) {
        let obligationToUpdate = arrangedObligationRestrictionData.find(
          (obligation) => obligation.parcelId === parcelId
        );
        obligationRestriction.Exploitation_Culture_Id.forEach((cultureId) => {
          if (obligationToUpdate.cultures.find((culture) => culture.cultureId === cultureId)) {
            let cultureToUpdate = obligationToUpdate.cultures.find(
              (culture) => culture.cultureId === cultureId
            );
            if (obligationRestriction.constraint) {
              cultureToUpdate.obligation = [
                ...cultureToUpdate.obligation,
                ...obligationRestriction.years,
              ];
            }
            if (!obligationRestriction.constraint) {
              cultureToUpdate.restriction = [
                ...cultureToUpdate.restriction,
                ...obligationRestriction.years,
              ];
            }
          } else {
            if (obligationRestriction.constraint) {
              obligationToUpdate.cultures.push({
                cultureId: cultureId,
                obligation: [...obligationRestriction.years],
                restriction: [],
              });
            }
            if (!obligationRestriction.constraint) {
              obligationToUpdate.cultures.push({
                cultureId: cultureId,
                obligation: [],
                restriction: [...obligationRestriction.years],
              });
            }
          }
        });
      }
    });
  });

  arrangedObligationRestrictionData?.forEach((parcel) => {
    parcel.name = exploitation?.parcels?.find((parcelle) => parcelle?.id === parcel.parcelId)?.name;
    parcel?.cultures?.forEach((culture) => {
      culture.name = cultures.find((crop) => crop.id === culture.cultureId)?.name;
    });
  });

  let truncatedObligationRestriction = truncateObligationRestriction(
    arrangedObligationRestrictionData,
    16
  );
  let obligationRestrictionPages = [];

  truncatedObligationRestriction.forEach((pageData, index) => {
    obligationRestrictionPages.push(
      <ObligationRestrictionTable
        key={index}
        truncatedObligationRestriction={pageData}
        messageDuplicate={index > 0 ? '(Suite)' : ''}
      />
    );
  });

  return obligationRestrictionPages;
};

const truncateObligationRestriction = (obligationRestriction, numberOfRowPerTable) => {
  let slicedObligationRestriction = [];
  let tempObligationRestriction = [];
  let rowCounter = 0;
  obligationRestriction.map((parcel) => {
    rowCounter++;
    let parcelObject = { name: parcel.name, parcelId: parcel.parcelId, cultures: [] };
    if (rowCounter === numberOfRowPerTable) {
      rowCounter = 1;
      slicedObligationRestriction.push(tempObligationRestriction);
      tempObligationRestriction = [];
    }
    parcel.cultures.map((culture) => {
      let cultureCounter = 0;
      if (culture.obligation.length > 0 && culture.restriction.length > 0) {
        cultureCounter = 2;
      } else {
        cultureCounter = 1;
      }
      let cultureObject = {
        cultureId: culture.cultureId,
        name: culture.name,
        obligation: culture.obligation,
        restriction: culture.restriction,
      };
      if (rowCounter <= numberOfRowPerTable - 2) {
        rowCounter += cultureCounter;

        parcelObject.cultures.push(cultureObject);
      } else if (
        rowCounter > numberOfRowPerTable - 2 &&
        rowCounter < numberOfRowPerTable &&
        cultureCounter === 1
      ) {
        rowCounter += cultureCounter;

        parcelObject.cultures.push(cultureObject);
      } else if (
        rowCounter > numberOfRowPerTable - 2 &&
        rowCounter < numberOfRowPerTable &&
        cultureCounter === 2
      ) {
        let truncatedCultureObjectObligation = {
          cultureId: culture.cultureId,
          name: culture.name,
          obligation: culture.obligation,
          restriction: [],
        };
        let truncatedCultureObjectRestriction = {
          cultureId: culture.cultureId,
          name: culture.name,
          obligation: [],
          restriction: culture.restriction,
        };
        parcelObject.cultures.push(truncatedCultureObjectObligation);
        rowCounter = 1;
        tempObligationRestriction.push(parcelObject);
        slicedObligationRestriction.push(tempObligationRestriction);
        tempObligationRestriction = [];
        parcelObject = { name: parcel.name, parcelId: parcel.parcelId, cultures: [], suite: true };
        parcelObject.cultures.push(truncatedCultureObjectRestriction);
      } else {
        rowCounter = 1;
        tempObligationRestriction.push(parcelObject);
        slicedObligationRestriction.push(tempObligationRestriction);
        tempObligationRestriction = [];
        parcelObject = { name: parcel.name, parcelId: parcel.parcelId, cultures: [], suite: true };
        parcelObject.cultures.push(cultureObject);
      }
    });
    tempObligationRestriction.push(parcelObject);
  });

  slicedObligationRestriction.push(tempObligationRestriction);

  return slicedObligationRestriction;
};

export default ObligationRestriction;
