import API from 'services/API';

export const getCooperative = (id) => API.get(`/cooperatives/${id}`);

// TECHNICIAN

export const getAllTechnicianFull = (options = null) => {
  let queryParamsString = 'version=full';
  if (options && options.queryParamsString) {
    for (const [key, value] of Object.entries(options.queryParamsString)) {
      queryParamsString += '&' + key + '=' + value;
    }
  }
  return API.get('/users?' + queryParamsString);
};

export const addTechnician = (user) => API.post('/users/add', user);

export const updateTechnician = (user) => API.patch(`/users/edit/${user.id}`, user);

export const deleteTechnician = (user) => API.delete(`/users/remove/${user.id}`);

export const linkUserToSector = (user) => API.patch(`/users/edit/link_sector/${user.id}`, user);

// CULTURE
export const addCulture = (culture, cooperativeId = -1) =>
  API.post(
    cooperativeId > -1 ? `/cultures/add?cooperative=${cooperativeId}` : '/cultures/add',
    culture
  );

export const getCultures = (cooperativeId) => API.get(`/cooperatives/${cooperativeId}/cultures`);

export const getCultureById = (id) => API.get(`/cultures/${id}`);

export const updateCulture = (culture, id) => API.patch(`/cultures/edit/${id}`, culture);

export const deleteCulture = (culture) =>
  API.delete(`/cultures/remove/${culture.id}?safe-delete=true`);

// CONSTRAINTS
export const getConstraint = (id) => API.get(`/constraints/${id}`);

// CAP CATEGORIES
export const getCAPCategories = () => API.get(`/capcategories`);
export const getCAPCategoriesList = () => API.get(`/capcategories/list`);

// CONSTRAINT CATEGORIES
export const getAllConstraintCategories = () => API.get(`/constraint_categories`);
export const getConstraintCategories = (cooperativeId) =>
  API.get(`/constraint_categories/${cooperativeId}`);
export const getCoProductsFromConstraintCategory = (data) =>
  API.post(`/constraint_categories/co-products`, data);

// CO PRODUCTS
export const getCoProducts = () => API.get(`/coproducts`);
export const getCoProductsPerCooperative = (cooperativeId) =>
  API.get(
    cooperativeId
      ? `/coproducts/cooperative?cooperativeId=${cooperativeId}`
      : `/coproducts/cooperative`
  );

export const renameCoProducts = (data) => API.patch(`/coproducts/rename/batch`, data);

export const linkCoProductToConstraint = (coProductId, constraintId) =>
  API.patch(`/coproducts/edit/${coProductId}/link_constraint/${constraintId}`);
export const associateCoProducts = (data) => API.post(`/coproducts/associate`, data);
export const newCategoryOrCoProduct = (data) => API.post(`/coproducts/new`, data);

// ROTATE EFFECTS

export const updateRotationEffect = (from, cooperativeId, body) =>
  API.patch(`/${from}/${cooperativeId}/rotation-effects`, {
    rotateEffectList: body,
  });

// SECTOR
export const addSecteur = (secteur, cooperativeId = -1) =>
  API.post(
    cooperativeId > -1 ? `/sectors/add?cooperative=${cooperativeId}` : '/sectors/add',
    secteur
  );

export const updateSecteur = (secteur) => API.patch(`/sectors/edit/${secteur.id}`, secteur);

export const deleteSector = (sectorId) => API.delete(`/sectors/remove/${sectorId}`);

export const getSecteurLight = (id) => API.get(`/sectors?version=light&cooperative=${id}`);

// GroundType
export const addGroundType = (groundType, cooperativeId = -1) =>
  API.post(
    cooperativeId > -1 ? `/ground-types/add?cooperative=${cooperativeId}` : '/ground-types/add',
    groundType
  );

export const updateGroundType = (groundType) =>
  API.patch(`/ground-types/edit/${groundType.id}`, groundType);

export const deleteGroundType = (groundType) => API.delete(`/ground-types/${groundType.id}`);

// GroundType effect
export const getAllGroundTypeEffects = () => API.get('/ground-type-effects');

export const updateGroundTypeEffect = (data) =>
  API.patch(`/ground-type-effects/edit/${data.id}`, { effect: data.effect });

// Import CSV

export const importCsvData = (formdata, cooperativeId) =>
  API.post(`/exploitation/${cooperativeId}/importParcelsCsv`, formdata, {
    headers: { 'content-type': 'multipart/form-data' },
  });

// Import Geofolia
export const importZipGeofolia = (formdata, cooperativeId) =>
  API.post(`/exploitation/${cooperativeId}/importParcelsGeofolia`, formdata, {
    headers: { 'content-type': 'multipart/form-data' },
  });

export const getGeofoliaPreflight = (jobId) => API.get(`/cache/geofolia/${jobId}`);

export const importParcelsGeofolia = (jobId, formdata) =>
  API.post(`/cache/geofolia/persist/${jobId}`, formdata);

// Import Wiuz
export const importWiuzExploitation = (id) => API.get(`/cooperative/${id}/importExploitations`);

export const getExploitationTypes = () => API.get(`/exploitations-types`);

export const importParcelsWiuz = (cooperativeId, data) =>
  API.post(`/exploitation/${cooperativeId}/importParcelsWiuz`, data);

// To check the status of the Wiuz importation
export const importParcelsResult = (jobId) => API.get(`exploitation/${jobId}/importParcelsResult`);

export const importParcelsStatus = (jobId) => API.get(`exploitation/${jobId}/importParcelsStatus`);

// To check if the mapping files are available
export const getMappingFiles = (cooperativeId) =>
  API.get(`structure/${cooperativeId}/mappingFiles`);

// YEARS
export const getCurrentYear = () => API.get('/years/current');

export default {
  getCooperative,
  addTechnician,
  updateTechnician,
  deleteTechnician,
  addCulture,
  getCultureById,
  updateCulture,
  deleteCulture,
  addSecteur,
  updateSecteur,
  deleteSector,
  addGroundType,
  updateGroundType,
  deleteGroundType,
};
