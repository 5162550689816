import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import ParcelInfo from 'components/generics/Map/ParcelInfo';
import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { Point } from 'ol/geom.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import { ExploitationContext } from 'utils/context';
import {
  createNewMap,
  gpsToPixel,
  handleMouseMoveEvent,
  setPolygonStyle,
  zoomToFit,
} from './MapCommon';
import { emptyCulture } from 'utils/culturesColors';
import './Map.style.scss';
import MapTab from './MapTab';

function MapParcels({ updateParcel, parcelUpdated, centerParcels, currentYear }) {
  const { exploitationContext } = useContext(ExploitationContext);
  const { culturesColors } = useContext(ExploitationContext);
  const [year, setYear] = useState(currentYear);
  const [minYear, setMinYear] = useState(4000);
  const [maxYear, setMaxYear] = useState(0);
  const [parcelInfo, setParcelInfo] = useState(null);
  const [modalCoords, setModalCoords] = useState(null);
  const [storeParcelColor, setStoreParcelColor] = useState([]);
  const [cultureHoverName, setCultureHoverName] = useState(null);
  const [parcelHoverCultureName, setParcelHoverCultureName] = useState(null);
  const [mapObject, setMap] = useState();
  const [polygonSource, setPolygonSource] = useState();
  const [coords, setCoords] = useState({ x: 0, y: 0 });
  const mapElement = useRef();
  const popup = useRef(null);
  let activePolygon;
  const data = exploitationContext?.parcels;
  const map = useMemo(() => mapObject, [mapObject]);

  if (data) {
    data.map((parcel) => {
      if (parcel) {
        if (parcel.cultureN1?.year && parcel.cultureN?.year) {
          if (Number(parcel.cultureN1.year) < minYear) {
            setMinYear(Number(parcel.cultureN1.year));
          }
          if (Number(parcel.cultureN.year) > maxYear) {
            setMaxYear(Number(parcel.cultureN.year));
          }
        }
      }
    });
  }

  useEffect(() => {
    return handleMouseMoveEvent(setCoords);
  }, []);

  useEffect(() => {
    setMap(createNewMap());
  }, []);

  useEffect(() => {
    if (map) {
      setMapDetails(map);
      map.once('rendercomplete', function (event) {
        zoomToFit(map);
      });
    }
  }, [map, centerParcels]);

  useEffect(() => {
    if (map) {
      setMapDetails(map);
    }
  }, [year, parcelUpdated]);

  useEffect(() => {
    if (map && cultureHoverName) {
      const extent = map.getView().calculateExtent(map.getSize());
      if (polygonSource) {
        polygonSource.forEachFeatureInExtent(extent, function (feature) {
          const selectedCultureName = exploitationContext?.parcels.find(
            (parcel) => parcel.id + '' + year + '' + parcel.name === feature.getId()
          );
          let parcelCultureAtYear = {};
          if (selectedCultureName?.cultureN?.year === year) {
            parcelCultureAtYear = selectedCultureName.cultureN;
          }
          if (selectedCultureName?.cultureN1?.year === year) {
            parcelCultureAtYear = selectedCultureName.cultureN1;
          }
          if (!parcelCultureAtYear.name) {
            parcelCultureAtYear = { ...emptyCulture, year: year };
          }
          if (selectedCultureName && parcelCultureAtYear) {
            if (parcelCultureAtYear.name === cultureHoverName || cultureHoverName === 'null') {
              setPolygonStyle(feature, parcelCultureAtYear.name, 1, culturesColors);
            } else {
              setPolygonStyle(feature, parcelCultureAtYear.name, 0.3, culturesColors);
            }
          }
        });
      }
    }
  }, [map, cultureHoverName]);

  function setMapDetails(map) {
    handleMouseMoveEvent(setCoords);

    if (map && year) {
      map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get('id') === 'polygonVector')
        .forEach((layer) => map.removeLayer(layer));

      const polygonSourceEffect = new VectorSource();
      exploitationContext?.parcels
        .filter((parcel) => parcel.coordinates)
        .map((parcel) => {
          let parcelCultureAtYear = {};
          if (parcel.cultureN?.year === year) {
            parcelCultureAtYear = parcel.cultureN;
          }
          if (parcel.cultureN1?.year === year) {
            parcelCultureAtYear = parcel.cultureN1;
          }
          if (!parcelCultureAtYear.name) {
            parcelCultureAtYear = { ...emptyCulture, year: year };
          }

          const parcelFeature = new Feature({
            geometry: new Polygon(gpsToPixel(parcel.coordinates)),
          });
          const parcelIdByYear = parcel.id + '' + year + '' + parcel.name;
          parcelFeature.setId(parcelIdByYear);
          parcelFeature.set('parcelId', parcel.id);
          setPolygonStyle(
            parcelFeature,
            activePolygon?.name ? activePolygon.name : parcelCultureAtYear?.name,
            1,
            culturesColors
          );

          let finalColorTable = storeParcelColor;
          finalColorTable.forEach((parcelColor, index) => {
            if (parcelColor.id === parcelIdByYear) {
              finalColorTable.splice(index, 1);
            }
          });
          finalColorTable.push({
            id: parcelIdByYear,
            culture: parcelCultureAtYear?.name,
          });

          setStoreParcelColor(finalColorTable);
          polygonSourceEffect.addFeature(parcelFeature);
          setPolygonSource(polygonSourceEffect);
          return polygonSourceEffect;
        });

      const geometryFunction = function () {
        polygonSourceEffect.getFeatures().forEach((feature) => {
          return new Point(getCenter(feature.getGeometry().getExtent()));
        });
      };

      const polygonVector = new VectorLayer({
        id: 'polygonVector',
        source: polygonSourceEffect,
        geometry: geometryFunction(),
      });

      map.addLayer(polygonVector);
      map.set();

      let selected = null;
      map.on('pointermove', function (e) {
        const extent = map.getView().calculateExtent(map.getSize());
        if (selected !== null) {
          setParcelInfo(null);
          setModalCoords(null);
          polygonSourceEffect.forEachFeatureInExtent(extent, function (feature) {
            const selectedCultureName = storeParcelColor.find(
              (parcel) => parcel.id === feature.getId()
            );
            if (selectedCultureName && selectedCultureName.culture) {
              setPolygonStyle(feature, selectedCultureName.culture, 1, culturesColors);
            }
            setParcelHoverCultureName(null);
          });
          selected = null;
        }

        map.forEachFeatureAtPixel(e.pixel, function (f) {
          selected = f;
          setParcelInfo(
            exploitationContext?.parcels.find(
              (parcel) => parcel.id + '' + year + '' + parcel.name === f.getId()
            )
          );
          setModalCoords(e.pixel);

          polygonSourceEffect.forEachFeatureInExtent(extent, function (feature) {
            const selectedCultureName = storeParcelColor.find(
              (parcel) => parcel.id === feature.getId()
            );
            if (selectedCultureName && selectedCultureName.culture) {
              if (feature.getId() !== selected.getId()) {
                setPolygonStyle(feature, selectedCultureName.culture, 0.3, culturesColors);
              } else {
                setParcelHoverCultureName(selectedCultureName.culture);
              }
            }
          });
          return true;
        });
      });

      map.on('click', function (e) {
        map.forEachFeatureAtPixel(e.pixel, function (feature) {
          updateParcel(feature.get('parcelId'));
        });
      });
    }
  }

  const handleCultureHover = (cultureHoverName) => {
    setCultureHoverName(cultureHoverName);
  };

  return (
    <div className="tab_container">
      <div className="details_container">
        <MapTab
          data={data}
          header={['', 'Culture', 'Surface']}
          isDynamic={true}
          year={year}
          minYear={minYear}
          maxYear={maxYear}
          setYear={setYear}
          parcelHoverCultureName={parcelHoverCultureName}
          parentCallback={handleCultureHover}
          fromParcels
        />
      </div>
      <div ref={mapElement} className="map_container">
        <div
          id="map"
          className="map_element"
          onMouseOut={() => {
            setParcelInfo(null);
            setModalCoords(null);
          }}
        ></div>
        {parcelInfo && (
          <div className="parcel-info-modal" ref={popup}>
            {modalCoords && (
              <div
                id="popup"
                className="ol-popup"
                style={{
                  marginLeft: coords.x - 50 + 'px',
                  marginTop: coords.y - 300 + 'px',
                }}
              >
                <ParcelInfo parcelInfo={parcelInfo}></ParcelInfo>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MapParcels;
