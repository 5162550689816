import { useEffect, useState, useRef, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  copyCurrentValueToFuture,
  StyledTooltipErrorInputCostModal,
} from 'utils/Datagrid/CustomCultureDataGridUtils';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { Box, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import {
  checkEmptyFieldAndDecimalRegexTwoDigitsDatagridValue,
  getOnlyAttributeUpdated,
} from 'utils/tools_functions';
import { toast } from 'react-toastify';
import { updateCulture } from 'services/API/Cooperative';
import { updateCulture as updateCultureSector } from 'services/API/Secteur';
import { updateCulture as updateCultureExploitation } from 'services/API/Exploitation';
import { validateDatagridData } from 'components/generics/Validators/datagrid.validate';
import { customDialogNitrogenTheme } from 'assets/styles/themes/Dialog/generic_dialog_nitrogen_mui';

const IFTModal = ({
  openIFTDialog,
  setOpenIFTDialog,
  refreshData,
  errorsFormDefault,
  setErrorsFormDefault,
  columns,
  IFTValue,
  owner,
  updatedRow,
}) => {
  const [IFTClone, setIFTClone] = useState(IFTValue);
  const ref = useRef();

  const handleChange = (e, field) => {
    const { value } = e.target;

    setIFTClone((prev) => ({
      ...prev,
      [field]: checkEmptyFieldAndDecimalRegexTwoDigitsDatagridValue(value),
    }));
  };

  const handleIFTClick = async () => {
    const toUpdate = getOnlyAttributeUpdated(IFTValue, IFTClone);

    errorsFormDefault.formError = false;

    setErrorsFormDefault({
      ...validateDatagridData(toUpdate, errorsFormDefault, columns, IFTValue.id),
    });

    if (!errorsFormDefault.formError) {
      if (Object.entries(toUpdate).length > 0) {
        if (toUpdate.hasOwnProperty('tfiHerbicide')) {
          toUpdate.tfiHerbicide = toUpdate.tfiHerbicide ?? updatedRow.tfiHerbicide;
        }

        if (toUpdate.hasOwnProperty('tfiWithoutHerbicide')) {
          toUpdate.tfiWithoutHerbicide =
            toUpdate.tfiWithoutHerbicide ?? updatedRow.tfiWithoutHerbicide;
        }

        try {
          setOpenIFTDialog(false);
          if (!owner) {
            await updateCulture(toUpdate, IFTValue.cultureId);
          } else if (owner.parent === 'fromSector') {
            await updateCultureSector(owner.id, toUpdate, IFTValue.cultureId);
          } else {
            await updateCultureExploitation(owner.id, toUpdate, IFTValue.cultureId);
          }
          toast.success(`Culture mise à jour`);
          refreshData();
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
      setOpenIFTDialog(false);
    }
  };

  const handleCloseIFTDialog = () => {
    setOpenIFTDialog(false);
    setErrorsFormDefault((prev) => ({
      ...prev,
      formError: false,
      tfiHerbicide: {
        ...prev.tfiHerbicide,
        message: '',
      },
      tfiWithoutHerbicide: {
        ...prev.tfiWithoutHerbicide,
        message: '',
      },
    }));
  };

  const isOpen = useMemo(() => errorsFormDefault.formError, [errorsFormDefault]);
  return (
    <ThemeProvider theme={customDialogNitrogenTheme}>
      <Dialog maxWidth="md" open={openIFTDialog} ref={ref} PaperProps={{ ref: ref }}>
        <Box>
          <DialogTitle>IFT</DialogTitle>
          <ClearIcon onClick={handleCloseIFTDialog} />
        </Box>

        <DialogContent dividers>
          <InputLabel id="tfiLabelHerbicide">IFT Herbicides</InputLabel>
          {errorsFormDefault?.tfiHerbicide.message && (
            <>
              <StyledTooltipErrorInputCostModal
                open={isOpen}
                title={errorsFormDefault?.tfiHerbicide?.message}
                placement="top"
              >
                <span></span>
              </StyledTooltipErrorInputCostModal>
            </>
          )}
          <TextField
            id="tfiHerbicide"
            value={IFTClone?.tfiHerbicide ?? 0}
            onChange={(e) => handleChange(e, 'tfiHerbicide')}
            className={errorsFormDefault?.tfiHerbicide.message ? 'MuiErrorForm' : ''}
          />
          <InputLabel id="tfiLabelWithoutHerbicide">IFT Hors Herbicides</InputLabel>
          {errorsFormDefault?.tfiWithoutHerbicide.message && (
            <StyledTooltipErrorInputCostModal
              open={isOpen}
              title={errorsFormDefault?.tfiWithoutHerbicide?.message}
              placement="top"
            >
              <span></span>
            </StyledTooltipErrorInputCostModal>
          )}
          <TextField
            id="tfiWithoutHerbicide"
            value={IFTClone?.tfiWithoutHerbicide ?? 0}
            onChange={(e) => handleChange(e, 'tfiWithoutHerbicide')}
            className={errorsFormDefault?.tfiWithoutHerbicide.message ? 'MuiErrorForm' : ''}
          />
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={customButtonTheme}>
            <Button id="iftButton" onClick={handleIFTClick}>
              Enregistrer
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      ;
    </ThemeProvider>
  );
};

export default IFTModal;
