// REACT
import React from 'react';

// SERVICES
import './CulturesMapTab.style.scss';

// Interface
import { IParcelsList } from '../Interface/Api/Response/Exploitation/Parcels/IParcelsList';
import { IParcelSimu } from '../Interface/Simulation/IParcelSimu';

type ParcelInfoParams = {
  parcelInfo: IParcelSimu;
  parcels: IParcelsList[];
};

const ParcelInfo = ({ parcelInfo, parcels }: ParcelInfoParams): React.JSX.Element => {
  const parentParcelName = parcels?.find((parcel) => parcel?.id === parcelInfo?.parent)?.name;
  return (
    <div id="popup-content">
      <h1>{parcelInfo?.name}</h1>
      <p>
        <span>{parcelInfo?.groupSurface ? 'Surface totale' : 'Surface'}</span>
        <span>
          {parcelInfo?.groupSurface
            ? Number(parcelInfo?.groupSurface.toFixed(2))
            : Number(parcelInfo?.surface.toFixed(2))}
        </span>
      </p>
      <p>
        <span>
          {parcelInfo?.cultureN?.year - 1} - {parcelInfo?.cultureN?.year} :
        </span>
        <span> {parcelInfo?.cultureN?.name}</span>
      </p>
      <p>
        <span>
          {parcelInfo?.cultureN1?.year - 1} - {parcelInfo?.cultureN1?.year} :
        </span>
        <span>{parcelInfo?.cultureN1?.name}</span>
      </p>
      <p>
        <span>Type de sol : </span>
        <span> {parcelInfo?.groundType?.name}</span>
      </p>
      <p>
        <span>Irrigabilité :</span>
        <span>{parcelInfo?.isIrrigableOnly || parcelInfo?.isIrrigable ? 'Oui' : 'Non'}</span>
      </p>
      {parcelInfo?.parent && (
        <p>
          {parentParcelName ? (
            <span>
              <i>Cette parcelle est regroupée avec {parentParcelName}</i>
            </span>
          ) : (
            <span>
              <i>Cette parcelle fait partie d'un regroupement</i>
            </span>
          )}
        </p>
      )}
      {parcelInfo?.isParent && (
        <p>
          <span>
            <i>Cette parcelle est parente d'un regroupement</i>
          </span>
        </p>
      )}
    </div>
  );
};

export default ParcelInfo;
