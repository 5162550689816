import API from 'services/API';

export const getExploitationInformations = (id) => API.get(`/exploitations/${id}`);

// CULTURES

export const getCulturesByExploitationId = (id) => API.get(`/exploitations/${id}/cultures`);

export const updateCulture = (id, data, exploitationCultureId) =>
  API.patch(`/exploitations/${id}/cultures/edit/${exploitationCultureId}`, data);

// EXPLOITATIONS

export const getConstraints = (cooperativeId) =>
  API.get(`/constraints?cooperative=${cooperativeId}`);

export const getCAPCategories = () => API.get(`/capcategories`);

export const getObjectives = () => API.get(`/objectives`);

// PARCELS

export const getParcelsByExploitation = (id) =>
  API.get(`/exploitations/${id}/parcels?version=full&by-years=false`);

export const addParcel = (idExploitation, data) =>
  API.post(`/exploitations/${idExploitation}/parcels`, data);

export const updateParcel = (idExploitation, data) =>
  API.patch(`/exploitations/${idExploitation}/parcels`, data);

export const removeParcel = (idExploitation, idParcel) =>
  API.delete(`/exploitations/${idExploitation}/parcels/remove/${idParcel}`);

export const createGroupementParcel = (idExploitation, data) =>
  API.post(`/exploitations/${idExploitation}/parcels/groupement`, data);

export const editGroupementParcel = (idExploitation, data, id) =>
  API.patch(`/exploitations/${idExploitation}/parcels/groupement/${id}`, data);

export const removeGroupementParcel = (idExploitation, id) =>
  API.delete(`/exploitations/${idExploitation}/parcels/groupement/${id}/delete`);

//FORECAST

export const getForecastsByExploitation = (exploitationId) =>
  API.get(`exploitations/${exploitationId}/forecast`);

export const addForecast = (exploitationId, data) =>
  API.post(`/exploitations/${exploitationId}/forecast`, data);

// GROUNDTYPE

export const getGroundTypeByExploitationId = (idExploitation) =>
  API.get(`ground-types/exploitations/${idExploitation}`);

// GROUNDTYPE EFFECT

export const updateGroundTypeEffect = (exploitation, data) =>
  API.patch(`/exploitations/${exploitation.id}/edit/${data.id}`, {
    effect: data.effect,
    from: data?.from,
  });

// EFFECTS

export const resetEffects = (exploitation, culture) =>
  API.patch(`/exploitations/${exploitation.id}/cultures/reset/${culture.id}`);

export const resetCultures = (exploitation) =>
  API.patch(`/exploitations/${exploitation.id}/cultures/reset`);

export const resetGroundTypeEffects = (exploitation) =>
  API.patch(`/exploitations/${exploitation.id}/gnd-type-effects/reset`);

export const resetRotationEffects = (exploitation) =>
  API.patch(`/exploitations/${exploitation.id}/rotation-effects/reset`);

// YEARS

export const getYears = () => API.get('/years');
export const getCurrentYear = () => API.get('/years/current');

// ARABLE

export const getArableSurface = (exploitationId) =>
  API.get(`/exploitations/${exploitationId}/arable`);

// IMPORT TELEPAC 

export const ImportTelepac = (formData, exploitationId) => 
  API.post(`/exploitation/${exploitationId}/telepac`, formData,{
    headers: { 'content-type': 'multipart/form-data' },
  });

export default {
  getExploitationInformations,
};
