import * as errorMessagesFunction from './messages/errors/index';
import { modal } from './messages/modal/message.modal';
import { generic } from './messages/generic/message.generic';
import { getCurrentYear } from 'services/API/Cooperative';

export const getOnlyAttributeUpdated = (base, updated) => {
  let baseEntries = Object.entries(base);
  let updatedEntries = Object.entries(updated);
  let toUpdate = [];
  updatedEntries.forEach((e, i) => {
    if (e[1] !== undefined && e[1] !== baseEntries[i][1]) {
      toUpdate.push(e);
    }
  });

  return Object.fromEntries(toUpdate);
};

export const getNewKeyBetweenObjects = (base, updated) => {
  let baseKeys = Object.keys(base);
  let updatedKeys = Object.keys(updated);

  const updatedKey = updatedKeys.filter((updatedKey) => !baseKeys.includes(updatedKey));
  if (updatedKey.length === 1) {
    return { [updatedKey[0]]: parseFloat(updated[updatedKey[0]]) };
  }
};

export const checkObjectsEquality = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

export const displayErrorMessage = (selectedMessage, additionnalInfo = null) => {
  for (const [key, errorFunction] of Object.entries(errorMessagesFunction)) {
    let errorMessageDisplay = errorFunction(selectedMessage, additionnalInfo);

    if (additionnalInfo && typeof additionnalInfo === 'string') {
      errorMessageDisplay.additionnalInfo = additionnalInfo;
    }
    if (errorMessageDisplay) {
      // .fr by default for now, but for internationalization we will take some langage variable from the navigator
      return errorMessageDisplay.additionnalInfo
        ? `${errorMessageDisplay?.fr} (${errorMessageDisplay.additionnalInfo})`
        : errorMessageDisplay?.fr;
    }
  }
  // If no specific error is found general error is send instead
  return errorMessagesFunction.staticErrorMessage('ERR_UNEXPECTED')?.fr;
};

export const displayMessage = (selectedMessage, additionnalInfo) => {
  const message = { ...modal, ...generic };
  let messageToDisplay = message[selectedMessage].fr;
  if (additionnalInfo) {
    messageToDisplay += ` (${additionnalInfo})`;
  }
  return messageToDisplay;
};

export const getTotalSurfaceParcelGroup = (base, children) => {
  children.forEach((c) => {
    base += c.surface;
  });
  return base.toFixed(2);
};

/**
 * Add additionnal controls for input type="number"
 * because of some inadequate behavior on Firefox and chrome especially on decimal separator
 */
export const checkNumericOnInputNumber = (e) => {
  // Array of allowed key press on input number in addition of numbers
  const arrayAllowedKeyPress = [
    'Backspace',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    '-',
    '.',
    ',',
    'Tab',
  ];
  // Check the type of the target Input
  if (
    e.target.dataset.type === 'number' ||
    e.target.dataset.type === 'digit' ||
    e.target.dataset.type === 'negativeNumber'
  ) {
    if (arrayAllowedKeyPress.includes(e.key)) {
      return;
    }
    // Doing nothing if the value is not a number
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  }
};

/**
 * Check input number value format to have 2 decimals digits at most
 * And only ',' & '.' for decimal separator
 */
export const checkDecimalRegexTwoDigits = (e) => {
  if (e.target.dataset.type === 'number' || e.target.dataset.type === 'negativeNumber') {
    // Force user input behavior tranform ',' => '.' in input datatype number
    if (e.target.value.includes(',')) {
      e.target.value = e.target.value.replace(/,/g, '.');
    }
    if (
      e.target.dataset.type === 'number' &&
      !e.target.value.toString().match(/^[0-9]*[.]?[0-9]{0,2}$/)
    ) {
      return false;
    }
    if (
      (e.target.dataset.type === 'negativeNumber' &&
        !e.target.value.toString().match(/^[0]?[-]?[0-9]*[.]?[0-9]{0,2}$/)) ||
      e.target.value[0] === '.' ||
      e.target.value.includes('-.')
    ) {
      return false;
    }
  }
  return true;
};

const formatFunctionChecking = (value, regex) => {
  if (value.includes(',')) {
    value = value.replace(/,/g, '.');
  }
  if (value[0] === '0' && value.length > 1 && value[1] !== '.') {
    value = value.substring(1);
  }
  return regexValidator(value, regex);
};

const regexValidator = (value, regex) => {
  if (typeof value === 'string' && value.toString().match(regex)) {
    return value;
  } else {
    return value.substring(value.length - 1, 0);
  }
};

export const checkTypeStringRegexDatagrid = (value) => {
  const regex =
    /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.\'\-_\(\)]+$/;
  return regexValidator(value, regex);
};

export const checkEmptyFieldAndDecimalRegexTwoDigitsDatagridValue = (value) => {
  const regex = /^[0]?[0-9]*[.]?[0-9]{0,2}$/;
  if (value.length === 0 || !value.length) {
    value = '0';
  }
  return formatFunctionChecking(value, regex);
};
export const checkDecimalRegexTwoDigitsDatagridValue = (value) => {
  const regex = /^[0]?[0-9]*[.]?[0-9]{0,2}$/;
  return formatFunctionChecking(value, regex);
};
export const checkEmptyFieldAndNoDecimalDatagridValue = (value) => {
  const regex = /^[0]?[0-9]*[.]?[0-9]{0,2}$/;
  if (value.length === 0 || !value.length) {
    value = '0';
  }
  return formatFunctionChecking(value, regex);
};
export const checkThreeValueAndDecimalRegexTwoDigitsDatagrid = (value) => {
  const regex = /^[0]?[0-9]{0,3}(\.[.]?[0-9]{0,2})?$/;
  return formatFunctionChecking(value, regex);
};
export const checkNoDecimalRegexTwoDigitsDatagridValue = (value) => {
  const regex = /^[0]?[0-9]*?[0-9]{0}$/;
  return formatFunctionChecking(value, regex);
};

export const checkDecimalNegativeDatagrid = (value) => {
  const regex = /^[0]?[-]?[0-9]*[.]?[0-9]{0,2}$/;
  return formatFunctionChecking(value, regex);
};

export const checkEmptyFieldAndNoDecimalDatagridInteger = (value) => {
  const regex = /^[0]?[0-9]*?[0-9]{0}$/;

  if (value.length === 0 || !value.length) {
    value = '0';
  }
  if (value[0] === '0' && value.length > 1) {
    value = value.substring(1);
  }

  if (typeof value === 'string' && value.toString().match(regex)) {
    return parseInt(value);
  } else {
    value.substring(value.length - 1, 0);
    return parseInt(value);
  }
};

/**
 * Only accept digits
 */
export const checkDigitRegex = (e) => {
  if (e.target.dataset.type === 'digit') {
    if (!e.target.value.toString().match(/^[0-9]*$/)) {
      return false;
    }
  }
  return true;
};

/**
 * '0' is set on emptied field.
 * When field is filled, initial '0' is removed
 */
export const emptyFieldToZero = (value) => {
  if (value === '') {
    value = 0;
  }
  if (value[0] === '0' && value.length > 1 && value[1] !== '.') {
    value = value.substring(1);
  }
  return value;
};

//Used to format import files size
export const formatFileSize = (size) => {
  let gb = Math.trunc(size / 1000000000);
  let mb = Math.trunc(size / 1000000);
  let kb = Math.trunc(size / 1000);
  kb = kb > 9 ? kb - 10 * Math.trunc(kb / 10) : kb;
  mb = mb > 9 ? mb - 10 * Math.trunc(mb / 10) : mb;
  gb = gb > 9 ? gb - 10 * Math.trunc(gb / 10) : gb;

  return `${gb > 0 ? gb + 'go ' : ''} ${mb > 0 ? mb + 'mo ' : ''} ${kb > 0 ? kb + 'ko' : ''}`;
};

export const getStringInsideParentheses = (string) => {
  return string.match(/\(([^)]+)\)/)?.[1];
};

//Used to calculate percentage relative to a total
export const getPercentage = (number, total) => {
  number = parseFloat(number);
  total = parseFloat(total);
  if (total === 0) {
    return 'Erreur : division par 0 impossible';
  }

  return ((number / total) * 100).toFixed(0);
};

export const setCurrentYearLocalStorage = async () => {
  const currentYear = await getCurrentYear();
  localStorage.setItem('currentYear', +currentYear?.data?.data?.year);
};
