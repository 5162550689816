import React from 'react';
import PropTypes from 'prop-types';

import './booleancheckbox.style.scss';

const BooleanCheckbox = ({ checked, onChange, label, labelClass, containerClass }) => {
  return (
    <div className={`boolean_checkbox_with_label ${containerClass}`}>
      <label htmlFor={label} className="switch">
        <input id={label} checked={checked} onChange={onChange} type="checkbox" />
        <span className="slider round" />
      </label>
      <p className={labelClass}>{label}</p>
    </div>
  );
}

BooleanCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  label: PropTypes.string,
  labelClass: PropTypes.string,
  containerClass: PropTypes.string,
};

BooleanCheckbox.defaultProps = {
  checked: false,
  label: '',
  labelClass: '',
  containerClass: '',
};

export default BooleanCheckbox;
