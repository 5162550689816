import { useState } from 'react';
import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import { customDataGridResultSimulation } from '../../../../utils/Datagrid/CustomForecastDataGridUtils';
import { Button, ThemeProvider } from '@mui/material';
import { customInvertedButtonTheme } from 'assets/styles/themes/generic_button_mui';

const DataGridCAPPoints = ({ rows, columns, fourPointCapCategory }) => {
  const [isOpen, setIsOpen] = useState(false);
  const fourPointCategories = [
    "Céréales d'hiver",
    'Céréales de printemps',
    'Plantes sarclées',
    "Oléagineux d'hiver",
    'Oléagineux de printemps',
  ];
  const processedColumns = columns?.slice(1)?.map((column, index) => {
    let processedColumn = {
      field: column,
      headerName: column,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    };

    if (index === 0) {
      processedColumn = { ...processedColumn, cellClassName: 'previous-campain' };
    }

    return processedColumn;
  });

  const processedRows = rows?.map((row, index) => {
    let processedRow = {
      id: row?.categorie + `-${index}`,
      hierarchy: fourPointCategories?.includes(row.categorie)
        ? ['Plafond à 4 points', row.categorie]
        : [row.categorie],
    };
    processedColumns?.map((column, idx) => {
      processedRow[column?.field] = row?.data?.[idx]?.value;
    });
    return processedRow;
  });

  const groupingColDef = {
    headerName: 'Campagne',
    field: 'group',
    headerAlign: 'center',
    hideDescendantCount: true,
    flex: 1,
    width: 250,
    minWidth: 250,
    maxWidth: 250,
  };

  const getTreeDataPath = (row) => row.hierarchy;
  const getRowClassName = (params) =>
    !fourPointCategories?.includes(params.id) ? 'classic-row Mui-even' : 'Mui-even';

  return (
    <div className="cap-points-categories-container">
      <div className="cap-points-categories-container__title">
        <h2>Point(s) Catégories PAC</h2>
        <ThemeProvider theme={customInvertedButtonTheme}>
          <Button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? 'Fermer' : 'Afficher'}
          </Button>
        </ThemeProvider>
      </div>
      <div
        className={
          isOpen
            ? 'cap-points-categories-container__opened'
            : 'cap-points-categories-container__closed'
        }
      >
        <CustomDataGridResult
          rows={processedRows}
          columns={processedColumns}
          isLoading={false}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColDef}
          treeData={true}
          theme={customDataGridResultSimulation}
          simulationContext={'simulation-box'}
          fullSize={true}
          getRowClassName={getRowClassName}
        />
      </div>
    </div>
  );
};

export default DataGridCAPPoints;
