// NO REVIEW

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { getCultures, getSecteurInformations } from 'services/API/Secteur';
import { ROLE_TECHNICIAN } from 'utils/const';
import { displayErrorMessage } from 'utils/tools_functions';
import { sendEvent } from 'utils/Event';

import Sector from 'components/icons/Sector';

import GroundTypeEffectForm from 'components/pages/Sector/form/GroundTypeEffect';
import GroundTypeEffectTableRow from 'components/pages/Cooperative/table/GroundTypeEffectRow';

import cultureProp from 'components/propTypes/cultureProp';
import groundTypeEffectProp from 'components/propTypes/groundTypeEffect';

import Table from 'components/generics/Table/Table';
import Select from 'components/generics/Select';
import { useNavigate } from 'react-router-dom';
import { FormModal } from 'components/generics/Modals/FormModal';

const SecteurGroundTypeEffects = ({ secteur }) => {
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [cultureSelected, setCultureSelected] = useState(null);
  const [groundTypesEffects, setGroundTypesEffects] = useState([]);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [sector, setSector] = useState(secteur);

  useEffect(() => {
    if (!sector) {
      if (!sector && localStorage.getItem('sector')) {
        getSecteurInformations(+localStorage.getItem('sector'))
          .then((resp) => {
            setSector(resp.data.data);
          })
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez un secteur');
        navigate('/sector');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (sector) {
      refreshData();
    }
  }, [sector]);

  useEffect(() => {
    cultureSelected &&
      setGroundTypesEffects(data.filter((e) => e.id === +cultureSelected)[0].groundTypeEffects);
  }, [cultureSelected]);

  useEffect(() => {
    if (data && !cultureSelected) {
      data && setCultureSelected(data[0]?.id);
    }
    setGroundTypesEffects(data?.filter((e) => e.id === +cultureSelected)[0]?.groundTypeEffects);
  }, [data]);

  const refreshData = () => {
    getCultures(sector.id)
      .then((cultures) => {
        setData(cultures.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_GET_EFFECT'));
        }
      });
  };

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    refreshData();
  };

  const actions = [
    {
      label: 'Modifier',
      func: (e) => {
        setEditData(e);
        setModal(true);
      },
      minimalRole: ROLE_TECHNICIAN,
    },
  ];

  return (
    <div className="section">
      <div className="main_container">
        <p className="title_spacing">
          <span className="subtitle_spacing">
            <Sector width={30.0} height={30} fill="$secondary" />
          </span>
          <span className="green_text subtitle_spacing">{sector?.name} </span>
        </p>

        <h1 className="title_section">Matrice type de sol</h1>
        <div className="flex-row">
          {data && (
            <Select
              className="reduce"
              name="Cultures"
              onChange={(e) => setCultureSelected(+e)}
              options={data}
              value={cultureSelected}
              itemLabel="name"
              itemValue="id"
            />
          )}
        </div>
        <Table
          header={['Type de sol', 'Effet (%)', '']}
          data={groundTypesEffects}
          RowElement={GroundTypeEffectTableRow}
          actions={actions}
        />
      </div>
      {modal && (
        <FormModal
          onClick={() => {
            setEditData(null);
            setModal(false);
          }}
          customTemplate={
            <GroundTypeEffectForm
              onSubmitSuccess={formSuccess}
              culture={cultureSelected}
              edit={editData}
              sectorId={sector.id}
            />
          }
        ></FormModal>
      )}
    </div>
  );
};

SecteurGroundTypeEffects.propTypes = {
  groundTypes: PropTypes.arrayOf(groundTypeEffectProp),
  cultures: PropTypes.arrayOf(cultureProp),
};

SecteurGroundTypeEffects.defaultProps = {
  groundTypes: [],
  cultures: [],
};

export default SecteurGroundTypeEffects;
