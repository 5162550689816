import PropTypes from 'prop-types';

import rotationEffectProp from 'components/propTypes/rotationEffect';
import groundTypeEffect from 'components/propTypes/groundTypeEffect';
import coProduct from 'components/propTypes/groundTypeEffect';

export default PropTypes.shape({
  groundTypeEffects: PropTypes.arrayOf(groundTypeEffect),
  id: PropTypes.number,
  ift: PropTypes.number,
  inputCost: PropTypes.number,
  coProducts: PropTypes.arrayOf(coProduct),
  capCategory: PropTypes.object,
  isSummerCulture: PropTypes.number,
  isStrawProducer: PropTypes.bool,
  name: PropTypes.string,
  presenceDuration: PropTypes.number,
  price: PropTypes.number,
  prohibitionDuration: PropTypes.number,
  rotationEffectN1s: PropTypes.arrayOf(rotationEffectProp),
  rotationEffectN2s: PropTypes.arrayOf(rotationEffectProp),
  yield: PropTypes.number,
  yieldProduct: PropTypes.number,
  irrigationYieldGain: PropTypes.number,
  irrigationWaterNeeded: PropTypes.number,
});
