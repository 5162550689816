import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/generics/Input';
import './inputLabel.style.scss';

const InputLabel = ({
  defaultStyle,
  disabled,
  name,
  required,
  type,
  value,
  onChange,
  color,
  placeholder,
  label,
  dataType
}) => (
  <label className={defaultStyle} htmlFor={name}>
    {label}
    <Input
      onChange={onChange}
      value={value}
      defaultStyle={defaultStyle}
      disabled={disabled}
      name={name}
      required={required}
      type={type}
      style={color}
      placeholder={placeholder}
      aria-label={name}
      dataType={dataType}
    />
  </label>
);

InputLabel.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  defaultStyle: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  placeholder: PropTypes.string,
};

InputLabel.defaultProps = {
  defaultStyle: '',
  disabled: false,
  name: '',
  required: false,
  type: 'text',
  color: '',
  placeholder: '',
};

export default InputLabel;
