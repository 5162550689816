import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useAuth from 'providers/Auth/useAuth';

import { deleteTechnician, linkUserToSector } from 'services/API/Cooperative';
import { ROLE_ADMIN } from 'utils/const';
import { displayErrorMessage } from 'utils/tools_functions';

import TechnicianTableRow from 'components/pages/Cooperative/table/TechnicianTableRow';

import secteurProp from 'components/propTypes/secteur';

import Table from 'components/generics/Table/Table';
import Select from 'components/generics/Select';
import IconButton from 'components/generics/Button';
import More from 'components/icons/More';
import AddEditTechnician from './form/AddEditTechnician';

const CooperativeTechnicians = ({ secteurs, technicians, refreshData }) => {
  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [filteredUsers, setFilteredUser] = useState(technicians);
  const [selectedFilter, setFilter] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    setIsEdit(false);
    refreshData();
  };

  const actions = () => {
    const list = [
      {
        label: 'Modifier',
        func: (e) => {
          setEditData({
            id: e.id,
            email: e.email,
            firstname: e.firstname,
            lastname: e.lastname,
            sectors: e.sectors,
          });
          setModal(true);
          setIsEdit(true);
        },
        minimalRole: ROLE_ADMIN,
      },
      {
        label: 'Supprimer',
        func: (e) =>
          deleteTechnician(e)
            .then(() => {
              toast.success(`Technicien supprimé`);
              refreshData();
            })
            .catch((err) => toast.error(err.data)),
        minimalRole: ROLE_ADMIN,
      },
    ];
    if (selectedFilter !== 0) {
      list.push({
        label: 'Supprimer du secteur',
        func: (e) => {
          let cooperativeId = -1;
          if (user?.role === 'SUPER_ADMIN')
            cooperativeId = Number(localStorage.getItem('cooperative'));

          const userToUnlink = {
            id: e.id,
            sectorsToRemove: [selectedFilter],
            cooperative: cooperativeId,
          };
          linkUserToSector(userToUnlink)
            .then(() => {
              toast.success('Technicien supprimé du secteur');
              refreshData();
            })
            .catch(() => toast.error(displayErrorMessage('ERR_DELETE_LINK_USER_SECTOR')));
        },
        minimalRole: ROLE_ADMIN,
      });
    }
    return list;
  };

  useEffect(() => {
    setSelectedUsers(
      technicians?.filter(
        (user) => !(user.sectors?.filter((sect) => sect.id === selectedFilter).length > 0)
      )[0]?.id || null
    );
    setFilteredUser(
      selectedFilter !== 0
        ? technicians?.filter(
            (user) => user.sectors?.filter((sect) => sect.id === selectedFilter).length > 0
          )
        : technicians
    );
  }, [selectedFilter, technicians]);

  return (
    <div className="section">
      <div className="main_container">
        <h1 className="title_section">Liste des Techniciens</h1>
        <div className="contain-header">
          <div className="flex-row">
            {secteurs && (
              <Select
                className="reduce"
                name="secteur"
                onChange={(e) => setFilter(+e)}
                options={[{ id: 0, name: 'Structure' }, ...secteurs]}
                value={selectedFilter}
                itemLabel="name"
                itemValue="id"
              />
            )}
          </div>
          <div className="add_button_container">
            <IconButton
              text="Ajouter"
              onClick={() => {
                setModal(!modal), setIsEdit(false);
              }}
              icon={<More width={13} height={13} />}
            />
          </div>
        </div>
        <Table
          header={['E-mail', 'Nom', 'Prénom', 'Date de création', '']}
          data={filteredUsers}
          RowElement={TechnicianTableRow}
          actions={actions()}
        />
      </div>
      <div className="add_button_container">
        {!!(
          selectedFilter !== 0 &&
          technicians?.filter(
            (user) => !(user.sectors?.filter((sect) => sect.id === selectedFilter).length > 0)
          ).length
        ) && (
          <>
            <Select
              name="newTech"
              onChange={(e) => setSelectedUsers(+e)}
              options={technicians?.filter(
                (user) => !(user.sectors?.filter((sect) => sect.id === selectedFilter).length > 0)
              )}
              value={selectedUsers}
              itemLabel="email"
              itemValue="id"
            />
            <IconButton
              text="Ajouter au secteur"
              defaultStyle="spacing"
              onClick={() => {
                let userInEdit = { id: selectedUsers, sectors: [selectedFilter] };
                if (user?.role === 'SUPER_ADMIN') {
                  userInEdit.cooperative = Number(localStorage.getItem('cooperative'));
                }
                linkUserToSector(userInEdit)
                  .then(() => {
                    toast.success('Technicien ajouté au secteur');
                    refreshData();
                  })
                  .catch(() => toast.error(displayErrorMessage('ERR_ADD_LINK_USER_SECTOR')));
              }}
            />
          </>
        )}
      </div>
      {modal && (
        <AddEditTechnician
          setEditData={setEditData}
          modal={modal}
          setModal={setModal}
          onSubmitSuccess={formSuccess}
          edit={editData}
          isEdit={isEdit}
          secteur={selectedFilter}
        />
      )}
    </div>
  );
};

CooperativeTechnicians.propTypes = {
  secteurs: PropTypes.arrayOf(secteurProp),
  refreshData: PropTypes.func.isRequired,
};

CooperativeTechnicians.defaultProps = {
  secteurs: [],
};

export default CooperativeTechnicians;
