import React from 'react';
import PropTypes from 'prop-types';

import actionsProps from 'components/propTypes/actions';

import ActionList from 'components/generics/Table/ActionList';

import cultureProp from 'components/propTypes/cultureProp';
import groundTypeProp from 'components/propTypes/groundType';

const GroundTypeEffectTableRow = ({ data, actions }) => (
  <tr>
    <td>{data.groundType.name}</td>
    <td>{`${data.effect}%`}</td>
    <td>
      <ActionList data={data} actions={actions} />
    </td>
  </tr>
);

GroundTypeEffectTableRow.propTypes = {
  data: PropTypes.shape({
    culture: cultureProp,
    effect: PropTypes.number,
    groundType: groundTypeProp,
    id: PropTypes.number,
  }),
  actions: actionsProps,
};

GroundTypeEffectTableRow.defaultProps = {
  data: {},
  actions: [],
};

export default GroundTypeEffectTableRow;
