import { useState, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  StyledTooltipErrorInputCostModal,
  copyCurrentValueToFuture,
} from 'utils/Datagrid/CustomCultureDataGridUtils';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { Box, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import {
  checkDecimalRegexTwoDigitsDatagridValue,
  getOnlyAttributeUpdated,
} from 'utils/tools_functions';
import { customDialogNitrogenTheme } from 'assets/styles/themes/Dialog/generic_dialog_nitrogen_mui';
import { toast } from 'react-toastify';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { updateStructure } from 'services/API/Administration';
import { updateSecteur } from 'services/API/Cooperative';
import { updateExploitation } from 'services/API/Secteur';

const NitrogenPriceModal = ({
  openNitrogenPriceDialog,
  setOpenNitrogenPriceDialog,
  errorsFormNitrogenEconomicValue,
  setErrorsNitrogenFormEconomicValue,
  nitrogenPriceValue,
  owner,
  cooperativeId,
  sector,
  exploitation,
  cooperativeName,
  getEconomicAttribute,
}) => {
  const [nitrogenValueClone, setNitrogenValueClone] = useState(nitrogenPriceValue);

  const handleChange = (e, field) => {
    const { value } = e.target;

    setNitrogenValueClone((prev) => ({
      ...prev,
      [field]: checkDecimalRegexTwoDigitsDatagridValue(value),
    }));
  };

  const handleNitrogenPriceClick = async () => {
    const toUpdate = getOnlyAttributeUpdated(nitrogenPriceValue, nitrogenValueClone);

    errorsFormNitrogenEconomicValue.formError = false;

    setErrorsNitrogenFormEconomicValue({
      ...validateFormData(toUpdate, errorsFormNitrogenEconomicValue),
    });

    if (!errorsFormNitrogenEconomicValue.formError) {
      if (Object.entries(toUpdate).length > 0) {
        let formData = new FormData();
        if (
          toUpdate.hasOwnProperty('nitrogenPrice') ||
          toUpdate.hasOwnProperty('nitrogenPriceN5')
        ) {
          toUpdate.economicAttributeCollection = {
            nitrogenPrice: {
              currentEconomicValue: toUpdate.nitrogenPrice ?? nitrogenValueClone.nitrogenPrice,
              futureEconomicValue: toUpdate.hasOwnProperty('nitrogenPriceN5')
                ? copyCurrentValueToFuture(
                    toUpdate.nitrogenPriceN5,
                    nitrogenValueClone.nitrogenPriceN5
                  )
                : nitrogenValueClone.nitrogenPriceN5,
            },
          };

          formData.append(
            'economicAttributeCollection[nitrogenPrice][currentEconomicValue]',
            toUpdate.nitrogenPrice ?? nitrogenValueClone.nitrogenPrice
          );

          formData.append(
            'economicAttributeCollection[nitrogenPrice][futureEconomicValue]',
            toUpdate.hasOwnProperty('nitrogenPriceN5')
              ? copyCurrentValueToFuture(
                  toUpdate.nitrogenPriceN5,
                  nitrogenValueClone.nitrogenPriceN5
                )
              : nitrogenValueClone.nitrogenPriceN5
          );

          toUpdate.hasOwnProperty('nitrogenPrice') && delete toUpdate.nitrogenPrice;
          toUpdate.hasOwnProperty('nitrogenPriceN5') && delete toUpdate.nitrogenPriceN5;
        }

        if (toUpdate.economicAttributeCollection) {
          setNitrogenValueClone((prev) => ({
            ...prev,
            economicAttributeCollection: toUpdate.economicAttributeCollection,
          }));
        }

        try {
          setOpenNitrogenPriceDialog(false);
          if (!owner) {
            await updateStructure(formData, cooperativeId)
              .then(() => {
                toast.success(`Cooperative ${cooperativeName} mis à jour`);
              })
              .catch((err) => {
                toast.error(err.message);
              });
          } else if (owner.parent === 'fromSector') {
            toUpdate.economicAttributeCollection = {
              id: owner.id,
              ...toUpdate,
            };
            await updateSecteur(toUpdate.economicAttributeCollection);
            toast.success(`Secteur ${sector} mis à jour`);
          } else {
            await updateExploitation(toUpdate, owner.id);
            toast.success(`Exploitation ${exploitation?.name} mis à jour`);
          }
          getEconomicAttribute();
        } catch (error) {
          console.log(error, 'error');
          toast.error(error.response.data.message);
        }
      }
      setOpenNitrogenPriceDialog(false);
    }
  };

  const handleCloseNitrogenPriceDialog = () => {
    setOpenNitrogenPriceDialog(false);
    setErrorsNitrogenFormEconomicValue((prev) => ({
      ...prev,
      formError: false,
      nitrogenPrice: {
        ...prev.nitrogenPrice,
        message: '',
      },
      nitrogenPriceN5: {
        ...prev.nitrogenPriceN5,
        message: '',
      },
    }));
  };

  const isOpen = useMemo(
    () => errorsFormNitrogenEconomicValue.formError,
    [errorsFormNitrogenEconomicValue]
  );
  return (
    <ThemeProvider theme={customDialogNitrogenTheme}>
      <Dialog maxWidth="md" open={openNitrogenPriceDialog}>
        <Box>
          <DialogTitle>Coût de l'azote</DialogTitle>
          <ClearIcon onClick={handleCloseNitrogenPriceDialog} />
        </Box>

        <DialogContent dividers>
          <InputLabel id="nitrogenPriceLabel" htmlFor="nitrogenPrice">
            Coût de l'azote
          </InputLabel>
          {errorsFormNitrogenEconomicValue?.nitrogenPrice.message && (
            <>
              <StyledTooltipErrorInputCostModal
                open={isOpen}
                title={errorsFormNitrogenEconomicValue?.nitrogenPrice?.message}
                placement="top"
              >
                <span></span>
              </StyledTooltipErrorInputCostModal>
            </>
          )}
          <TextField
            id="nitrogenPrice"
            value={nitrogenValueClone?.nitrogenPrice}
            onChange={(e) => handleChange(e, 'nitrogenPrice')}
            className={errorsFormNitrogenEconomicValue?.nitrogenPrice.message ? 'MuiErrorForm' : ''}
            placeholder="Entrez un coût de l'azote"
            autoFocus={true}
          />
          <InputLabel id="nitrogenPriceN5Label" htmlFor="nitrogenPriceN5">
            Coût de l'azote N+5
          </InputLabel>
          {errorsFormNitrogenEconomicValue?.nitrogenPriceN5.message && (
            <StyledTooltipErrorInputCostModal
              open={isOpen}
              title={errorsFormNitrogenEconomicValue?.nitrogenPriceN5?.message}
              placement="top"
            >
              <span></span>
            </StyledTooltipErrorInputCostModal>
          )}
          <TextField
            id="nitrogenPriceN5"
            value={nitrogenValueClone?.nitrogenPriceN5}
            onChange={(e) => handleChange(e, 'nitrogenPriceN5')}
            className={
              errorsFormNitrogenEconomicValue?.nitrogenPriceN5.message ? 'MuiErrorForm' : ''
            }
            placeholder="Entrez un coût de l'azote N+5"
          />
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={customButtonTheme}>
            <Button id="nitrogenPrice" onClick={handleNitrogenPriceClick}>
              Enregistrer
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      ;
    </ThemeProvider>
  );
};

export default NitrogenPriceModal;
