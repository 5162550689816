import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

// import userProp from 'components/propTypes/user';

import secteurFullProp from 'components/propTypes/secteurFull';

import {
  addExploitation,
  addFarmer,
  removeFarmer,
  removeExploitation,
  updateExploitation,
} from 'services/API/Secteur';
import './exploitations.styles.scss';
import Button from 'components/generics/Button';
import InputLabel from 'components/generics/InputLabel';
import IconButton from 'components/generics/IconButton';
import More from 'components/icons/More';
import Label from 'components/generics/Label';
import Close from 'components/icons/Close';
import { LoadingContext } from 'utils/context';
import { displayErrorMessage, displayMessage, emptyFieldToZero } from 'utils/tools_functions';
import {
  inputNumberValidator,
  inputRequired,
  inputVerifyMail,
  inputIntegerValidator,
} from 'components/generics/Validators/validator';
import { validateFormData } from 'components/generics/Validators/form.validate';

const DEFAULT_FORM = { name: '', code: '', irrigability: 0 };
const DEFAULT_FARMER = {
  firstname: '',
  lastname: '',
  email: '',
  role: ['ROLE_FARMER'],
  cooperative: 0,
  sectors: [],
  exploitations: [],
};

const ExploitationForm = ({
  onSubmitSuccess,
  edit,
  secteur,
  isEdit,
  cooperativeId,
  loadDataSector,
}) => {
  const [data, setData] = useState(isEdit ? edit : DEFAULT_FORM);
  const [currentFarmers, setCurrentFarmers] = useState(isEdit ? edit.farmers : []);
  const [newFarmers, setNewFarmers] = useState([]);
  const [addFarmers, setAddFarmers] = useState(DEFAULT_FARMER);
  const [removeId, setRemoveId] = useState([]);
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired],
    },
    code: {
      message: '',
      validator: [inputRequired, inputNumberValidator],
    },
    irrigability: {
      message: '',
      validator: [inputIntegerValidator],
    },
  });
  const [errorsFormFarmer, setErrorsFormFarmer] = useState({
    formError: false,
    firstname: {
      message: '',
      validator: [inputRequired],
    },
    lastname: {
      message: '',
      validator: [inputRequired],
    },
    email: {
      message: '',
      validator: [inputRequired, inputVerifyMail],
    },
  });
  const [isSuspendOpen, setIsSuspendOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const setTextLoader = useContext(LoadingContext);

  const updateNewFarmer = (key, value) => setAddFarmers({ ...addFarmers, [key]: value });

  const updateForm = (key, value) => {
    if (key === 'irrigability') {
      setData({ ...data, [key]: emptyFieldToZero(value) });
    } else {
      setData({ ...data, [key]: value });
    }
  };

  const cooperativeIdChoose = Number(localStorage.getItem('cooperative'));
  const addFarmerHandle = () => {
    if (addFarmers !== DEFAULT_FARMER) {
      errorsFormFarmer.formError = false;
      setErrorsFormFarmer({ ...validateFormData(addFarmers, errorsFormFarmer) });
      if (!errorsFormFarmer.formError) {
        const newArr = newFarmers;
        newArr.push(addFarmers);
        setNewFarmers(newArr);
        setAddFarmers(DEFAULT_FARMER);
      }
    }
  };
  const deleteCurrentFarmer = (farmer) => {
    const newDeleteArray = removeId;
    newDeleteArray.push(farmer.id);
    setRemoveId(newDeleteArray);
    const newCurrentFarmer = currentFarmers.filter(
      (currentFarmer) => currentFarmer.id !== farmer.id
    );
    setCurrentFarmers(newCurrentFarmer);
  };
  const deleteNewFarmer = (index) => {
    const arr = [];
    for (let i = 0; i < newFarmers.length; i++) {
      if (i !== index) {
        arr.push(newFarmers[i]);
      }
    }
    setNewFarmers(arr);
  };
  const handleOnSubmit = () => {
    errorsFormDefault.formError = false;
    if (isEdit && data.irrigability === -1) {
      data.irrigability = 0;
    }
    setErrorsFormDefault({ ...validateFormData(data, errorsFormDefault) });
    if (!errorsFormDefault.formError) {
      setLoading(true);
      setTextLoader.customText = displayMessage('UPDATE_EXPLOTATION_LOADER');
      if (isEdit) {
        const editData = {
          name: data.name,
          code: data.code,
          irrigability: data.irrigability,
        };
        removeId.forEach((id) => {
          removeFarmer(id)
            .then(() => {
              toast.success(`Agriculteur supprimé`);
              loadDataSector(secteur.id);
            })
            .catch((err) => {
              toast.error(err.message);
            });
        });
        newFarmers.forEach((farmer) => {
          farmer.cooperative = cooperativeIdChoose ? cooperativeIdChoose : cooperativeId;
          farmer.sectors = [...farmer.sectors, secteur.id];
          farmer.exploitations = [...farmer.exploitations, data.id];
          addFarmer(farmer)
            .then(() => {
              toast.success(`Agriculteur ${farmer.firstname} ${farmer.lastname} créé`);
              loadDataSector(secteur.id);
            })
            .catch((err) => {
              if (err.response.data.status === 409) {
                return toast.error(displayErrorMessage('ERR_ADD_EXISTING_EMAIL', farmer));
              }
              toast.error(err.response.data.message);
            });
        });
        updateExploitation(editData, data.id)
          .then(() => {
            toast.success(`Exploitation ${editData.name} mise à jour`);
            loadDataSector(secteur.id);
            onSubmitSuccess();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        const addData = {
          name: data.name,
          code: data.code,
          irrigability: data.irrigability,
          sector: secteur.id,
        };
        setTextLoader.customText = displayMessage('CREATE_EXPLOTATION_LOADER');
        addExploitation(addData)
          .then((response) => {
            toast.success(`Exploitation ${addData.name} créée.`);
            const exploitationId = response.data.data.id;
            newFarmers.forEach((farmer) => {
              farmer.cooperative = cooperativeIdChoose ? cooperativeIdChoose : cooperativeId;
              farmer.sectors = [...farmer.sectors, secteur.id];
              farmer.exploitations = [...farmer.exploitations, exploitationId];
              addFarmer(farmer)
                .then(() => {
                  toast.success(`Agriculteur ${farmer.firstname} ${farmer.lastname} créé`);
                  loadDataSector(secteur.id);
                })
                .catch((err) => {
                  if (err.response.data.status === 409) {
                    return toast.error(displayErrorMessage('ERR_ADD_EXISTING_EMAIL', farmer));
                  }
                  toast.error(err.response.data.message);
                });
            });
            onSubmitSuccess();
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
    setLoading(false);
  };

  const triggerRaccourcis = (e) => {
    if (e.keyCode === 13) {
      handleOnSubmit();
    }
  };

  const handleSuspend = () => {
    removeExploitation(data.id)
      .then(() => {
        toast.success(`Exploitation ${data.name} supprimé`);
        loadDataSector(secteur.id);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    onSubmitSuccess();
  };
  return (
    <>
      <h1>{isEdit ? 'Editer une exploitation' : 'Ajouter une exploitation'}</h1>
      <form onKeyDown={(e) => triggerRaccourcis(e)}>
        {errorsFormDefault?.name?.message && (
          <p className="errorForm">{errorsFormDefault.name.message}</p>
        )}
        <InputLabel
          onChange={(e) => {
            updateForm('name', e);
          }}
          value={data.name}
          name="name"
          defaultStyle="secondary"
          placeholder="Nom"
          label="Nom"
          disabled={loading}
          required
        />
        {errorsFormDefault?.code?.message && (
          <p className="errorForm">{errorsFormDefault.code.message}</p>
        )}
        <InputLabel
          onChange={(e) => {
            updateForm('code', e);
          }}
          value={data.code}
          name="code"
          defaultStyle="secondary"
          placeholder="Code"
          label="Code"
          disabled={loading}
          required
        />
        {errorsFormDefault?.irrigability?.message && (
          <p className="errorForm">{errorsFormDefault.irrigability.message}</p>
        )}
        <InputLabel
          value={data.irrigability === -1 ? 0 : data.irrigability}
          onChange={(e) => {
            updateForm('irrigability', e);
          }}
          name="irrigability"
          defaultStyle="secondary"
          placeholder="Capacité d’irrigation (en m3)"
          label="Capacité d’irrigation (en m3)"
          dataType="digit"
          disabled={loading}
        />
        <InputLabel
          value={secteur.name}
          onChange={() => null}
          name="sector"
          defaultStyle="secondary"
          placeholder={secteur.name}
          label="SECTEUR"
          disabled={true}
        />
        <Label text="Agriculteur" name="Agriculteur" className="secondary">
          <div className="agriculteur-box">
            {currentFarmers.length > 0 &&
              currentFarmers.map((farmer) => (
                <div key={farmer.id}>
                  <Button
                    onClick={() => deleteCurrentFarmer(farmer)}
                    text={`${farmer.firstname} ${farmer.lastname}`}
                    defaultStyle="agriculteur-button"
                    backgroundColor="#277134"
                    textColor="#ebebeb"
                    disabled={loading}
                    icon={<Close width={13} height={13} />}
                  />
                </div>
              ))}
            {newFarmers.map((farmer, index) => (
              <div key={index}>
                <Button
                  onClick={() => deleteNewFarmer(index)}
                  text={`${farmer.firstname} ${farmer.lastname}`}
                  defaultStyle="agriculteur-button"
                  backgroundColor="#277134"
                  textColor="#ebebeb"
                  disabled={loading}
                  icon={<Close width={13} height={13} />}
                />
              </div>
            ))}
          </div>
        </Label>
        <div className="add-agriculteur">
          <Label text="Ajouter un agriculteur " name="Status" className="secondary">
            <>
              <div style={{ margin: '20px 0px' }}>
                {errorsFormFarmer?.firstname.message && (
                  <p className="errorForm">{errorsFormFarmer?.firstname?.message}</p>
                )}
                <InputLabel
                  onChange={(e) => {
                    updateNewFarmer('firstname', e);
                  }}
                  value={addFarmers.firstname}
                  name="firstname"
                  defaultStyle="secondary"
                  placeholder="Prénom"
                  disabled={loading}
                  label="Prénom"
                />
                {errorsFormFarmer?.lastname?.message && (
                  <p className="errorForm">{errorsFormFarmer?.lastname?.message}</p>
                )}
                <InputLabel
                  onChange={(e) => {
                    updateNewFarmer('lastname', e);
                  }}
                  value={addFarmers.lastname}
                  name="lastname"
                  defaultStyle="secondary"
                  placeholder="Nom de famille"
                  disabled={loading}
                  label="Nom de famille"
                />
                {errorsFormFarmer?.email?.message && (
                  <p className="errorForm">{errorsFormFarmer?.email?.message}</p>
                )}
                <InputLabel
                  onChange={(e) => {
                    updateNewFarmer('email', e);
                  }}
                  value={addFarmers.email}
                  name="email"
                  defaultStyle="secondary"
                  placeholder="Email"
                  type="email"
                  disabled={loading}
                  label="Email"
                />
              </div>
              <div style={{ heigh: 10, marginLeft: '20px' }}>
                <IconButton
                  onClick={() => addFarmerHandle()}
                  type="button"
                  text="Ajouter"
                  icon={<More width={13} height={13} />}
                />
              </div>
            </>
          </Label>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
          <div className="btn">
            <Button
              defaultStyle="form"
              type="submit"
              text="Supprimer I'exploitation"
              disabled={loading}
              onClick={(e) => {
                e.preventDefault();
                setIsSuspendOpen(true);
              }}
            />
          </div>
          <div>
            <Button
              defaultStyle="form"
              text="Enregistrer"
              onClick={() => handleOnSubmit()}
              disabled={loading}
            />
          </div>
        </div>
        {isSuspendOpen && (
          <div className="modal">
            <div
              style={{ transform: 'scale(0.6)', marginRight: '30px' }}
              tabIndex={0}
              role="button"
              aria-label="close"
              className="close"
              onClick={() => {
                setIsSuspendOpen(false);
              }}
            >
              <Close />
            </div>
            <div className="content-suspend">
              <h1>Supprimer une exploitation</h1>
              <p>Vous êtes sur le point de supprimer la exploitation</p>
              <p>{isEdit ? `${data.name}` : ''} souhaitez-vous continuer?</p>
              <div className="group-button">
                <div className="close-button">
                  <Button
                    defaultStyle="form"
                    text="Annuler"
                    onClick={() => setIsSuspendOpen(false)}
                  />
                </div>
                <div>
                  <Button defaultStyle="form" text="Supprimer" onClick={() => handleSuspend()} />
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

ExploitationForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
  // edit: PropTypes.oneOf([PropTypes.shape(userProp), null]).isRequired,
  // edit: PropTypes.oneOf([PropTypes.shape(exploitationProp), null]).isRequired,
  secteur: secteurFullProp.isRequired,
};

ExploitationForm.defaultProp = {
  edit: null,
};

export default ExploitationForm;
