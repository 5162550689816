import { useContext } from 'react';
import './parcelSynthesisTable.style.scss';
import './table.style.scss';
import { PdfContext } from 'utils/context';
import gouteletteLogo from 'assets/images/laissez-tomber-min.png';
import logoCover from 'assets/images/logo-cover-min.png';
import logoCoverTransparent from 'assets/images/logo-cover-transparent-min.png';
import { v1 } from 'uuid';
import { toLowerCaseWithFirstLetterUpper } from '../Generics/utilsFunctions';

const ParcelSynthesisTable = ({ repetition, parcels, years, messageDuplicate }) => {
  const { currentYear, cultures } = useContext(PdfContext);
  let headers = [
    ...['Parcelle', 'Surface'],
    ...Array(years.length + 1)
      .fill()
      .map((_, i) => `${i + (currentYear - 1)}-${i + currentYear}`),
  ];
  const heightTableHead = '45px';
  const heightTableBody = '592.5px';

  return (
    <article className="pdf-page">
      {years?.[0]?.nitrogen && years?.[0]?.nitrogen?.coverGainInUnit !== 0 && repetition === 1 && (
        <div className="legend_simulation_synthesis">
          <div className="legend-cover">
            <img src={logoCover} alt="Logo couvert" />
            Couvert à base légumineuse avant la culture
          </div>
          <div className="legend-cover goutelette_img">
            <img src={logoCoverTransparent} alt="Logo couvert" />
            Autre couvert possible avant la culture 
          </div>
        </div>
      )}
      <div className="tableau_center">
        <h6 className="title_h6">
          {parcels.length > 0 && messageDuplicate !== ''
            ? `Rotation des cultures par parcelle ${messageDuplicate}`
            : `Rotation des cultures par parcelle`}
        </h6>
        <table
          className="table table-full-page"
          style={{
            height: parcels.length <= 5 ? 'auto' : '',
          }}
        >
          <thead className="table_head" style={{ height: heightTableHead }}>
            <tr className="table_tr">
              {headers.map((header, i) => (
                <th key={i} scope="col" className="column_name">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody style={{ height: parcels.length <= 5 ? 'auto' : heightTableBody }}>
            {parcels?.map((parcel, idxParcel) => (
              <tr
                className="table_tr"
                key={v1()}
                style={{ height: parcels.length <= 5 ? 'auto' : `calc(100% / ${parcels.length})` }}
              >
                <td className="parcel_value">{parcelNameCell(parcel?.isParent, parcel?.name)}</td>
                <td className="parcel_value">{(parcel?.surface.toFixed(1) + ' ha').toString()}</td>
                {parcel?.cultures.map((culture, idx) => (
                  <td className="parcel_value" key={v1()}>
                    <div className="flex-column-synthesis">
                      <div className="cell-content-name">
                        {toLowerCaseWithFirstLetterUpper(culture.name.toString())}
                      </div>
                      {hasIcon(cultures, culture, idxParcel, idx, years, parcel?.cover) && (
                        <div className="icons">
                          {cultures.find((exploitCulture) => exploitCulture.id === culture.id)
                            ?.color && (
                            <div
                              className="color"
                              style={{
                                backgroundColor: cultures.find(
                                  (exploitCulture) => exploitCulture.id === culture.id
                                )?.color,
                              }}
                            ></div>
                          )}
                          {years[idx - 1]?.irrigationDistribution[idxParcel] > 0 && (
                            <img
                              className="goutelette_img"
                              src={gouteletteLogo}
                              alt="Logo irrigation"
                            />
                          )}
                          {idx > 0 &&
                            parcel?.cover?.length > 0 &&
                            (parcel?.cover?.[idx - 1] === 0 || parcel?.cover?.[idx - 1] === 1) && (
                              <img
                                className="goutelette_img"
                                src={
                                  parcel?.cover[idx - 1] === 0 ? logoCoverTransparent : logoCover
                                }
                                alt="Logo cover"
                              />
                            )}
                        </div>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
  );
};

const parcelNameCell = (isParent, parcelName) => {
  return (
    <div className="cell-content">
      {isParent ? 'Îlot ' : ''}
      {toLowerCaseWithFirstLetterUpper(parcelName)}
    </div>
  );
};

const hasIcon = (allCultures, culture, indexParcel, indexCulture, years, cover) => {
  let hasCoverIcon = false;
  if (cover) {
    const coverValue = cover?.[indexCulture - 1];
    if (coverValue === 0 || coverValue === 1) {
      hasCoverIcon = true;
    }
  }

  return (
    allCultures.find((exploitCulture) => exploitCulture.id === culture.id)?.color ||
    years[indexCulture - 1]?.irrigationDistribution[indexParcel] > 0 ||
    hasCoverIcon
  );
};

export default ParcelSynthesisTable;
