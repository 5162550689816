import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

const Item = ({ active, path, text }) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const regex = /import/i;
  const regexComparator = /comparator/i;

  useEffect(() => {
    if (
      (regex.test(location.pathname) && text === 'Import') ||
      (regexComparator.test(location.pathname) && text === 'Comparateur')
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [regex]);

  return (
    <Link title={text} to={`${path}`}>
      <div className={`item ${active ? ' active' : ''} ${isActive ? ' active' : ''}`}>
        <span>{text}</span>
      </div>
    </Link>
  );
};

Item.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

Item.defaultProps = {
  active: false,
};

export default Item;
