import Box from '@mui/material/Box';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import useDataGridLoading from './useDataGridLoading';
import './result.styles.scss';
import {
  CustomForecastHeader,
} from '../../../../utils/Datagrid/CustomForecastDataGridUtils';

export default function DataGridCAPPoints({
  comparatorSimulationResult,
  comparatorCalculatorResult,
  currentYear,
}) {
  const dataGridLoading = useDataGridLoading(
    comparatorSimulationResult,
    comparatorCalculatorResult
  );

  const columns = [
    {
      field: 'campaign',
      headerName: 'Campagne',
      headerClassName: 'result-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
    },
    {
      field: 'yearN1',
      headerName: `${currentYear - 2}-${currentYear - 1}`,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'yearN',
      headerName: `${currentYear - 1}-${currentYear}`,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'forecast',
      headerName: `${currentYear}-${currentYear + 1} (prévisionnel)`,
      renderHeader: () => CustomForecastHeader(currentYear, 'prévisionnel'),
      sortable: false,
      headerClassName: 'forecast-header header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
    },
    {
      field: 'simulationSuggest',
      headerName: `${currentYear}-${currentYear + 1} (Assolia)`,
      renderHeader: () => CustomForecastHeader(currentYear, 'Assolia'),
      sortable: false,
      headerClassName: 'forecast-header header-assolia header-material',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: CustomForecastCell,
    },
  ];

  const processedRows = comparatorCalculatorResult
    ? comparatorCalculatorResult?.result.years[0]?.capPoints?.map((capCategory) => {
        const CAPPerCategory = (data) =>
          data.find(
            (simulationCapCategory) => simulationCapCategory.categorie === capCategory.categorie
          );
        const simulationCAPPerCategory = CAPPerCategory(
          comparatorSimulationResult.suggestions.suggestion1?.years[0].capPoints
        );
        const yearNCAPPerCategory = CAPPerCategory(comparatorSimulationResult.previousPacPoints[1]);
        const yearN1CAPPerCategory = CAPPerCategory(
          comparatorSimulationResult.previousPacPoints[0]
        );

        return {
          yearN1: yearN1CAPPerCategory?.points,
          yearN: yearNCAPPerCategory?.points,
          id: capCategory?.categorie,
          campaign: capCategory?.categorie,
          forecast: capCategory?.points,
          simulationSuggest: simulationCAPPerCategory?.points,
        };
      })
    : [];

  const simulationTotalPacPoints = (data) => data.reduce((acc, cur) => acc + cur.points, 0);

  const rows = [
    ...processedRows,
    {
      id: 'Total',
      campaign: 'Total',
      yearN1: simulationTotalPacPoints(comparatorSimulationResult.previousPacPoints[0]),
      yearN: simulationTotalPacPoints(comparatorSimulationResult.previousPacPoints[1]),
      forecast: comparatorCalculatorResult?.result.years[0]['Points PAC'],
      simulationSuggest: simulationTotalPacPoints(
        comparatorSimulationResult.suggestions.suggestion1?.years[0].capPoints
      ),
    },
  ];
  
  const getRowClassName= (params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd';

  return <CustomDataGridResult rows={rows} columns={columns} isLoading={dataGridLoading} getRowClassName={getRowClassName} />;
}

const CustomForecastCell = (params) => {
  if (
    params.formattedValue !== '0' &&
    Number(params.formattedValue) > Number(params.row.forecast)
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ position: 'relative' }}>
          <ArrowDropUpIcon
            sx={{
              color: '#277134',
              fontSize: '30px',
              position: 'absolute',
              top: '-2px',
              right: '100%',
            }}
          />
          <div>{params.value}</div>
        </div>
      </Box>
    );
  }
  if (Number(params.formattedValue) < Number(params.row.forecast)) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ position: 'relative' }}>
          <ArrowDropDownIcon
            sx={{
              color: 'red',
              fontSize: '30px',
              position: 'absolute',
              top: '-2px',
              right: '100%',
            }}
          />
          <div>{params.value}</div>
        </div>
      </Box>
    );
  }
};
