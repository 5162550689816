export const validateDatagridData = (toUpdate, errors, columns, rowId, field) => {
  // Array to get the keys who need to be chekc over by some validator
  const keyToValidate = Object.keys(toUpdate)
    .filter((key) => errors.hasOwnProperty(key))
    .reduce((obj, key) => {
      obj[key] = toUpdate[key];
      return obj;
    }, {});

  for (const [key, value] of Object.entries(keyToValidate)) {
    // Get the name from the input verified from his label's text
    const inputName = field ? field : columns.find((column) => column.field === key).headerName;
    errors[key].message = '';
    if (errors[key].validator) {
      for (const inputValidator of errors[key].validator) {
        // Interate through all inputValidator gave from the errors json for the current key
        let messageError = inputValidator(value, inputName);
        if (messageError) {
          errors[key].message += messageError + '\r\n';
          errors.formError = true;
          errors.rowId = rowId;
        }
      }
    }
  }
  return errors;
};

export const validateDatagridDataCapCategory = (
  toUpdate,
  errors,
  columns,
  rowId,
  foundCapCategoryInRow,
  foundIsArable,
  foundCapCategoryInUpdate,
  field
) => {
  // Array to get the keys who need to be chekc over by some validator
  const keyToValidate = Object.keys(toUpdate)
    .filter((key) => errors.hasOwnProperty(key))
    .reduce((obj, key) => {
      obj[key] = toUpdate[key];
      return obj;
    }, {});

  for (const [key, value] of Object.entries(keyToValidate)) {
    let isAllow = true;
    if (
      (foundCapCategoryInUpdate &&
        !foundCapCategoryInUpdate.allowArable &&
        toUpdate.isArable === true) ||
      (foundCapCategoryInUpdate && !foundCapCategoryInUpdate.allowArable && foundIsArable) ||
      (foundCapCategoryInRow && !foundCapCategoryInRow.allowArable && toUpdate.isArable === true)
    ) {
      isAllow = false;
    }
    if (
      foundCapCategoryInUpdate &&
      foundCapCategoryInUpdate.allowArable &&
      toUpdate.isArable === true
    ) {
      isAllow = true;
    }
    if (
      foundCapCategoryInUpdate &&
      !foundCapCategoryInUpdate.allowArable &&
      toUpdate.isArable === false
    ) {
      isAllow = true;
    }

    // Get the name from the input verified from his label's text
    let inputName = '';
    inputName = field ? field : columns.find((column) => column.field === key).headerName;

    errors[key].message = '';
    if (errors[key].validator) {
      for (const inputValidator of errors[key].validator) {
        // Interate through all inputValidator gave from the errors json for the current key
        let messageError = inputValidator(
          isAllow,
          inputName,
          value,
          foundCapCategoryInUpdate
            ? foundCapCategoryInUpdate.displayName
            : foundCapCategoryInRow?.displayName
        );
        if (messageError) {
          errors[key].message += messageError + '\r\n';
          errors.formError = true;
          errors.rowId = rowId;
        }
      }
    }
  }
  return errors;
};

export const validateDatagridDataRotateEffect = (
  toUpdate,
  errors,
  rowId,
  field,
  currentRotationLevel
) => {
  // Array to get the keys who need to be chekc over by some validator
  const keyToValidate = Object.keys(toUpdate)
    .filter((key) => errors.hasOwnProperty(key))
    .reduce((obj, key) => {
      obj[key] = toUpdate[key];
      return obj;
    }, {});

  for (const [key, value] of Object.entries(keyToValidate)) {
    // Get the name from the input verified from his label's text
    // const inputName = field ? field : columns.find((column) => column.field === key).headerName;
    errors[key].message = '';
    if (errors[key].validator) {
      for (const inputValidator of errors[key].validator) {
        // Interate through all inputValidator gave from the errors json for the current key
        let messageError = inputValidator(value);
        if (messageError) {
          errors[key].message += messageError + '\r\n';
          errors.formError = true;
          if (!errors?.cellErrors?.[currentRotationLevel].hasOwnProperty(rowId)) {
            errors.cellErrors[currentRotationLevel] = {
              ...errors.cellErrors[currentRotationLevel],
              [rowId]: [field],
            };
          } else if (
            errors?.cellErrors?.[currentRotationLevel].hasOwnProperty(rowId) &&
            !errors?.cellErrors?.[currentRotationLevel]?.[rowId].includes(field)
          ) {
            errors.cellErrors[currentRotationLevel][rowId].push(field);
          }
        } else {
          if (
            errors?.cellErrors?.[currentRotationLevel].hasOwnProperty(rowId) &&
            errors?.cellErrors?.[currentRotationLevel]?.[rowId].includes(field)
          ) {
            errors.cellErrors[currentRotationLevel][rowId] = errors?.cellErrors?.[
              currentRotationLevel
            ]?.[rowId].filter((error) => error !== field);
            if (errors?.cellErrors?.[currentRotationLevel]?.[rowId]?.length === 0) {
              delete errors?.cellErrors?.[currentRotationLevel]?.[rowId];
            }
          }
        }
      }
    }
  }

  return errors;
};
