import React from 'react';
import PropTypes from 'prop-types';

import actionsProps from 'components/propTypes/actions';

import ActionList from 'components/generics/Table/ActionList';

const GroundTypeTableRow = ({ data, actions }) => (
  <tr>
    <td>{data.name}</td>
    <td>
      <ActionList data={data} actions={actions} />
    </td>
  </tr>
);

GroundTypeTableRow.propTypes = {
  data: PropTypes.shape({ name: PropTypes.string }),
  actions: actionsProps,
};

GroundTypeTableRow.defaultProps = {
  data: {},
  actions: [],
};

export default GroundTypeTableRow;
