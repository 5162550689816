import { useEffect, useState } from 'react';
import {
  getCAPCategoriesList,
  getCoProductsPerCooperative,
  getCooperative,
  getCultures,
} from 'services/API/Cooperative';
import { toast } from 'react-toastify';
import { displayErrorMessage } from 'utils/tools_functions';
import DataGridCulture from './DataGridCulture';

const CulturesDataGridCooperative = ({
  user,
  cooperativeId,
  cooperativeName,
  economicAttribute,
  setEconomicAttribute,
  nitrogenCover,
  setNitrogenCover,
}) => {
  const [CAPCategories, setCAPCategories] = useState(null);
  const [coProductsList, setCoProductsList] = useState(null);
  const [cooperativeCultures, setCooperativeCultures] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    getCAPCategories();
    getCoProducts();
    getCooperativeCultures();
  };

  const getCooperativeCultures = async () => {
    try {
      const result = await getCultures(cooperativeId);
      const data = result.data;
      setCooperativeCultures(data);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_GET_CULTURE'));
    }
  };

  const getEconomicAttribute = async () => {
    try {
      const result = await getCooperative(cooperativeId);
      const data = result.data;
      setEconomicAttribute(data.data?.economicAttributeCollection);
      setNitrogenCover(data.data?.nitrogenSupplyByCover);
    } catch (error) {
      toast.error(error.data);
    }
  };

  const getCAPCategories = async () => {
    try {
      const result = await getCAPCategoriesList();
      const { data } = result.data;
      setCAPCategories([{ id: 0, displayName: 'Aucune' }].concat(data));
    } catch (error) {
      toast.error(displayErrorMessage('ERR_GET_PAC_CATEGORIES'));
    }
  };

  const getCoProducts = async () => {
    let cooperativeId = null;
    if (user?.role === 'SUPER_ADMIN') {
      cooperativeId = Number(localStorage.getItem('cooperative'));
    }
    try {
      const result = await getCoProductsPerCooperative(cooperativeId);
      const { data } = result.data;
      setCoProductsList([{ id: null, displayName: 'Aucun' }].concat(data));
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <DataGridCulture
      columnsDefinition={cooperativeCultures?.metadata}
      from={'fromCooperative'}
      cultures={cooperativeCultures?.data}
      refreshData={loadData}
      user={user}
      CAPCategories={CAPCategories}
      coProductsList={coProductsList}
      cooperativeId={cooperativeId}
      economicAttribute={economicAttribute}
      cooperativeName={cooperativeName}
      nitrogenCover={nitrogenCover}
      getEconomicAttribute={getEconomicAttribute}
    />
  );
};

export default CulturesDataGridCooperative;
