// REACT
import React, {
  ChangeEvent,
  Fragment,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

// YARN
import {
  Box,
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  tooltipClasses,
  Checkbox,
  SelectChangeEvent,
  Modal,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import {
  GridApiPro,
  GridEditInputCell,
  GridEditInputCellProps,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { v4 as uuidv4 } from 'uuid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ThemeProvider } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// fix type issue for for createTheme => https://github.com/mui/mui-x/issues/1755#issuecomment-945650984
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { toast } from 'react-toastify';

// SERVICES
import {
  checkDecimalRegexTwoDigitsDatagridValue,
  checkNoDecimalRegexTwoDigitsDatagridValue,
  checkTypeStringRegexDatagrid,
  getOnlyAttributeUpdated,
  getStringInsideParentheses,
} from '../tools_functions';
import { StyledTooltip, mediaQuery } from './CustomGenericDataGridUtils';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { updateStructure } from 'services/API/Administration';

// Interface
import { ICulture } from 'components/generics/Interface/ICulture';
import { ICoproduct } from 'components/generics/Interface/ICoproduct';
import { ICapCategory } from 'components/generics/Interface/ICapCategory';
import { IPriceTabsValues } from 'components/generics/Interface/IPriceTabsValues';
import { IFormErrors, IFormErrorObjects } from 'components/generics/Interface/Commons/IErrorForm';
import { IInputCostValue } from 'components/generics/Interface/Commons/Culture/IInputCostValue';
import { IParamsInputCost } from 'components/generics/Interface/Commons/Culture/IParamsInputCost';
import { ICostTabsValuesRef } from 'components/generics/Interface/Commons/Culture/ICostTabsValuesRef';

// Theme
import { customButtonTheme } from '../../assets/styles/themes/generic_button_mui';
import { preventDefault } from 'ol/events/Event';
import { IErrorResponse } from 'components/generics/Interface/Commons/IErrorResponse';

type CustomHeaderWithoutSortParams = {
  name: string;
};

export const essentialColumns = {
  yieldProduct: false,
  coProduct: false,
  irrigationYieldGain: false,
  irrigationWaterNeeded: false,
  isSummerCulture: false,
  isStrawProducer: false,
  capCategory: false,
  isIrrigableOnly: false,
  isArable: false,
  priceN5: false,
  inputCostN5: false,
  tfiHerbicide: false,
  tfiWithoutHerbicide: false,
  nitrogenLimit: false,
  nitrogenBonus: false,
  inputCostWithoutNitrogen: false,
  inputCostWithoutNitrogenN5: false,
  grossMarginN5: false,
};

export const allColumns = {
  priceN5: false,
  inputCostN5: false,
  tfiHerbicide: false,
  tfiWithoutHerbicide: false,
  inputCostWithoutNitrogen: false,
  inputCostWithoutNitrogenN5: false,
  grossMarginN5: false,
};

export const CustomHeaderWithoutSort = ({
  name,
}: CustomHeaderWithoutSortParams): React.JSX.Element => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div
        style={{
          height: '50px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <StyledTooltip placement="top" title={name}>
          <span>{name}</span>
        </StyledTooltip>
      </div>
    </div>
  );
};

type StyledTooltipErrorParams = {
  children: React.JSX.Element;
  open: boolean;
  title: any;
  placement: PlacementType;
  className?: string;
};

type PlacementType =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined;

export const StyledTooltipError = styled(
  ({ children, open, title, placement, className }: StyledTooltipErrorParams) => (
    <Tooltip {...{ children, open, title, placement, className }} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    maxWidth: 120,
    fontSize: '12px',
  },
}));

export const StyledTooltipErrorYieldCoProduct = styled(
  ({ children, open, title, placement, className }: StyledTooltipErrorParams) => (
    <Tooltip {...{ children, open, title, placement, className }} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    maxWidth: 150,
    fontSize: '12px',
    marginBottom: '50px !important',
  },
}));

export const StyledTooltipErrorInputCostModal = styled(
  ({ children, open, title, placement, className }: StyledTooltipErrorParams) => (
    <Tooltip {...{ children, open, title, placement, className }} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    minWidth: 180,
    fontSize: '12px',
    position: 'absolute',
    bottom: '15px',
    marginLeft: '8px !important',
  },
}));

export const StyledTooltipErrorNitrogen = styled(
  ({ className, ...props }: StyledTooltipErrorParams) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {props.children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    minWidth: 180,
    fontSize: '12px',
    position: 'absolute',
    bottom: '15px',
    right: '-90px',
  },
}));

export const CustomCultureColorCell = (color?: string): React.JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {color && (
        <span className="cultureColorCircle" style={{ backgroundColor: `${color}` }}></span>
      )}
    </Box>
  );
};

type CustomCulturePriceCellParams = {
  priceTabsValues: { current: PriceTabsValuesRefParams[] };
  id: number;
  value: number;
  row: ICulture;
};

export const CustomCulturePriceCell = ({
  priceTabsValues,
  id,
  value,
  row,
}: CustomCulturePriceCellParams): React.JSX.Element => {
  const priceTabsValuesRef: { current: PriceTabsValuesRefParams[] } = priceTabsValues;
  const [currentValue, setCurrentValue] = useState<number>(
    priceTabsValuesRef?.current?.find((row: PriceTabsValuesRefParams) => row.id === id)?.value ?? 0
  );
  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setCurrentValue(+newValue);
    priceTabsValuesRef.current = priceTabsValuesRef.current.map(
      (prevRow: PriceTabsValuesRefParams) =>
        prevRow.id === id ? { ...prevRow, value: newValue } : { ...prevRow }
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          margin: '3px 0 -5px 0',
        }}
      >
        <StyledTabs value={currentValue} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N" {...a11yProps(0)} />
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N+5" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel sx={{ textAlign: 'center' }} value={currentValue} index={0}>
        <div>{value}</div>
      </CustomTabPanel>
      <CustomTabPanel sx={{ textAlign: 'center' }} value={currentValue} index={1}>
        <div>{row.priceN5}</div>
      </CustomTabPanel>
    </Box>
  );
};

type CustomGrossMarginCellParams = {
  grossMarginTabsValuesRef: grossMarginTabsValuesRefParams;
  id: number;
  row: ICulture;
};

type grossMarginTabsValuesRefParams = {
  current: grossMarginTabsValuesType[];
};

type grossMarginTabsValuesType = {
  id: number;
  value: number;
};

export const CustomGrossMarginCell = ({
  grossMarginTabsValuesRef,
  id,
  row,
}: CustomGrossMarginCellParams) => {
  const [value, setValue] = useState<number>(
    grossMarginTabsValuesRef.current.find((row: grossMarginTabsValuesType) => row?.id === id)
      ?.value ?? 0
  );
  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
    grossMarginTabsValuesRef.current = grossMarginTabsValuesRef.current.map((prevRow: any) =>
      prevRow.id === id ? { ...prevRow, value: newValue } : { ...prevRow }
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          margin: '3px 0 -5px 0',
        }}
      >
        <StyledTabs value={value} onChange={handleChange} aria-label="marge brute tabs">
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N" {...a11yProps(0)} />
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N+5" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel sx={{ textAlign: 'center' }} value={value} index={0}>
        <div>{+row.grossMargin.toFixed(2)}</div>
      </CustomTabPanel>
      <CustomTabPanel sx={{ textAlign: 'center' }} value={value} index={1}>
        <div>{+row.grossMarginN5.toFixed(2)}</div>
      </CustomTabPanel>
    </Box>
  );
};

type ICustomEditCulturePriceCellParams = {
  params: IPriceTabsValues;
  errorsFormEconomicValue: IFormErrors;
  isOpen: boolean;
  priceTabsValuesRef: { current: PriceTabsValuesRefParams[] };
};

type PriceTabsValuesRefParams = {
  id: number;
  value: number;
};

export const CustomEditCulturePriceCell = ({
  params,
  errorsFormEconomicValue,
  isOpen,
  priceTabsValuesRef,
}: ICustomEditCulturePriceCellParams): React.JSX.Element => {
  const { row, id, field, value, hasFocus }: IPriceTabsValues = params;
  const [priceN5, setPriceN5] = useState<number | null>(row.priceN5);
  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiContext();
  const ref = useRef<HTMLInputElement>(null);
  const [editValue, setEditValue] = useState<number>(
    priceTabsValuesRef?.current?.find((row: PriceTabsValuesRefParams) => row.id === params.id)
      ?.value ?? 0
  );

  let selectedField = field;

  let rowTabValue: PriceTabsValuesRefParams | undefined;
  if (priceTabsValuesRef.current.length > 0) {
    rowTabValue = priceTabsValuesRef?.current.find(
      (row: PriceTabsValuesRefParams) => row.id === params.id
    );
  }

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setEditValue(newValue);

    priceTabsValuesRef.current = priceTabsValuesRef.current.map(
      (prevRow: PriceTabsValuesRefParams) =>
        prevRow.id === params.id ? { ...prevRow, value: newValue } : { ...prevRow }
    );
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = e;
    setPriceN5(+value);
    apiRef.current.setEditCellValue({ id, field: 'priceN5', value });
  };

  if (rowTabValue?.value === 1) {
    selectedField = 'priceN5';
  }

  useEnhancedEffect(() => {
    if (hasFocus && ref?.current) {
      const input: Element | null = ref?.current?.querySelector('#priceN5');
      (input as HTMLElement)?.focus();
    }
  }, [hasFocus, value]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '3px 0 -5px 0' }}>
        {errorsFormEconomicValue.formError &&
          errorsFormEconomicValue.hasOwnProperty(selectedField) &&
          (errorsFormEconomicValue[selectedField as keyof IFormErrors] as IFormErrorObjects).message
            .length > 0 && (
            <StyledTooltipError
              open={isOpen}
              title={
                (errorsFormEconomicValue[selectedField as keyof IFormErrors] as IFormErrorObjects)
                  .message
              }
              placement="top"
            >
              <span></span>
            </StyledTooltipError>
          )}
        <StyledTabs value={editValue} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N" {...a11yProps(0)} />
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N+5" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={editValue} index={0}>
        <GridEditInputCell
          {...params}
          sx={{
            borderBottom:
              errorsFormEconomicValue.formError &&
              // errorsFormEconomicValue.rowId === id &&
              errorsFormEconomicValue.hasOwnProperty(selectedField) &&
              (errorsFormEconomicValue[selectedField as keyof IFormErrors] as IFormErrorObjects)
                .message.length > 0
                ? '2px solid #d32f2f'
                : '1px solid #e69636',
          }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={editValue} index={1}>
        <TextField
          sx={{
            padding: '0 16px',
            borderBottom:
              errorsFormEconomicValue.formError &&
              // errorsFormEconomicValue.rowId === id &&
              errorsFormEconomicValue.hasOwnProperty(selectedField) &&
              (errorsFormEconomicValue[selectedField as keyof IFormErrors] as IFormErrorObjects)
                .message.length > 0
                ? '2px solid #d32f2f'
                : '1px solid #e69636',
          }}
          value={priceN5}
          onChange={(e) => handlePriceChange(e)}
          id="priceN5"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="standard"
          size="small"
          ref={ref}
        />
      </CustomTabPanel>
    </Box>
  );
};

export const EditInputCell = (
  props: GridEditInputCellProps,
  errorsFormDefault: IFormErrors,
  isOpen: boolean
): React.JSX.Element => {
  if (props.field === 'surface') {
    props.value = props.value.surface;
  }
  return errorsFormDefault.formError &&
    // errorsFormDefault.rowId === props.id &&
    errorsFormDefault.hasOwnProperty(props.field) &&
    (errorsFormDefault[props.field as keyof IFormErrors] as IFormErrorObjects).message.length >
      0 ? (
    <>
      <StyledTooltipError
        open={isOpen}
        title={(errorsFormDefault[props.field as keyof IFormErrors] as IFormErrorObjects).message}
        placement="top"
      >
        <span>
          <GridEditInputCell {...props} sx={{ borderBottom: '2px solid #d32f2f' }} />
        </span>
      </StyledTooltipError>
    </>
  ) : (
    <GridEditInputCell {...props} sx={{ borderBottom: '1px solid #e69636' }} />
  );
};

export const CustomNameCell = (name: string): React.JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>{name}</div>
    </Box>
  );
};
type IEditNameCell = {
  params: GridEditInputCellProps;
  errorsFormDefault: IFormErrors;
  isOpen: boolean;
};
export const EditNameCell = ({
  params,
  errorsFormDefault,
  isOpen,
}: IEditNameCell): React.JSX.Element => {
  const { row, id, field, value, hasFocus }: GridEditInputCellProps = params;
  const ref = useRef<HTMLElement>();
  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiContext();
  const [name, setName] = useState<string>(
    row.parentName === row.name ? '' : getStringInsideParentheses(row.name)
  );

  useEnhancedEffect(() => {
    if (hasFocus && ref.current) {
      const input: Element | null = ref.current?.querySelector('#name');
      (input as HTMLElement)?.focus();
    }
  }, [hasFocus, value]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = e;
    setName(checkTypeStringRegexDatagrid(value));
    let newValue = !value.length ? row.parentName : `${row.parentName} (${value}))`;
    apiRef.current.setEditCellValue({ id, field: 'name', value: newValue });
  };
  const isError =
    errorsFormDefault.formError &&
    errorsFormDefault.hasOwnProperty(field) &&
    (errorsFormDefault[field as keyof IFormErrors] as IFormErrorObjects).message.length > 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        {isError && (
          <StyledTooltipError
            open={isOpen}
            title={(
              errorsFormDefault[field as keyof IFormErrors] as IFormErrorObjects
            ).message.replace('champ Nom', 'suffixe')}
            placement="top"
          >
            <span></span>
          </StyledTooltipError>
        )}
      </Box>
      <span style={{ cursor: 'default' }}>{row.parentName}</span>
      <TextField
        sx={{
          padding: '0 16px',
          borderBottom: isError ? '2px solid #d32f2f' : '1px solid #e69636',
        }}
        value={name}
        placeholder="Entrez un suffixe"
        onChange={(e) => handleNameChange(e)}
        id="name"
        InputLabelProps={{
          shrink: true,
        }}
        variant="standard"
        size="small"
        inputRef={ref}
      />
    </Box>
  );
};

export const CustomCultureInputCostCell = (
  params: GridEditInputCellProps,
  openInputCostDialog: boolean,
  setOpenInputCostDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setInputCostValue: React.Dispatch<React.SetStateAction<IInputCostValue>>,
  setParamsInputCost: React.Dispatch<React.SetStateAction<IParamsInputCost>>,
  isEditInputCost: boolean,
  inputCostTabsValuesRef: ICostTabsValuesRef
): React.JSX.Element => {
  const [value, setValue] = useState<number>(
    inputCostTabsValuesRef?.current?.find(
      (row: { id: number; value: number }) => row.id === params.id
    )?.value ?? 0
  );
  const setInputCostValues = () => {
    setInputCostValue((prev: IInputCostValue) => ({
      ...prev,
      inputCostWithoutNitrogen: params.row.inputCostWithoutNitrogen,
      inputCostWithoutNitrogenN5: params.row.inputCostWithoutNitrogenN5,
      necessaryNitrogenQuantity: params.row.necessaryNitrogenQuantity,
      inputCost: +params.value,
      inputCostN5: +params.row.inputCostN5,
      cultureId: +params.row.id,
    }));
  };
  const setInputCostParams = () => {
    setParamsInputCost((prev: IParamsInputCost) => ({
      ...prev,
      inputCost: true,
    }));
  };
  const setInputCostN5Params = () => {
    setParamsInputCost((prev: IParamsInputCost) => ({
      ...prev,
      inputCostN5: true,
    }));
  };
  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
    inputCostTabsValuesRef.current = inputCostTabsValuesRef.current.map(
      (prevRow: { id: number; value: number }) =>
        prevRow.id === params.id ? { ...prevRow, value: newValue } : { ...prevRow }
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: '3px 0 -5px 0' }}>
        <StyledTabs onChange={handleChange} value={value} aria-label="basic tabs example">
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N" {...a11yProps(0)} />
          <StyledTab sx={{ width: '50%', minWidth: 'auto' }} label="N+5" {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel
        value={value}
        sx={{
          textAlign: 'center',
        }}
        onClick={() => {
          if (isEditInputCost) {
            setOpenInputCostDialog(!openInputCostDialog);
            setInputCostValues();
            setInputCostParams();
          }
        }}
        index={0}
      >
        <div>{params.value}</div>
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        sx={{
          textAlign: 'center',
        }}
        onClick={() => {
          if (isEditInputCost) {
            setOpenInputCostDialog(!openInputCostDialog);
            setInputCostValues();
            setInputCostN5Params();
          }
        }}
        index={1}
      >
        <div>{params.row.inputCostN5}</div>
      </CustomTabPanel>
    </Box>
  );
};

export const CustomCultureIFTCell = (
  params: GridEditInputCellProps,
  isEditableIFT: boolean,
  openIFTDialog: boolean,
  setOpenIFTDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setIFTtValue: React.Dispatch<
    React.SetStateAction<{
      tfiHerbicide: number;
      tfiWithoutHerbicide: number;
      cultureId: number;
    }>
  >
) => {
  const setIFTValues = () => {
    setIFTtValue((prev) => ({
      ...prev,
      tfiHerbicide: params.row.tfiHerbicide,
      tfiWithoutHerbicide: params.row.tfiWithoutHerbicide,
      cultureId: params.row.id,
    }));
  };
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        onClick={() => {
          if (isEditableIFT) {
            setOpenIFTDialog(!openIFTDialog);
            setIFTValues();
          }
        }}
      >
        {params.row.tfi}
      </div>
    </Box>
  );
};

type CustomNitrogenSupplyByCoverParams = {
  errorsFormNitrogenCoverValue: IFormErrors;
  setErrorsNitrogenCoverValue: React.Dispatch<React.SetStateAction<IFormErrors | undefined>>;
  nitrogenCoverValue: { nitrogenSupplyByCover: number };
  owner: null | { parent: string; id: number };
  cooperativeId: number;
  cooperativeName: string;
  isEditNitrogenCover: boolean;
  setIsEditNitrogenCover: React.Dispatch<React.SetStateAction<boolean>>;
  getEconomicAttribute: () => void;
  nitrogenCover: number;
};
export const CustomNitrogenSupplyByCover = ({
  errorsFormNitrogenCoverValue,
  setErrorsNitrogenCoverValue,
  nitrogenCoverValue,
  owner,
  cooperativeId,
  cooperativeName,
  isEditNitrogenCover,
  setIsEditNitrogenCover,
  getEconomicAttribute,
  nitrogenCover,
}: CustomNitrogenSupplyByCoverParams) => {
  const [nitrogenCoverValueClone, setNitrogenCoverValueClone] = useState<{
    nitrogenSupplyByCover: number;
  }>(nitrogenCoverValue);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setNitrogenCoverValueClone((prev) => ({
      ...prev,
      nitrogenSupplyByCover: nitrogenCover ?? 0,
    }));
  }, [nitrogenCover]);

  useEffect(() => {
    if (ref.current) {
      const input: HTMLSelectElement | null = ref.current.querySelector('#nitrogenSupplyByCover');
      if (errorsFormNitrogenCoverValue.formError) {
        input?.focus();
      }
    }
  }, [ref, errorsFormNitrogenCoverValue.formError]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setNitrogenCoverValueClone((prev) => ({
      ...prev,
      nitrogenSupplyByCover: checkNoDecimalRegexTwoDigitsDatagridValue(value),
    }));
  };

  const handleNitrogenCoverClick = async () => {
    const toUpdate = getOnlyAttributeUpdated(nitrogenCoverValue, nitrogenCoverValueClone);

    errorsFormNitrogenCoverValue.formError = false;

    setErrorsNitrogenCoverValue({
      ...validateFormData(toUpdate, errorsFormNitrogenCoverValue),
    });

    if (!errorsFormNitrogenCoverValue.formError) {
      if (Object.entries(toUpdate).length > 0) {
        let formData = new FormData();
        if (toUpdate.hasOwnProperty('nitrogenSupplyByCover')) {
          toUpdate.nitrogenSupplyByCover =
            toUpdate.nitrogenSupplyByCover ?? nitrogenCoverValueClone.nitrogenSupplyByCover;

          formData.append(
            'nitrogenSupplyByCover',
            toUpdate.nitrogenSupplyByCover ?? nitrogenCoverValueClone.nitrogenSupplyByCover
          );
        }

        try {
          if (!owner) {
            updateStructure(formData, cooperativeId);
            toast.success(`Cooperative ${cooperativeName} mis à jour`);
            setErrorsNitrogenCoverValue((prev) => {
              if (prev?.nitrogenSupplyByCover && typeof prev.nitrogenSupplyByCover === 'object') {
                return {
                  ...prev,
                  formError: false,
                  nitrogenSupplyByCover: {
                    ...prev.nitrogenSupplyByCover,
                    message: '',
                  },
                };
              }
            });
          }
          getEconomicAttribute();
        } catch (error) {
          const apiError = error as IErrorResponse;
          toast.error(apiError?.response?.data?.message);
        }
      }
    }
  };

  const isOpen = useMemo(
    () => errorsFormNitrogenCoverValue.formError,
    [errorsFormNitrogenCoverValue]
  );
  return (
    <ClickAwayListener
      onClickAway={() => {
        if (isEditNitrogenCover) {
          handleNitrogenCoverClick();
        }
        if (!errorsFormNitrogenCoverValue.formError) {
          setIsEditNitrogenCover(false);
        }
      }}
    >
      <Box>
        <StyledTooltip
          placement="top"
          title="Quantité d’azote apportée par le couvert de légumineuses en unités d’azote"
        >
          <InputLabel id="nitrogenSupplyByCoverLabel" htmlFor="nitrogenSupplyByCover">
            Qté d'Azote d'un couvert
          </InputLabel>
        </StyledTooltip>
        {(
          errorsFormNitrogenCoverValue?.[
            'nitrogenSupplyByCover' as keyof IFormErrors
          ] as IFormErrorObjects
        ).message && (
          <>
            <StyledTooltipErrorNitrogen
              open={isOpen}
              title={
                (
                  errorsFormNitrogenCoverValue?.[
                    'nitrogenSupplyByCover' as keyof IFormErrors
                  ] as IFormErrorObjects
                ).message
              }
              placement="top"
            >
              <span></span>
            </StyledTooltipErrorNitrogen>
          </>
        )}
        <TextField
          id="nitrogenSupplyByCover"
          onFocus={() => setIsEditNitrogenCover(true)}
          disabled={owner ? true : false}
          value={nitrogenCoverValueClone?.nitrogenSupplyByCover}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          className={
            (
              errorsFormNitrogenCoverValue?.[
                'nitrogenSupplyByCover' as keyof IFormErrors
              ] as IFormErrorObjects
            ).message
              ? 'MuiErrorForm'
              : ''
          }
          ref={ref}
        />
      </Box>
    </ClickAwayListener>
  );
};

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#e69636',
  },
});
const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  color: '#277134',
  '&:hover': {
    color: '#e69636',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#e69636',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
})) as typeof Tab;

type TabPanelParams = {
  children?: React.ReactNode;
  index: number;
  value: number;
  onClick?: () => void;
  className?: string;
};

const CustomTabPanel = styled((props: TabPanelParams) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: '15px' }}>{children}</Box>}
    </div>
  );
})<TabPanelParams>({});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const copyCurrentValueToFuture = (
  futureValue: number | string,
  currentValue: number
): number | string => {
  if (!futureValue && futureValue != '0') {
    return currentValue;
  }
  return futureValue;
};

export const customCoProductsCell = (
  value: string[],
  setCoProductYieldFocus: React.Dispatch<React.SetStateAction<{ value: string; index: string }>>
): React.JSX.Element => {
  const setYieldCoProductValues = (index: number) => (e: React.MouseEvent<HTMLInputElement>) => {
    if ((e.target as HTMLInputElement)?.innerText) {
      setCoProductYieldFocus({
        value: (e.target as HTMLInputElement)?.innerText,
        index: `coProductYield-${index}`,
      });
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
      }}
    >
      {value?.length
        ? value?.map((val: string, index: number) => (
            <div
              style={{ userSelect: 'none' }}
              key={uuidv4()}
              onDoubleClick={setYieldCoProductValues(index)}
              id={`coProductYield-${index}`}
            >
              {val}
            </div>
          ))
        : '-'}
    </Box>
  );
};

type CoProductYieldEditCellParams = {
  id: number;
  value: number[];
  field: string;
  hasFocus: boolean;
  row: CoProductYieldEditCellRow;
  errorsFormCoProduct: IFormErrors;
  errorCoProductsMessages: string[];
  isOpen: boolean;
  coProductYieldFocus: { index: number; value: number };
};

type CoProductYieldEditCellRow = {
  coProducts: ICoproduct[];
  coProduct: string[];
};

export const CoProductYieldEditCell = ({
  id,
  value,
  field,
  hasFocus,
  row: { coProducts, coProduct },
  errorsFormCoProduct,
  errorCoProductsMessages,
  isOpen,
  coProductYieldFocus,
}: CoProductYieldEditCellParams): React.JSX.Element => {
  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiContext();
  const ref = useRef<HTMLDivElement>(null);
  const [coProductsYield, setCoProductsYield] = useState<number[] | undefined>(
    coProducts?.map((coProduct: ICoproduct) => coProduct.yield)
  );
  const coProductsDisplayName = coProduct;

  useEffect(() => {
    if (!value) {
      setCoProductsYield(undefined);
    } else {
      setCoProductsYield(value);
    }
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const {
      target: { value },
    } = e;
    setCoProductsYield((prevCoProducts: number[] | undefined): number[] | undefined => {
      const updatedCoProducts = prevCoProducts?.map(
        (prevCoProduct: number, prevCoProductIndex: number) =>
          index === prevCoProductIndex
            ? checkDecimalRegexTwoDigitsDatagridValue(value)
            : prevCoProduct
      );
      apiRef.current.setEditCellValue({ id, field, value: updatedCoProducts });

      return updatedCoProducts;
    });
  };
  useEffect(() => {
    if (hasFocus && coProductYieldFocus) {
      const input: HTMLElement | null | undefined = ref.current?.querySelector(
        `[value="${coProductYieldFocus.value}"]`
      );
      const getId: number | null = input ? +input.id : null;
      if (getId === coProductYieldFocus.index) {
        input?.focus();
      }
    }
  }, [hasFocus, value, coProductYieldFocus]);

  return (
    <div ref={ref}>
      <ThemeProvider theme={customYieldEditTheme}>
        {coProductsYield?.length &&
          coProductsYield.map((coProductYield: number, index: number) => (
            <Fragment key={index}>
              <Box>
                {errorsFormCoProduct.formError && errorCoProductsMessages?.[index]?.length > 0 && (
                  <StyledTooltipErrorYieldCoProduct
                    open={isOpen}
                    title={errorCoProductsMessages[index] as ReactNode}
                    placement="top-start"
                  >
                    <span></span>
                  </StyledTooltipErrorYieldCoProduct>
                )}
              </Box>
              <TextField
                value={coProductYield}
                onChange={(e) => handleChange(e, index)}
                id={`coProductYield-${index}`}
                label={`Rendement du co-produit ${coProductsDisplayName[index]}`}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                size="small"
                className={
                  errorsFormCoProduct.formError && errorCoProductsMessages?.[index]?.length
                    ? 'MuiErrorForm'
                    : ''
                }
              />
            </Fragment>
          ))}
      </ThemeProvider>
    </div>
  );
};

type CoProductEditCellParams = {
  id: number;
  value: string[];
  field: string;
  hasFocus: boolean;
  row: CoProductEditCellRow;
  coProductsList: ICoproduct[];
  setIsEditCoProduct: React.Dispatch<React.SetStateAction<boolean>>;
};

type CoProductEditCellRow = {
  yieldProduct: number[];
  coProducts: ICoproduct[];
};
export const CoProductEditCell = ({
  id,
  value,
  field,
  hasFocus,
  row: { yieldProduct, coProducts },
  coProductsList,
  setIsEditCoProduct,
}: CoProductEditCellParams): React.JSX.Element => {
  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiContext();
  // To further investigate : find the right types for 'ref' & 'modalRef'.
  // Maybe converting the Modal component into a Dialog could help.
  const ref = useRef<HTMLSelectElement | null>();
  const modalRef = useRef<any>();
  const [coProductsRow, setCoProductsRow] = useState<string[]>(
    coProducts?.map((coProduct: ICoproduct) => coProduct.displayName)
  );
  const [open, setOpen] = useState<boolean>(false);
  const [newYieldProduct, setNewYieldProduct] = useState<number>(0);

  useEffect(() => {
    if (coProductsRow?.length && !coProductsRow.includes('Aucun')) {
      checkYield();
    }
  }, [coProductsRow]);

  useEffect(() => {
    if (newYieldProduct) {
      apiRef.current.setEditCellValue({
        id,
        field: 'yieldProduct',
        value: yieldProduct ? [...yieldProduct, newYieldProduct] : [newYieldProduct],
      });
    }
  }, [newYieldProduct]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (newYieldProduct === 0) {
      apiRef.current.setEditCellValue({
        id,
        field: 'yieldProduct',
        value: yieldProduct ? [...yieldProduct, newYieldProduct] : [newYieldProduct],
      });
    }
    setNewYieldProduct(0);
    setOpen(false);
  };
  const handleChange = (e: SelectChangeEvent<string>, index: number) => {
    const {
      target: { value },
    } = e;
    setCoProductsRow((prevCoProducts: string[]) => {
      const updatedCoProducts = prevCoProducts?.map(
        (prevCoProduct: string, prevCoProductIndex: number) =>
          index === prevCoProductIndex ? value : prevCoProduct
      );
      apiRef.current.setEditCellValue({ id, field, value: updatedCoProducts });

      return updatedCoProducts;
    });
  };

  const handleDelete = (index: number) => {
    setCoProductsRow((prevCoProducts: string[]) => {
      const filterCoProduct = prevCoProducts?.filter(
        (_: string, prevCoProductIndex: number) => index !== prevCoProductIndex
      );
      const filterYieldProduct = yieldProduct?.filter(
        (_: number, yieldIndex: number) => index !== yieldIndex
      );
      apiRef?.current?.setEditCellValue({ id, field, value: filterCoProduct });
      apiRef?.current?.setEditCellValue({ id, field: 'yieldProduct', value: filterYieldProduct });
      return filterCoProduct;
    });
  };
  const handleAdd = () => {
    coProductsRow?.length
      ? setCoProductsRow((prevCoProducts: string[]) => [...prevCoProducts, 'Aucun'])
      : setCoProductsRow(['Aucun']);
    coProductsRow?.length;
    setIsEditCoProduct(true);
  };
  const checkYield = () => {
    if (yieldProduct?.length !== coProductsRow?.length) {
      handleOpen();
    }
  };
  const handleYieldProduct = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    setNewYieldProduct(checkDecimalRegexTwoDigitsDatagridValue(newValue));
  };

  useEffect(() => {
    if (hasFocus) {
      const input = ref?.current?.querySelector('#coProductSelect');
      if (open && input) {
        const inputModal: HTMLElement | null | undefined =
          modalRef?.current?.querySelector('#coProductYield');
        inputModal?.focus();
      }
    }
  }, [hasFocus, value, ref, open, modalRef]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <ThemeProvider theme={customCoProductTheme}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {coProductsList && coProductsRow?.length ? (
            coProductsRow.map((coProduct: string, index: number) => (
              <FormControl
                fullWidth
                variant="filled"
                size="small"
                key={index}
                sx={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <InputLabel
                  sx={{
                    '& .MuiInputLabel-root:focus': {
                      color: 'unset !important',
                    },
                  }}
                  id="coProductLabel"
                >{`Type de co-produit ${index + 1}`}</InputLabel>
                <Select
                  labelId="coProductLabel"
                  id="coProductSelect"
                  value={coProduct}
                  label="Age"
                  onChange={(e) => handleChange(e, index)}
                  ref={ref}
                >
                  {coProductsList
                    .filter((coProductListItem: ICoproduct) => {
                      if (index !== 0) {
                        return !coProductsRow
                          .slice(0, index)
                          .includes(coProductListItem.displayName);
                      }
                      return coProductListItem;
                    })
                    .map((coProducts: ICoproduct) => (
                      <MenuItem key={coProducts.id ?? 0} value={coProducts.displayName}>
                        {coProducts.displayName}
                      </MenuItem>
                    ))}
                </Select>
                {index + 1 === coProductsRow.length && coProductsRow[0] !== 'Aucun]' && (
                  <AddCircleOutlineIcon sx={{ color: '#e69636' }} onClick={handleAdd} />
                )}
                <DeleteIcon onClick={() => handleDelete(index)} sx={{ color: '#e69636' }} />
              </FormControl>
            ))
          ) : coProductsList ? (
            <ThemeProvider theme={customButtonTheme}>
              <Button onClick={handleAdd}>Ajouter</Button>
            </ThemeProvider>
          ) : (
            <div style={{ color: 'red', fontSize: '10px' }}>Aucun co-produit enregistré</div>
          )}
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          keepMounted={true}
          ref={modalRef}
        >
          <Box sx={style}>
            <p>Veuillez indiquer le rendement du co-produit {coProductsRow?.slice(-1)}</p>
            <div>
              <TextField
                value={newYieldProduct}
                onChange={handleYieldProduct}
                id="coProductYield"
                label={`Rendement du co-produit`}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                size="small"
              />
              <ThemeProvider theme={customButtonTheme}>
                <Button onClick={handleClose}>Valider</Button>
              </ThemeProvider>
            </div>
          </Box>
        </Modal>
      </ThemeProvider>
    </div>
  );
};

type CustomEditCapCategoryCellParams = {
  id: number;
  value: string;
  field: string;
  errorsFormCapCategory: IFormErrors;
  CAPCategories: ICapCategory[];
};

export const CustomEditCapCategoryCell = ({
  id,
  value,
  field,
  errorsFormCapCategory,
  CAPCategories,
}: CustomEditCapCategoryCellParams): React.JSX.Element => {
  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiContext();
  const foundCapCategory = CAPCategories?.find(
    (category: ICapCategory) => category?.displayName === value
  )?.id;

  const handleChange = (e: SelectChangeEvent<string | number>) => {
    const {
      target: { value },
    } = e;

    apiRef.current.setEditCellValue({
      id,
      field,
      value: CAPCategories.find((category: ICapCategory) => category?.id === value)?.displayName,
    });
  };
  const errorMessage =
    errorsFormCapCategory.formError &&
    errorsFormCapCategory.hasOwnProperty(field) &&
    (errorsFormCapCategory[field as keyof IFormErrors] as IFormErrorObjects).message.length > 0;

  return (
    <>
      <StyledTooltipErrorInputCostModal
        open={errorMessage}
        title={(errorsFormCapCategory[field as keyof IFormErrors] as IFormErrorObjects)?.message}
        placement="top"
      >
        <span></span>
      </StyledTooltipErrorInputCostModal>
      <Select
        id="capCategory"
        value={foundCapCategory ?? value}
        onChange={(e) => handleChange(e)}
        error={
          (errorsFormCapCategory['capCategory' as keyof IFormErrors] as IFormErrorObjects).message
            ? true
            : false
        }
        className={errorMessage ? 'MuiErrorForm select-capCategoryCell' : 'select-capCategoryCell'}
      >
        {CAPCategories?.map((capCategory: ICapCategory) => (
          <MenuItem key={capCategory.id} value={capCategory.id}>
            {capCategory.displayName}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

type CustomEditIsArableCellParams = {
  id: number;
  value: boolean;
  field: string;
  errorsFormCapCategory: IFormErrors;
};

export const CustomEditIsArableCell = ({
  id,
  value,
  field,
  errorsFormCapCategory,
}: CustomEditIsArableCellParams): React.JSX.Element => {
  const apiRef: React.MutableRefObject<GridApiPro> = useGridApiContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked },
    } = e;

    apiRef.current.setEditCellValue({
      id,
      field,
      value: checked,
    });
  };
  const errorMessage =
    errorsFormCapCategory.formError &&
    errorsFormCapCategory.hasOwnProperty(field) &&
    (errorsFormCapCategory[field as keyof IFormErrors] as IFormErrorObjects).message.length > 0;

  return (
    <>
      <StyledTooltipErrorYieldCoProduct
        open={errorMessage}
        title={(errorsFormCapCategory[field as keyof IFormErrors] as IFormErrorObjects).message}
        placement="top"
      >
        <span></span>
      </StyledTooltipErrorYieldCoProduct>
      <Checkbox
        id="isArable"
        className={errorMessage ? 'MuiErrorForm-checkbox' : ''}
        checked={value}
        onChange={(e) => handleChange(e)}
      />
    </>
  );
};

export const customCultureDataGridTheme = createTheme(
  {
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            border: '1px solid #277134 !important',
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              border: 'none',
              outline: 'none',
            },
            '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
              border: 'none',
              outline: 'none',
              '& :focus-visible': {
                border: 'none',
                outline: 'none',
              },
            },
            '& .MuiDataGrid-virtualScroller': {
              maxHeight: '68vh',
              overflowY: 'auto !important',
              [`@media (max-width: ${mediaQuery.breakpoints.values.lg}px)`]: {
                maxHeight: '43vh',
                overflowX: 'auto !important',
              },
            },
            '& .MuiDataGrid-columnHeaderTitleContainer .MuiCheckbox-root': {
              color: '#277134 !important',
            },
            '& .MuiDataGrid-cell--editing .MuiDataGrid-editInputCell .MuiInputBase-input': {
              textAlign: 'center !important',
            },
            '& .MuiBox-root .MuiFormControl-root .MuiInputBase-root:before, .MuiBox-root .MuiFormControl-root .MuiInputBase-root:after':
              {
                borderBottom: 'unset !important',
              },
            '& .MuiBox-root .MuiFormControl-root .MuiInput-input': {
              textAlign: 'center !important',
              padding: '0 16px',
              fontSize: '14px',
              height: 'unset',
            },
            '.MuiDataGrid-pinnedColumnHeaders--right': {
              paddingRight: '15px !important',
            },
            '& .MuiDataGrid-row--editing': {
              zIndex: 5,
              position: 'relative',
              boxShadow: '0px -1px 0px 1px #277134, 0px 1px 0px 1px #277134',
            },
            '& .MuiDataGrid-cell--Not-editable': {
              cursor: 'not-allowed',
              backgroundColor: '#d2d2d2 !important',
            },
            '& .MuiDataGrid-ParcelChild--row': {
              backgroundColor: '#d6d6d6 !important',
            },
            '& .MuiDataGrid--Not-editable': {
              cursor: 'default',
            },
            '& .MuiDataGrid--AllRows-editable': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-treeDataGroupingCellToggle .MuiIconButton-root': {
              fontWeight: 'bold',
              fontSize: '25px',
            },
            '& .select-capCategoryCell': {
              position: 'relative !important',
              '& .MuiSelect-select': {
                whiteSpace: 'pre-wrap !important',
              },
            },
            '& .MuiErrorForm .MuiSelect-select::before': {
              content: '""',
              position: 'absolute',
              top: '85%',
              right: 0,
              width: '100%',
              borderBottom: '2px solid #d32f2f !important',
              borderRadius: 'unset',
            },
            '& .MuiErrorForm-checkbox .MuiSvgIcon-root': {
              border: '2px solid #d32f2f !important',
            },
          },
          main: {
            fontFamily: 'Rubik-Medium',
            fontSize: '16px',
            color: '#707070',
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#e69636',
              color: 'white',
            },
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            border: '1px solid #e69636',
            fontFamily: 'Rubik',
            fontSize: '14px',
            color: '#707070',
            ':hover': {
              color: '#e69636',
              fontFamily: 'Rubik-Medium',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: '#e69636 !important',
            '&.Mui-checked': {
              color: '#277134 !important',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'unset !important',
            borderWidth: '0px !important',
          },
        },
      },
    },
  },
  frFR
);

export const customCoProductTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.MuiFormControl-root': {
            borderBottom: '1px solid #e69636',
            margin: '1px',
          },
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#e69636 !important',
            top: '-2px !important',
          },
          '& .MuiOutlinedInput-root.Mui-focused legend': {
            display: 'none !important',
          },
          '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0) !important',
            maxWidth: 120,
          },
          '& .MuiSvgIcon-root': {
            paddingTop: '10px',
            cursor: 'pointer',
          },
          '& .MuiInputBase-root:before': {
            borderBottom: 'unset !important',
          },
          '& .MuiInputBase-root:after ': {
            borderColor: '#e69636 !important',
          },
          '& .MuiInputBase-root .MuiFilledInput-input:focus ': {
            backgroundColor: 'unset !important',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiBox-root': {
            border: '3px solid #e69636 !important',
            borderRadius: '20px',
          },
          '& .MuiBox-root .MuiFormControl-root': {
            border: 'none !important',
            marginRight: '40px',
            width: '40%',
          },
          '& .MuiBox-root .MuiFormControl-root .MuiInput-root input': {
            borderBottom: '1px solid #e69636 !important',
          },
          '& .MuiBox-root .MuiFormControl-root .MuiInput-root:after': {
            border: '1px solid #e69636 !important',
          },
          '& .MuiBox-root div': {
            display: 'flex',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
          top: '-6px',
          left: '-6px',
        },
      },
    },
  },
});

export const customYieldEditTheme = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root:before, .MuiBox-root .MuiFormControl-root .MuiInputBase-root:after':
            {
              borderBottom: 'unset !important',
            },
          '& .MuiInputBase-root input': {
            textAlign: 'center !important',
            padding: '5px',
            borderBottom: '1px solid #e69636 !important',
          },
          '& .MuiInputBase-root:after ': {
            borderColor: '#e69636 !important',
          },
          '&.MuiErrorForm .MuiInputBase-root input': {
            borderBottom: '2px solid #d32f2f !important',
          },
          '&.MuiFormControl-root.MuiErrorForm .MuiInputBase-root:after ': {
            borderColor: '#d32f2f !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
          userSelect: 'none',
        },
      },
    },
  },
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -17,
    top: -23,
    position: 'absolute',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: '#e69636',
  },
}));

export const handleRowUpdateValidation = (
  row: ICulture,
  errorsFormDefault: IFormErrors,
  errorsFormCoProduct: IFormErrors,
  errorsFormEconomicValue: IFormErrors,
  errorsFormCapCategory: IFormErrors
): Promise<ICulture> => {
  return new Promise((resolve, reject) => {
    if (
      errorsFormDefault.formError === false &&
      errorsFormCoProduct.formError === false &&
      errorsFormEconomicValue.formError === false &&
      errorsFormCapCategory.formError === false
    ) {
      resolve(row);
    } else {
      reject();
    }
  });
};
