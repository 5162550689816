// REACT
import { useEffect, useState } from 'react';
// YARN
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

// SERVICES
import { CustomSortableHeader } from 'utils/Datagrid/CustomGenericDataGridUtils';
import useDataGridCommonListActions from 'utils/Datagrid/useDataGridCommonListActions';
import { CustomHeaderWithoutSort } from 'utils/Datagrid/CustomCultureDataGridUtils';
import {
  CustomCommonListDataGridThemeWithCursorPointer,
  CustomLogoCell,
} from 'utils/Datagrid/CustomCommonListDataGridUtils';
import CustomCommonListDataGrid from './../../../utils/Datagrid/CustomCommonListDataGrid';
import { removeStructure } from 'services/API/Administration';
// INTERFACES
import IStructureList from 'components/generics/Interface/Api/Response/Structures/IStructureList';
import { IUser } from 'components/generics/Interface/Api/Response/User/IUser';
// THEMES
import './../../generics/commonListDataGrid.scss';
import './StructureDataGrid.scss';
import { customDialogTheme } from 'assets/styles/themes/Dialog/generic_dialog_mui';

type StructureDataGridParams = {
  structures: IStructureList[];
  refreshData: () => void;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setEditData: React.Dispatch<React.SetStateAction<GridValidRowModel | null | undefined>>;
};

export const StructureDataGrid = ({
  structures,
  refreshData,
  setModal,
  setIsEdit,
  setEditData,
}: StructureDataGridParams): React.JSX.Element => {
  const [rows, setRows] = useState<GridRowsProp | []>([]);
  const [dataGridLoading, setDataGridLoading] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [actionId, setActionId] = useState<GridRowId | null>(null);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  const navigate: NavigateFunction = useNavigate();
  const thisStructure: GridValidRowModel | undefined = rows.find((row) => row.id === actionId);

  type ProcessedRowType = {
    id: number;
    name: string;
    status: string;
    logo: string | null;
    users: IUser[] | [];
  };

  const processedRows: ProcessedRowType[] = structures?.map((structure) => {
    return {
      id: structure.id,
      name: structure.name,
      status: structure.is_active ? 'Actif' : 'Inactif',
      logo: structure.logo,
      users: structure.users,
    };
  });

  useEffect(() => {
    setDataGridLoading(true);
    processedRows.length && setRows(processedRows);
    setDataGridLoading(false);
  }, [structures]);

  const { getActions } = useDataGridCommonListActions(
    processedRows,
    setModal,
    setIsEdit,
    setEditData,
    setOpenDeleteDialog,
    setActionId
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Structure',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'name'} name={'Structure'} />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'status'} name={'Status'} />
      ),
    },
    {
      field: 'logo',
      headerName: 'Logo',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        CustomLogoCell(params.row.logo, params.row.name),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'actions',
      getActions: (params: GridRowParams) => getActions(params.id),
      renderHeader: () => <CustomHeaderWithoutSort name={'Actions'} />,
    },
  ];

  const onRowClick = (params: GridRowParams): void => {
    localStorage.setItem('cooperative', params.id.toString());
    localStorage.removeItem('exploitation');
    localStorage.removeItem('sector');
    window.dispatchEvent(new Event('setActiveMainNavBar'));
    navigate('/cooperative/sectors');
  };

  const handleDeleteClick = async (): Promise<void> => {
    setOpenDeleteDialog(false);
    try {
      await removeStructure(thisStructure?.id);
      toast.success(`Coopérative ${thisStructure?.name} supprimée`);
      refreshData();
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  const renderDeleteDialog = (): React.JSX.Element => {
    return (
      <ThemeProvider theme={customDialogTheme}>
        <Dialog maxWidth="md" open={openDeleteDialog}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent dividers>
            <h3>Supprimer une structure</h3>
            <p>Vous êtes sur le point de supprimer la structure</p>
            <p>
              <strong>{thisStructure?.name}</strong>. Souhaitez-vous continuer?
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClick}>
              Supprimer <DoneIcon />
            </Button>
            <Button onClick={() => setOpenDeleteDialog(false)}>
              Non <ClearIcon />
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  };

  return (
    <>
      <div className="common-list-data-grid structure-data-grid">
        <CustomCommonListDataGrid
          rows={rows}
          columns={columns}
          isLoading={dataGridLoading}
          sortModel={sortModel}
          onRowClick={onRowClick}
          theme={CustomCommonListDataGridThemeWithCursorPointer}
        />
      </div>
      {renderDeleteDialog()}
    </>
  );
};
