import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { getAllGroundTypeEffects } from 'services/API/Cooperative';
import { ROLE_ADMIN } from 'utils/const';
import { displayErrorMessage } from 'utils/tools_functions';
import { sendEvent } from 'utils/Event';
import GroundTypeEffectForm from 'components/pages/Cooperative/form/GroundTypeEffect';
import GroundTypeEffectTableRow from 'components/pages/Cooperative/table/GroundTypeEffectRow';

import cultureProp from 'components/propTypes/cultureProp';
import groundTypeEffectProp from 'components/propTypes/groundTypeEffect';

import Table from 'components/generics/Table/Table';
import Select from 'components/generics/Select';
import { FormModal } from 'components/generics/Modals/FormModal';

const CooperativeGroundTypeEffects = ({ groundTypes, cultures }) => {
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [cultureSelected, setCultureSelected] = useState('');
  const [effects, setEffects] = useState([]);
  const [groundTypesEffects, setGroundTypesEffects] = useState([]);

  useEffect(() => {
    refreshData();
    return () => {
      sendEvent('cancelHttpRequest')
    };
  }, []);

  useEffect(() => {
    effects && setGroundTypesEffects(effects.filter((e) => e?.culture?.id === cultureSelected));
  }, [cultureSelected, effects]);

  useEffect(() => {
    cultures && setCultureSelected(cultures[0]?.id);
  }, [cultures]);

  const refreshData = () => {
    getAllGroundTypeEffects()
      .then((v) => {
        setEffects(v.data.data);
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_EFFECT_NOT_FOUND'))
        }
      });
  };

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    refreshData();
  };

  const actions = [
    {
      label: 'Modifier',
      func: (e) => {
        setEditData(e);
        setModal(true);
      },
      minimalRole: ROLE_ADMIN,
    },
  ];

  return (
    <div className="section">
      <div className="main_container">
        <h1 className="title_section">Matrice type de sol</h1>
        <div className="flex-row">
          {cultures && (
            <Select
              className="reduce"
              name="Cultures"
              onChange={(e) => setCultureSelected(+e)}
              options={cultures}
              value={cultureSelected}
              itemLabel="name"
              itemValue="id"
            />
          )}
        </div>
        <Table
          header={['Type de sol', 'Effet (%)', '']}
          data={groundTypesEffects}
          RowElement={GroundTypeEffectTableRow}
          actions={actions}
        />
      </div>
      {modal && (
        <FormModal
          onClick={() => {
            setEditData(null);
            setModal(false);
          }}
          customTemplate={
            <GroundTypeEffectForm
              onSubmitSuccess={formSuccess}
              culture={cultureSelected}
              groundTypes={groundTypes.filter(
                (all) => groundTypesEffects.filter((exist) => all.id === exist.id).length !== 1
              )}
              edit={editData}
            />}>
        </FormModal>
      )}
    </div>
  );
};

CooperativeGroundTypeEffects.propTypes = {
  groundTypes: PropTypes.arrayOf(groundTypeEffectProp),
  cultures: PropTypes.arrayOf(cultureProp),
};

CooperativeGroundTypeEffects.defaultProps = {
  groundTypes: [],
  cultures: [],
};

export default CooperativeGroundTypeEffects;
