// REACT
import { useCallback, useEffect, useState } from 'react';
// YARN
import {
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRowModel,
  GridRowsProp,
  GridSortModel,
  GridValueOptionsParams,
} from '@mui/x-data-grid-pro';
// SERVICES
import CustomDataGrid from 'utils/Datagrid/CustomCultureDataGrid';
import {
  inputNumberRequired,
  inputPositiveFloatValidator,
  inputRequired,
} from 'components/generics/Validators/validator';
import { CustomHeaderWithoutSort, EditInputCell } from 'utils/Datagrid/CustomCultureDataGridUtils';
import {
  CustomSortableHeader,
  childrenTotalSurface,
  getFamily,
  processColumns,
  totalSurfaceComparator,
  resetErrorsForm,
} from 'utils/Datagrid/CustomGenericDataGridUtils';
import useDataGridParcelsActions from 'utils/Datagrid/useDataGridParcelsActions';
import {
  checkDecimalRegexTwoDigitsDatagridValue,
  checkTypeStringRegexDatagrid,
  getOnlyAttributeUpdated,
} from 'utils/tools_functions';
import { validateDatagridData } from '../../generics/Validators/datagrid.validate';
import { isSameAsOriginal } from 'utils/Datagrid/CustomForecastDataGridUtils';
import {
  CustomParcelCell,
  CustomParcelSurfaceCell,
  customIsIrrigableCell,
} from 'utils/Datagrid/CustomParcelstDataGridUtils';
// INTERFACES
import { IParcelsList } from 'components/generics/Interface/Api/Response/Exploitation/Parcels/IParcelsList';
import { IGroundType } from 'components/generics/Interface/Api/Response/GroundType/IGroundType';
import { ICulture } from 'components/generics/Interface/ICulture';
import { IMetadata } from 'components/generics/Interface/Api/Response/Metadata/IMetadata';
import { IFormErrors } from 'components/generics/Interface/Commons/IErrorForm';
import { IStackedParcelsRequestsRef } from 'components/generics/Interface/Exploitation/Parcels/IStackedParcelsRequestsRef';
// THEMES
import './dataGridParcels.style.scss';

type DataGridParcelsParams = {
  cultures: ICulture[];
  groundTypes: IGroundType[];
  id: number;
  currentYear?: number;
  parcels?: IParcelsList[];
  refreshData: Function;
  dataGridLoading: boolean;
  setEditData: Function;
  setModalGroup: Function;
  columnsMetadata: IMetadata;
  stackedParcelsRequestsRef: React.MutableRefObject<IStackedParcelsRequestsRef[]>;
  setIsAllowedSubmit: Function;
};
const DataGridParcels = ({
  id,
  groundTypes,
  cultures,
  currentYear,
  parcels,
  refreshData,
  dataGridLoading,
  setEditData,
  setModalGroup,
  columnsMetadata,
  stackedParcelsRequestsRef,
  setIsAllowedSubmit,
}: DataGridParcelsParams) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);
  const [errorsFormDefault, setErrorsFormDefault] = useState<IFormErrors>({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired],
    },
    surface: {
      message: '',
      validator: [inputNumberRequired, inputPositiveFloatValidator],
    },
  });
  const [columnsVisible, setColumnsVisible] = useState<GridColumnVisibilityModel>({});
  const [rows, setRows] = useState<GridRowsProp | []>([]);

  const isOpen = errorsFormDefault.formError;

  type ProcessedRowType = {
    id: number;
    name: string;
    surface: number;
    totalSurface: number | undefined;
    exploitationId: number;
    isIrrigable: boolean;
    groundType: string;
    cultureN1: string;
    cultureN: string;
    parentId: undefined | number[];
    isParent: boolean;
  };

  function isIGroundType(object: any): object is IGroundType {
    return 'name' in object;
  }

  const processedRows: (ProcessedRowType | undefined)[] | undefined = parcels?.map((parcel) => {
    if (isIGroundType(parcel.groundType)) {
      return {
        id: parcel?.id,
        name: parcel.name,
        surface: parcel?.surface,
        totalSurface: parcel?.isParent
          ? childrenTotalSurface(parcel?.id, parcels) + parcel?.surface
          : parcel?.surface,
        exploitationId: id,
        isIrrigable: parcel?.isIrrigable,
        groundType: parcel?.groundType?.name,
        cultureN1: parcel?.cultureN1?.name ?? 'Pas de culture associée',
        cultureN: parcel?.cultureN?.name ?? 'Pas de culture associée',
        parentId: parcel?.parent ? getFamily(parcel, parcels) : [parcel.id],
        isParent: parcel?.isParent ?? false,
      };
    }
  });

  useEffect(() => {
    if (processedRows) {
      const checkedProcessedRows = processedRows.filter(
        (row) => row !== undefined
      ) as ProcessedRowType[];
      setRows(checkedProcessedRows);
    }
  }, [parcels]);

  const {
    rowModesModel,
    setRowModesModel,
    getActions,
    renderDeleteDialog,
    renderDeletegroupingDialog,
  } = useDataGridParcelsActions(rows, refreshData, errorsFormDefault, setEditData, setModalGroup);

  const columnsDefinition: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Parcelle',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: true,
      valueParser: (value: string) => {
        return checkTypeStringRegexDatagrid(value);
      },

      cellClassName: (params: GridCellParams<any, number>) =>
        params.cellMode === 'view' && params.row.parentId.length > 1 ? 'first-children' : '',
      renderCell: (params: GridRenderCellParams) =>
        CustomParcelCell(params, stackedParcelsRequestsRef),
      renderEditCell: (props: GridRenderCellParams) =>
        EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader setSortModel={setSortModel} fieldName={'name'} name={'Parcelle'} />
      ),
    },
    {
      field: 'surface',
      headerName: 'Surface (ha)',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 0.8,
      sortable: false,
      editable: true,
      valueParser: (value: string) => {
        return checkDecimalRegexTwoDigitsDatagridValue(value);
      },
      valueGetter: (params: GridRenderCellParams) => ({
        totalSurface: params.row.totalSurface,
        surface: params.row.surface,
      }),

      sortComparator: totalSurfaceComparator,
      renderCell: (params: GridRenderCellParams) =>
        CustomParcelSurfaceCell(params, stackedParcelsRequestsRef),
      cellClassName: (params: GridCellParams<any, number>) =>
        params.row.parentId.length > 1 ? 'parcel_children' : '',
      renderEditCell: (props: GridRenderCellParams) =>
        EditInputCell(props, errorsFormDefault, isOpen),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'surface'}
          name={'Surface (ha)'}
        />
      ),
    },
    {
      field: 'isIrrigable',
      headerName: 'Irrigabilité',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      type: 'boolean',
      editable: true,
      flex: 0.6,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        customIsIrrigableCell(params, stackedParcelsRequestsRef),
      cellClassName: (params: GridCellParams<any, number>) =>
        params.row.parentId.length > 1 ? 'parcel_children' : '',
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'isIrrigable'}
          name={'Irrigabilité'}
        />
      ),
    },
    {
      field: 'groundType',
      headerName: 'Type de sols',
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: true,
      type: 'singleSelect',
      cellClassName: (params: GridCellParams<any, number>) =>
        params.row.parentId.length > 1 ? 'parcel_children' : '',

      valueOptions: () => {
        if (groundTypes) {
          return [...groundTypes?.map((groundType) => groundType.name)];
        }
        return [];
      },
      renderCell: (params: GridRenderCellParams) =>
        CustomParcelCell(params, stackedParcelsRequestsRef),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'groundType'}
          name={'Type de sols'}
        />
      ),
    },
    {
      field: 'cultureN1',
      headerName: currentYear ? `${currentYear - 2}-${currentYear - 1}` : undefined,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: true,
      type: 'singleSelect',
      cellClassName: (params: GridCellParams<any, number>) =>
        params.row.parentId.length > 1 ? 'parcel_children' : '',
      valueOptions: (params: GridValueOptionsParams<any>) => {
        if (cultures) {
          if (params.row.cultureN1 === 'Pas de culture associée') {
            return [...cultures?.map((culture) => culture.name), 'Pas de culture associée'];
          }
          return [...cultures?.map((culture) => culture.name)];
        }
        return [];
      },
      renderCell: (params: GridRenderCellParams) =>
        CustomParcelCell(params, stackedParcelsRequestsRef),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'cultureN1'}
          name={currentYear ? `${currentYear - 2}-${currentYear - 1}` : 'Culture N-1'}
        />
      ),
    },
    {
      field: 'cultureN',
      headerName: currentYear ? `${currentYear - 1}-${currentYear}` : undefined,
      headerClassName: 'forecast-header',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: false,
      editable: true,
      type: 'singleSelect',
      cellClassName: (params: GridCellParams<any, number>) =>
        params.row.parentId.length > 1 ? 'parcel_children' : '',
      valueOptions: (params: GridValueOptionsParams<any>) => {
        if (cultures) {
          if (params.row.cultureN === 'Pas de culture associée') {
            return [...cultures?.map((culture) => culture.name), 'Pas de culture associée'];
          }
          return [...cultures?.map((culture) => culture.name)];
        }
        return [];
      },
      renderCell: (params: GridRenderCellParams) =>
        CustomParcelCell(params, stackedParcelsRequestsRef),
      renderHeader: () => (
        <CustomSortableHeader
          setSortModel={setSortModel}
          fieldName={'cultureN'}
          name={currentYear ? `${currentYear - 1}-${currentYear}` : 'Culture N'}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 160,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'actions',
      getActions: getActions,
      renderHeader: () => <CustomHeaderWithoutSort name={'Actions'} />,
    },
  ];

  const processedColumns = processColumns(columnsDefinition, columnsMetadata);

  const handleRowUpdateValidation = (row: GridRowModel, errorsFormDefault: IFormErrors) => {
    return new Promise((resolve, reject) => {
      if (errorsFormDefault.formError === false) {
        resolve(row);
      } else {
        reject();
      }
    });
  };

  const processRowUpdate = useCallback(
    async (updatedRow: GridRowModel, previousRow: GridRowModel) => {
      errorsFormDefault.formError = false;
      if (typeof updatedRow.surface === 'object') {
        updatedRow.surface = updatedRow.surface.surface;
      }
      const toUpdate: IStackedParcelsRequestsRef = getOnlyAttributeUpdated(previousRow, updatedRow);
      setErrorsFormDefault({
        ...validateDatagridData(toUpdate, errorsFormDefault, processedColumns, updatedRow.id),
      });

      if (errorsFormDefault.formError && Object.keys(rowModesModel).length > 1) {
        resetErrorsForm(errorsFormDefault);
        return previousRow;
      }

      if (Object.entries(toUpdate).length > 0) {
        await handleRowUpdateValidation(updatedRow, errorsFormDefault);

        let existInStackedRequest = false;
        stackedParcelsRequestsRef.current.forEach((request) => {
          if (request?.parcelId === updatedRow.id) {
            existInStackedRequest = true;
          }
        });

        if (!existInStackedRequest) {
          stackedParcelsRequestsRef.current.push({ ...toUpdate, parcelId: updatedRow.id });
        }

        stackedParcelsRequestsRef.current = stackedParcelsRequestsRef.current.map((request) => {
          if (request?.parcelId === updatedRow.id) {
            return {
              ...request,
              ...toUpdate,
            };
          } else {
            return request;
          }
        });

        Object.keys(toUpdate).forEach((field) => {
          if (isSameAsOriginal(field, parcels, updatedRow)) {
            stackedParcelsRequestsRef.current = stackedParcelsRequestsRef.current.map((request) => {
              if (request?.parcelId === updatedRow.id) {
                delete request[field as keyof IStackedParcelsRequestsRef];
                delete toUpdate[field as keyof IStackedParcelsRequestsRef];
                return {
                  ...request,
                  ...toUpdate,
                };
              } else {
                return request;
              }
            });
          }
        });

        stackedParcelsRequestsRef.current = stackedParcelsRequestsRef.current.filter(
          (request) => Object.keys(request).length > 1
        );

        setIsAllowedSubmit(stackedParcelsRequestsRef.current.length ? true : false);

        const updatedSurface = Number(updatedRow.surface);
        const totalSurface = updatedRow?.isParent
          ? childrenTotalSurface(updatedRow?.id, parcels) + updatedSurface
          : updatedSurface;

        updatedRow.surface = updatedSurface;
        updatedRow.totalSurface = totalSurface;

        setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));

        return updatedRow;
      }

      return previousRow;
    },
    [parcels, rowModesModel]
  );

  return (
    rows && (
      <div className="parcel_datagrid_container">
        <CustomDataGrid
          rows={rows}
          columns={processedColumns}
          processRowUpdate={processRowUpdate}
          loading={dataGridLoading}
          sortModel={sortModel}
          rowModesModel={rowModesModel}
          setRowModesModel={setRowModesModel}
          fromParcel
          columnVisibilityModel={columnsVisible}
          setColumnVisibilityModel={setColumnsVisible}
        />
        {renderDeleteDialog()}
        {renderDeletegroupingDialog()}
      </div>
    )
  );
};

export default DataGridParcels;
