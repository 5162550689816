import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { getCulturesByExploitationId, resetGroundTypeEffects } from 'services/API/Exploitation';
import { ROLE_FARMER } from 'utils/const';
import { displayErrorMessage } from 'utils/tools_functions';
import { sendEvent } from 'utils/Event';

import GroundTypeEffectForm from 'components/pages/Exploitation/form/GroundTypeEffect';
import GroundTypeEffectTableRow from 'components/pages/Cooperative/table/GroundTypeEffectRow';

import exploitationProp from 'components/propTypes/exploitationFull';

import Table from 'components/generics/Table/Table';
import IconButton from 'components/generics/Button';
import Select from 'components/generics/Select';
import { FormModal } from 'components/generics/Modals/FormModal';

const ExploitationGroundTypeEffects = ({ exploitation, setExploitation }) => {
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [cultureSelected, setCultureSelected] = useState(null);
  const [groundTypesEffects, setGroundTypesEffects] = useState([]);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [exploit, setExploit] = useState(exploitation);

  useEffect(() => {
    if (!exploit) {
      if (!exploit && localStorage.getItem('exploitation')) {
        setExploitation(+localStorage.getItem('exploitation'))
          .then((resp) => setExploit(resp.data.data))
          .catch((err) => toast.error(err.data));
      } else {
        toast.info('Selectionnez une exploitation');
        navigate('/exploitation');
      }
    }
    // Dismount
    return () => {
      sendEvent('cancelHttpRequest');
    };
  }, []);

  useEffect(() => {
    if (exploit) {
      refreshData();
    }
  }, [exploit]);

  useEffect(() => {
    cultureSelected &&
      setGroundTypesEffects(data.filter((e) => e.id === +cultureSelected)[0].groundTypeEffects);
  }, [cultureSelected, data]);

  useEffect(() => {
    if (data && !cultureSelected) {
      data && setCultureSelected(data[0]?.id);
    }
  }, [data]);

  const refreshData = () => {
    getCulturesByExploitationId(exploit.id)
      .then((response) => {
        setData(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          toast.error(displayErrorMessage('ERR_GET_EFFECT'));
        }
      });
  };

  const resetAllGroundTypeEffects = () => {
    resetGroundTypeEffects(exploit)
      .then(() => {
        toast.success(`Effets ${exploit.name} réinitialisés`);
        refreshData();
      })
      .catch(() => toast.error(displayErrorMessage('ERR_REINIT_EFFECT')));
  };

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    refreshData();
  };

  const actions = [
    {
      label: 'Modifier',
      func: (e) => {
        setEditData(e);
        setModal(true);
      },
      minimalRole: ROLE_FARMER,
    },
  ];

  return (
    <div className="section">
      <div className="main_container">
        <h1 className="title_section">Matrice type de sol</h1>
        <div className="contain-header">
          <div className="flex-row">
            {data && (
              <Select
                className="reduce"
                name="Cultures"
                onChange={(e) => setCultureSelected(+e)}
                options={data}
                value={cultureSelected}
                itemLabel="name"
                itemValue="id"
              />
            )}
          </div>
          <div className="add_button_container">
            <IconButton
              text="Remettre les valeurs par défaut"
              onClick={resetAllGroundTypeEffects}
            />
          </div>
        </div>
        <Table
          header={['Type de sol', 'Effet (%)', '']}
          data={groundTypesEffects}
          RowElement={GroundTypeEffectTableRow}
          actions={actions}
        />
      </div>
      {modal && (
        <FormModal
          onClick={() => {
            setEditData(null);
            setModal(false);
          }}
          customTemplate={
            <GroundTypeEffectForm
              onSubmitSuccess={formSuccess}
              exploitation={exploit}
              culture={cultureSelected}
              edit={editData}
            />
          }
        ></FormModal>
      )}
    </div>
  );
};

ExploitationGroundTypeEffects.propTypes = {
  exploitation: function (props, propName, componentName) {
    const propValue = props[propName];
    if (propValue === null) return;
    if (PropTypes.shape(exploitationProp)) return;
    return new Error(`${componentName} only accepts null or exploitationProp`);
  },
};

export default ExploitationGroundTypeEffects;
