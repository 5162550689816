import { styled } from '@mui/material/styles';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Checkbox, Tooltip, createTheme, tooltipClasses } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-pro';

export const CustomSortableHeader = ({ setSortModel, fieldName, name, optionnalName = null }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <div
        style={{
          height: '50px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <StyledTooltip placement="top" title={optionnalName ?? name}>
          <span>{name}</span>
        </StyledTooltip>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '30px',
        }}
      >
        <div style={{ height: '15px' }}>
          <ArrowDropUpIcon
            sx={{
              color: '#277134',
              cursor: 'pointer',
              display: 'block',
            }}
            onClick={() =>
              setSortModel([
                {
                  field: fieldName,
                  sort: 'asc',
                },
              ])
            }
          />
        </div>
        <div style={{ height: '15px' }}>
          <ArrowDropDownIcon
            sx={{
              color: '#277134',
              cursor: 'pointer',
              display: 'block',
            }}
            onClick={() =>
              setSortModel([
                {
                  field: fieldName,
                  sort: 'desc',
                },
              ])
            }
          />
        </div>
      </div>
    </div>
  );
};

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#e69636',
    fontSize: 14,
    fontFamily: 'Rubik-Medium',
  },
}));

export const mediaQuery = createTheme({
  breakpoints: {
    values: {
      xs: 640,
      sm: 1024,
      md: 1650,
      lg: 1920,
      xl: 2559,
      xxl: 3000,
    },
  },
});

export function DataGridCheckbox(props) {
  return <Checkbox color="green" {...props} />;
}

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={{ color: '#277134' }} />
    </GridToolbarContainer>
  );
}

export const processColumns = (columnsDefinition, columnsMetadata) => {
  const actionsColumn = columnsDefinition.find((column) => column.field === 'actions');
  const processedColumns = columnsDefinition
    .filter((column) => {
      const isColumnInMedatada = columnsMetadata?.some(
        (columnDefinition) => columnDefinition.field === column.field
      );
      return isColumnInMedatada && column.field !== 'actions';
    })
    .map((column) => {
      const columnMetadata = columnsMetadata?.find(
        (columnDefinition) => column.field === columnDefinition.field
      );
      return { ...column, editable: columnMetadata?.editable, position: columnMetadata?.position };
    })
    .sort((a, b) => a.position - b.position);

  return actionsColumn ? [...processedColumns, actionsColumn] : processedColumns;
};

export const customToolBarStyle = {
  '& .MuiPaper-root': { color: '#277134' },
  '& .MuiPaper-root .MuiTypography-root': {
    fontFamily: 'Rubik-Medium',
  },
  '& .MuiPaper-root .MuiFormControl-root': {
    display: 'none',
  },
  '& .MuiPaper-root .MuiButtonBase-root.MuiButton-text': {
    color: '#277134',
  },
  '& .MuiPaper-root .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked': {
    color: '#e69636',
  },
  '& .MuiPaper-root .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#e69636',
  },
  '& .MuiPaper-root .MuiButtonBase-root.MuiSwitch-switchBase+.MuiSwitch-track': {
    backgroundColor: '#277134',
  },
};

export const getFamily = (child, parcels) => {
  return parcels
    ?.flatMap((parcel) => {
      if (parcel.isParent && parcel.id === child.parent) {
        return [parcel.id, child.id];
      }
    })
    .filter((member) => member);
};

export const childrenTotalSurface = (parentId, parcels) => {
  return parcels
    .filter((parcel) => parcel.parent === parentId)
    .map((childParcel) => childParcel.surface)
    .reduce((acc, current) => acc + current, 0);
};

export const totalSurfaceComparator = (v1, v2, param1, param2) => {
  return gridStringOrNumberComparator(v1.totalSurface, v2.totalSurface, param1, param2);
};

export const resetErrorsForm = (...errorForms) => {
  errorForms.forEach((errorForm) => {
    errorForm.formError = false;
    for (const key in errorForm) {
      if (typeof errorForm[key] === 'object') {
        errorForm[key]['message'] = '';
      }
    }
  });
};
