import { Box, TextField, createTheme } from '@mui/material';
import { GridRowModes, useGridApiContext } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { getPercentage } from 'utils/tools_functions';
import SouthIcon from '@mui/icons-material/South';

export const customCropToPlantCell = (params) => {
  return (
    <Box className="crop-to-plant-box">
      <div>{params.value}</div>
    </Box>
  );
};

export const CustomCropToPlantHeader = (params) => {
  return (
    <p>
      <span>{params.colDef.headerName}</span>
      <SouthIcon />
    </p>
  );
};

export const CustomEditBrushAutomaticEffectCell = ({
  params,
  selectedBrushValue,
  previousRotationLevel,
  currentRotationLevel,
}) => {
  const { id, field, value } = params;
  const apiRef = useGridApiContext();

  //* in a useEffect so the reset of the Ref is done after the call to processRowUpdate
  useEffect(() => {
    if (previousRotationLevel.current !== currentRotationLevel) {
      previousRotationLevel.current = currentRotationLevel;
    }
  }, [currentRotationLevel]);

  const handleChange = async (e) => {
    apiRef.current.setEditCellValue({ id, field, value: selectedBrushValue });
    if (params.cellMode === 'edit') {
      apiRef.current.stopCellEditMode({ id, field, value: selectedBrushValue });
    }
  };

  useEffect(() => {
    if (selectedBrushValue) {
      handleChange();
    }
  }, []);

  return (
    <Box className="brush-edit-cell">
      <TextField value={selectedBrushValue ?? value} autoFocus={true} />
    </Box>
  );
};

export const CustomEffectEditCell = ({
  params,
  errorsEffectFormDefault,
  currentRotationLevel,
  rowModesModel,
  setRowModesModel,
  previousRotationLevel,
}) => {
  const { id, field, value } = params;
  //* in a useEffect so the reset of the Ref is done after the call to processRowUpdate
  useEffect(() => {
    if (previousRotationLevel.current !== currentRotationLevel) {
      previousRotationLevel.current = currentRotationLevel;
    }
  }, [currentRotationLevel]);

  const apiRef = useGridApiContext();
  const handleEffectChange = (e) => {
    const {
      target: { value },
    } = e;
    apiRef.current.setEditCellValue({ id, field, value });
  };

  const handleFocusClick = (id) => () => {
    setRowModesModel((prevRowModesModel) => {
      if (Object.keys(prevRowModesModel).length === 0) {
        return { [id]: { mode: GridRowModes.Edit } };
      } else {
        let resetPrevRowModesModel = {};
        for (const key in prevRowModesModel) {
          if (prevRowModesModel.hasOwnProperty(key)) {
            resetPrevRowModesModel[key] = { mode: GridRowModes.View };
          }
        }
        return { ...resetPrevRowModesModel, [id]: { mode: GridRowModes.Edit } };
      }
    });
  };

  const isError =
    errorsEffectFormDefault?.cellErrors?.[currentRotationLevel].hasOwnProperty(id) &&
    errorsEffectFormDefault?.cellErrors?.[currentRotationLevel]?.[id].includes(parseInt(field));

  return (
    <Box className="edit-cell">
      <TextField
        className="edit-cell-textfield"
        inputProps={{
          style: {
            border: isError ? '2px solid #d12d38' : '',
          },
        }}
        onFocus={handleFocusClick(id)}
        onChange={(e) => handleEffectChange(e)}
        value={value}
        autoFocus={true}
      />
    </Box>
  );
};

export const handleRowUpdateValidation = (row, errorsEffectFormDefault) => {
  return new Promise((resolve, reject) => {
    if (errorsEffectFormDefault.formError === false) {
      resolve(row);
    } else {
      reject();
    }
  });
};

export const getFramingValueColors = (value, brushValueList) => {
  const brushNumberList = brushValueList.map(({ number }) => number);
  let superiorNumber = Math.max(...brushNumberList);
  let inferiorNumber = Math.min(...brushNumberList);

  if (value < inferiorNumber) {
    return null;
  }

  const findColor = (num) => brushValueList.find(({ number }) => number === num).color;

  if (value > superiorNumber) {
    const colorValue = findColor(superiorNumber);
    return {
      superiorValue: { color: colorValue, percentage: '100' },
      inferiorValue: { color: colorValue },
    };
  }

  if (brushNumberList.includes(value)) {
    const colorValue = findColor(value);
    return {
      superiorValue: { color: colorValue, percentage: '100' },
      inferiorValue: { color: colorValue },
    };
  }

  brushNumberList.forEach((number) => {
    if (number >= value && number < superiorNumber) {
      superiorNumber = number;
    }
    if (number <= value && number > inferiorNumber) {
      inferiorNumber = number;
    }
  });

  let superiorValue = {
    color: brushValueList.find((value) => value.number === superiorNumber).color,
    percentage: getPercentage(value, superiorNumber),
  };
  let inferiorValue = {
    color: brushValueList.find((value) => value.number === inferiorNumber).color,
  };

  return { superiorValue, inferiorValue };
};

export const CustomNoRowsOverlay = () => {
  return <Box className="noRowOverlay">Aucune donnée à afficher</Box>;
};

export const themeRotateEffectsFilter = createTheme({
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '188px',
          border: '1px solid #e69636 !important',
          borderRadius: '5px',
          margin: '5px',
          '.MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#e69636 !important',
            top: '-2px !important',
          },
          '.MuiOutlinedInput-root.Mui-focused legend': {
            display: 'none !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87) !important',
          maxHeight: '50px !important',
          '.MuiSelect-select.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.65) !important',
          },
          '.MuiSvgIcon-root.MuiSelect-icon': {
            color: '#277134 !important',
          },
        },
        notchedOutline: {
          borderColor: 'unset !important',
          borderWidth: '0px !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#e69636 !important',
          '&.Mui-checked': {
            color: '#277134 !important',
          },
        },
      },
    },
  },
});

export const themeRotateEffectsPalette = createTheme({
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          width: '25px',
          height: '25px',
          right: '10px',
          backgroundColor: '#e69636',
          border: `2px solid white`,
          borderRadius: '100px',
        },
      },
    },
  },
});
