import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { deleteGroundType } from 'services/API/Cooperative';
import { ROLE_ADMIN } from 'utils/const';

import GroundTypeForm from 'components/pages/Cooperative/form/GroundType';
import GroundTypeTableRow from 'components/pages/Cooperative/table/GroundTypeTableRow';

import Table from 'components/generics/Table/Table';
import IconButton from 'components/generics/Button';
import More from 'components/icons/More';
import { FormModal } from 'components/generics/Modals/FormModal';
import { displayErrorMessage } from 'utils/tools_functions';

const CooperativeGroundTypes = ({ groundTypes, refreshData }) => {
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);

  const formSuccess = () => {
    setModal(false);
    setEditData(null);
    refreshData();
  };

  const actions = [
    {
      label: 'Modifier',
      func: (e) => {
        setEditData({ name: e.name, id: e.id });
        setModal(true);
      },
      minimalRole: ROLE_ADMIN,
    },
    {
      label: 'Supprimer',
      func: (e) =>
        deleteGroundType(e)
          .then((_) => {
            toast.success(`${e.name} supprimé`);
            refreshData();
          })
          .catch((err) => {
            const errorContext = err?.response?.data?.context;
            if (errorContext?.errorCode) {
              toast.error(displayErrorMessage(errorContext?.errorCode, errorContext?.data));
            } else {
              toast.error(err?.response?.data.message ?? displayErrorMessage('ERR_UNEXPECTED'));
            }
          }),
      minimalRole: ROLE_ADMIN,
    },
  ];

  return (
    <div className="section">
      <div className="main_container">
        <div className="contain-header">
          <h1 className="title_section">Liste des types de sol</h1>
          <div className="add_button_container">
            <IconButton
              text="Ajouter"
              onClick={() => setModal(!modal)}
              icon={<More width={13} height={13} />}
            />
          </div>
        </div>
        <Table
          header={['Nom', '']}
          data={groundTypes}
          RowElement={GroundTypeTableRow}
          actions={actions}
        />
      </div>
      {modal && (
        <FormModal
          onClick={() => {
            setEditData(null);
            setModal(false);
          }}
          customTemplate={<GroundTypeForm onSubmitSuccess={formSuccess} edit={editData} />}
        ></FormModal>
      )}
    </div>
  );
};

CooperativeGroundTypes.propTypes = {
  groundTypes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  refreshData: PropTypes.func.isRequired,
};

CooperativeGroundTypes.defaultProps = {
  groundTypes: [],
};

export default CooperativeGroundTypes;
