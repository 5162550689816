import React from 'react';
import PropTypes from 'prop-types';

const Edit = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 18.605 18.605">
    <g>
      <path
        color={color}
        fill={fill}
        d="M17.426 1.18a4.037 4.037 0 00-5.7 0L.216 12.689a.73.73 0 00-.213.509v4.68a.727.727 0 00.727.727h4.675a.729.729 0 00.514-.213l11.51-11.51a4.037 4.037 0 000-5.7zM1.454 17.152v-2.194l2.193 2.193zm3.948-.3l-3.647-3.647 9.082-9.082 1.31 1.31-4.881 4.881a.727.727 0 001.028 1.028l4.881-4.881 1.31 1.31zm11-11l-.886.887-3.648-3.647.887-.886a2.579 2.579 0 013.647 3.647zm0 0"
      />
    </g>
  </svg>
);

Edit.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Edit.defaultProps = {
  color: '#000',
  fill: '#e69636',
  width: 18.605,
  height: 18.605,
};

export default Edit;
