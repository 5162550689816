import PropTypes from 'prop-types';

import parcelProps from 'components/propTypes/parcel';

export default PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  sector: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  parcels: PropTypes.arrayOf(parcelProps),
});
