// REACT
import { useState } from 'react';
// YARN
import {
  GridCellParams,
  GridGroupingColDefOverride,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { Button, ThemeProvider } from '@mui/material';
// SERVICES
import CustomDataGridResult from '../../../../utils/Datagrid/CustomForecastDataGridResult';
import { customDataGridResultSimulation } from '../../../../utils/Datagrid/CustomForecastDataGridUtils';
// THEMES
import './dataGridKeyNumberNitrogen.scss';
import { customInvertedButtonTheme } from 'assets/styles/themes/generic_button_mui';

type DataGridKeyNumberNitrogenParams = {
  rows: GridRowModel[];
  columns: string[];
};

const DataGridKeyNumberNitrogen = ({ rows, columns }: DataGridKeyNumberNitrogenParams) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const processedColumns = columns?.map((column, index) => ({
    ...(index === 0
      ? {
          flex: 1.5,
          cellClassName: (params: GridCellParams) => {
            if (params.row.hierarchy.length > 1) {
              return 'campaign-cell campaign-cell__first-children';
            }
            return 'campaign-cell';
          },
          renderCell: (params: GridRenderCellParams) => CustomKeyNumberCell(params),
          align: 'left',
        }
      : {
          cellClassName: (params: GridCellParams) => {
            if (params.row.hierarchy.length > 1) {
              return 'nitrogen-children';
            }
          },
          align: 'center',
          flex: 1,
        }),
    ...(index === 1 && {
      cellClassName: (params: GridCellParams) => {
        if (params.row.hierarchy.length > 1) {
          return 'previous-campain nitrogen-children';
        }
        return 'previous-campain';
      },
    }),
    field: column,
    headerName: column,
    sortable: false,
    headerAlign: 'center',
  }));

  const findParentIndex: number = rows.findIndex((row) => row?.isParent);
  const filteredRows: GridRowModel[] = rows.filter(
    (row) =>
      !row.isChild ||
      (row?.isChild &&
        row.data.some((datum: any) => datum.value !== '0 u/ha' && datum.value !== '-'))
  );

  type ProcessedRowType = {
    id: number;
    hierarchy: number[];
  };
  const processedRows: (ProcessedRowType | undefined)[] | undefined = filteredRows?.map(
    (row, index) => {
      let processedRow = {
        id: index,
        hierarchy: row?.isChild ? [findParentIndex, index] : [index],
        ...(row?.unit && { unit: row.unit }),
      };
      processedColumns?.map((column, idx) => {
        processedRow[column?.field] =
          column?.field === 'Campagne' ? row.id : row?.data?.[idx - 1]?.value;
      });
      return processedRow;
    }
  );

  const groupingColDef: GridGroupingColDefOverride = {
    width: 5,
    valueFormatter: () => '',
    headerName: '',
    // field: 'group',
    align: 'right',
    hideDescendantCount: true,
  };

  const getTreeDataPath = (row: GridRowModel) => row.hierarchy;

  const CustomKeyNumberCell = (params: GridRenderCellParams) => {
    const isChild = params?.row?.hierarchy?.length > 1;
    const displayNitrogenSupply = params?.row?.unit ?? false;

    if (displayNitrogenSupply) {
      return (
        <Box>
          <div className="campaign-cell__wrapper">
            <div className="campaign-cell__group" title={params.value}>
              {params.value}
            </div>
            <div className="campaign-cell__unit">
              {`(${displayNitrogenSupply})`}
              <span className="nitrogen-key-figures-container__asterisk">*</span>
            </div>
          </div>
        </Box>
      );
    }

    return (
      <p
        className={isChild ? 'campaign-cell__main campaign-cell__child' : 'campaign-cell__main'}
        title={params.value}
      >
        {params.value}
      </p>
    );
  };

  const getRowClassName = () => 'Mui-even';

  return (
    <div className="nitrogen-key-figures-container">
      <div className="nitrogen-key-figures-container__title">
        <h2>Chiffres clés azote</h2>
        <ThemeProvider theme={customInvertedButtonTheme}>
          <Button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? 'Fermer' : 'Afficher'}
          </Button>
        </ThemeProvider>
      </div>

      <div
        className={
          isOpen
            ? 'nitrogen-key-figures-container__opened'
            : 'nitrogen-key-figures-container__closed'
        }
      >
        <CustomDataGridResult
          rows={processedRows}
          columns={processedColumns}
          isLoading={false}
          theme={customDataGridResultSimulation}
          simulationContext={'simulation-box'}
          fullSize={true}
          getRowClassName={getRowClassName}
          getTreeDataPath={getTreeDataPath}
          treeData={true}
          fromKeyNumberNitrogen
          groupingColDef={groupingColDef}
        />
        <p className="nitrogen-key-figures-container__legend">
          <span className="nitrogen-key-figures-container__asterisk">*</span> Cette information
          provient de la quantité d'azote d'un couvert.
        </p>
      </div>
    </div>
  );
};

export default DataGridKeyNumberNitrogen;
