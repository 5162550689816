import React from 'react';
import PropTypes from 'prop-types';

const MenuRight = ({ color, fill, width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 22.5">
    <g id="menu" transform="translate(-345.5 -19.5)">
      <line
        id="Ligne_45"
        data-name="Ligne 45"
        x2="26"
        transform="translate(346.5 20.5)"
        fill={fill}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Ligne_47"
        data-name="Ligne 47"
        x2="26"
        transform="translate(346.5 41)"
        fill={fill}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        id="Ligne_46"
        data-name="Ligne 46"
        x2="17"
        transform="translate(355.5 30.75)"
        fill={fill}
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
MenuRight.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

MenuRight.defaultProps = {
  color: '#fff',
  fill: 'none',
  width: 19.031,
  height: 19.031,
};

export default MenuRight;
