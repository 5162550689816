const { createTheme } = require('@mui/material');

export const customGenericInputTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#FFF',
          fontSize: '15px',
          fontFamily: 'Rubik-Medium',
          minWidth: 180,
          marginLeft: '-5px',
          left: 5,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '60px',
          marginLeft: '10px',
          borderRadius: '5px',
          '& .MuiInputBase-input': {
            padding: '5px',
          },
          '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#e69636 !important',
            },
          '&.MuiErrorForm': {
            border: '2px solid #d32f2f !important',
            borderColor: '#d32f2f !important',
          },
          '&.MuiErrorForm .MuiOutlinedInput-root.Mui-focused': {
            '& fieldset': {
              borderColor: '#d32f2f !important',
            },
          },
          '&.MuiErrorForm .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#d32f2f !important',
            },
          '& .MuiOutlinedInput-root.Mui-focused': {
            borderColor: '#e69636 !important',
          },
          '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: '#e69636 !important',
          },
          '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
              borderColor: '#e69636 !important',
            },
        },
      },
    },
  },
});
