import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import patternGreen from 'assets/images/pattern-green.png';
import patternRed from 'assets/images/pattern-red.png';
import patternRedAndGreen from 'assets/images/pattern-redandgreen.png';
import ParcelInfo from 'components/generics/Map/ParcelInfo';
import { SimulationContext } from 'components/pages/Exploitation/SimulationContext';
import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { Point } from 'ol/geom.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Vector as VectorSource } from 'ol/source.js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ExploitationContext } from 'utils/context';
import { createNewMap, gpsToPixel, greenPattern, handleMouseMoveEvent, redAndGreenPattern, redPattern, setPolygonStyle, zoomToFit } from './MapCommon';
import YearsSelect from './YearsSelect';

import './Map.style.scss';

function MapConstraints({
  simulationYears,
  updateParcel,
  culturesList,
  simulationModalCulture,
  setSimulationModalCulture,
  mapYear,
  setMapYear,
  center }) {
  const { obligationAndRestriction } = React.useContext(SimulationContext);
  const { exploitationContext } = useContext(ExploitationContext);
  const [parcelInfo, setParcelInfo] = useState(null);
  const [modalCoords, setModalCoords] = useState(null);
  const [storeParcelColor, setStoreParcelColor] = useState([]);
  const [map, setMap] = useState();
  const [currentCulture, setCurrentCulture] = React.useState('');
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const mapElement = useRef();
  const popup = useRef(null);

  useEffect(() => {
    return handleMouseMoveEvent(setCoords);
  }, []);

  useEffect(() => {
    setMap(createNewMap());
  }, []);

  useEffect(() => {
    if (map) {
      map.once('rendercomplete', function (event) {
        setCurrentCulture(false);
        setMapDetails(map);
        zoomToFit(map);
      });
    }
  }, [map, center])

  useEffect(() => {
    if (map) {
      setMapDetails(map);
    }
  }, [mapYear, simulationModalCulture, obligationAndRestriction]);

  function setMapDetails(map) {
    if (map && mapYear) {
      map.getLayers().getArray()
        .filter(layer => layer.get('id') === 'polygonVector')
        .forEach(layer => map.removeLayer(layer));

      const polygonSourceEffect = new VectorSource();

      exploitationContext?.parcels.filter(parcel => parcel.coordinates).map(parcel => {
        let constrainOrRestrict = 'lightgrey';
        let parcelCultureAtYear = {};
        parcelCultureAtYear = parcel.cultureN;

        const parcelFeature = new Feature({
          geometry: new Polygon(gpsToPixel(parcel.coordinates)),
        });
        const parcelIdByYear = parcel.id + parcel.name;
        parcelFeature.setId(parcelIdByYear);
        parcelFeature.set('parcelId', parcel.id);
        obligationAndRestriction.forEach((oAr) => {
          oAr.years.forEach((oArYear) => {
            if (Number(oArYear) === Number(mapYear)) {
              if (simulationModalCulture && oAr.Exploitation_Culture_Id.some((cultureId) => cultureId === simulationModalCulture)) {
                oAr.Parcel_Id.map((parcelId) => {
                  if (Number(parcelId) === Number(parcel.id)) {
                    if (oAr.constraint.toString() === "false") {  constrainOrRestrict = 'red'; }
                    if (oAr.constraint.toString() === "true") {  constrainOrRestrict = 'green'; }
                  }
                });
              }
              else {
                oAr.Parcel_Id.map((parcelId) => {
                  if (Number(parcelId) === Number(parcel.id) && !simulationModalCulture) {
                    if (constrainOrRestrict !== 'lightgrey') {  constrainOrRestrict = redAndGreenPattern(); }
                    else {
                      if (oAr.constraint.toString() === "false") {  constrainOrRestrict = redPattern(); }
                      if (oAr.constraint.toString() === "true") {  constrainOrRestrict = greenPattern(); }
                    }
                  }
                });
              }
            }
          });
        });

        setPolygonStyle(parcelFeature, constrainOrRestrict);

        let finalColorTable = storeParcelColor;
        finalColorTable.forEach((parcelColor, index) => {
          if (parcelColor.id === (parcelIdByYear)) {
            finalColorTable.splice(index, 1);
          }
        });
        finalColorTable.push({
          id: parcelIdByYear,
          culture: parcelCultureAtYear?.name
        });

        setStoreParcelColor(finalColorTable);
        polygonSourceEffect.addFeature(parcelFeature);
        return polygonSourceEffect;
      });

      const geometryFunction = function () {
        polygonSourceEffect.getFeatures().forEach((feature) => {
          return new Point(getCenter(feature.getGeometry().getExtent()));
        });
      };

      const polygonVector = new VectorLayer({
        id: 'polygonVector',
        source: polygonSourceEffect,
        geometry: geometryFunction(),
      });

      map.addLayer(polygonVector);
      map.set();

      let selected = null;
      map.on('pointermove', function (e) {
        if (selected !== null) {
          setParcelInfo(null);
          setModalCoords(null);
          selected = null;
        }
        map.forEachFeatureAtPixel(e.pixel, function (f) {
          selected = f;
          setParcelInfo(exploitationContext?.parcels.find(parcel => parcel.id + parcel.name === f.getId()));
          setModalCoords(e.pixel);
          return true;
        });
      });

      map.on('click', function (e) {
        map.forEachFeatureAtPixel(e.pixel, function (feature) {
          updateParcel(feature.get('parcelId'));
        })
      });
    }
  }

  const handleCultureChange = (event) => {
    setCurrentCulture(event.target.value);
    setSimulationModalCulture(event.target.value);
  }

  return (
    <div className="map_container map_constraints">
      <div className="map_form">
        <YearsSelect data={simulationYears} mapYear={mapYear} setMapYear={setMapYear}></YearsSelect>
        <FormControl fullWidth className="culture_select">
          <InputLabel id="cultureList">Culture</InputLabel>
          <Select
            labelId="cultureList"
            id="cultureList"
            value={currentCulture}
            label="Culture"
            onChange={handleCultureChange}
          >
            <MenuItem key={0} value={false} defaultValue>Culture</MenuItem>

            {culturesList.map((culture) =>
              <MenuItem key={culture.id} value={culture.id}>{culture.name}</MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
      <div ref={mapElement}>
        <div id="map" className="map_element" onMouseOut={() => { setParcelInfo(null); setModalCoords(null) }}></div>
        {parcelInfo &&
          <div className="parcel-info-modal" ref={popup}>
            {modalCoords &&
              <div id="popup"
                className="ol-popup"
                style={{
                  marginLeft: (coords.x - 50) + 'px',
                  marginTop: (coords.y - 300) + 'px'
                }}>
                <ParcelInfo parcelInfo={parcelInfo}></ParcelInfo>
              </div>
            }
          </div>
        }
      </div>
      <div className="map_legend">
        <p>
          <span className="culture_legend" style={{ background: '#277134' }}></span>
          <img src={patternGreen} className="culture_legend" alt="Obligation(s)" style={{ width: '20px' }} />
          Obligation(s)
        </p>
        <p>
          <span className="culture_legend" style={{ background: '#d23540' }}></span>
          <img src={patternRed} className="culture_legend" alt="Interdiction(s)" style={{ width: '20px' }} />
          Interdiction(s)
        </p>
        <p>
          <img src={patternRedAndGreen} className="culture_legend" alt="Obligation(s) et interdition(s)" style={{ width: '20px' }} />
          Les deux
        </p>
      </div>
    </div>
  );
}

export default MapConstraints;