import React, { useState, useEffect, useRef, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  checkDecimalRegexTwoDigits,
  checkDigitRegex,
  checkNumericOnInputNumber,
  checkObjectsEquality,
  checkTypeStringRegexDatagrid,
  displayErrorMessage,
  displayMessage,
} from 'utils/tools_functions';
import { addParcel, addForecast, updateParcel } from 'services/API/Exploitation';
import Button from '@mui/material/Button';
import {
  inputAbsolutePositiveFloatValidator,
  inputNumberRequired,
  inputRequired,
} from 'components/generics/Validators/validator';
import { useContext } from 'react';
import { ExploitationContext, LoadingContext } from 'utils/context';
import { validateFormData } from 'components/generics/Validators/form.validate';
import { emptyCulture } from 'utils/culturesColors';
import { ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  FormControl,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { customButtonTheme } from 'assets/styles/themes/generic_button_mui';
import { StyledTooltipErrorInputCostModal } from 'utils/Datagrid/CustomCultureDataGridUtils';
import LoadingScreen from 'components/generics/loadingScreen';
import { customDialogAddEditParcelsTheme } from 'assets/styles/themes/Dialog/generic_dialog_add_edit_parcel';
import { updateExploitationContext } from 'utils/Datagrid/CustomForecastDataGridUtils';

const DEFAULT_FORM = {
  name: '',
  surface: '',
  cultureN: 'EMPTY',
  cultureN1: 'EMPTY',
  groundType: '',
  forecast: 0,
  isIrrigable: false,
};

const AddEditParcelModal = ({ modal, setModal, onSubmitSuccess, edit, setExploitationContext }) => {
  const [formData, setFormData] = useState(edit || DEFAULT_FORM);
  const [culture, setCulture] = useState(null);
  const [groundTypes, setGroundTypes] = useState(null);
  const [loadingComplete, setLoadinComplete] = useState(true);
  const { exploitationContext } = useContext(ExploitationContext);
  const setTextLoader = useContext(LoadingContext);
  const [errorsFormDefault, setErrorsFormDefault] = useState({
    formError: false,
    name: {
      message: '',
      validator: [inputRequired],
    },
    surface: {
      message: '',
      validator: [inputNumberRequired, inputAbsolutePositiveFloatValidator],
    },
  });
  const currentYear = +localStorage.getItem('currentYear');
  const ref = useRef();

  const loadData = () => {
    try {
      if (edit) {
        setCulture(
          edit.cultureN1 === 'EMPTY'
            ? [...exploitationContext?.cultures, emptyCulture]
            : [...exploitationContext?.cultures]
        );
      } else {
        setCulture([...exploitationContext?.cultures, emptyCulture]);
      }
      setGroundTypes(exploitationContext?.groundTypes);
      setLoadinComplete(false);
    } catch (error) {
      toast.error(displayErrorMessage('ERR_UNEXPECTED'));
      setModal(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [exploitationContext]);

  useEffect(() => {
    if (culture && groundTypes) {
      const tmp = {
        cultureN: formData.cultureN,
        cultureN1: formData.cultureN1,
        forecast: formData.forecast,
        groundType: formData.groundType,
      };
      if (!tmp.cultureN) {
        tmp.cultureN = culture[0].id;
      }
      if (!tmp.cultureN1) {
        tmp.cultureN1 = culture[0].id;
      }
      if (!tmp.forecast && tmp.forecast !== 0) {
        tmp.forecast = culture[0].id;
      }
      if (!tmp.groundType) {
        tmp.groundType = groundTypes?.[0]?.id;
      }
      setFormData((prevFormData) => ({ ...prevFormData, ...tmp }));
    }
  }, [culture, groundTypes]);

  const onSubmit = async (e) => {
    errorsFormDefault.formError = false;
    setErrorsFormDefault({ ...validateFormData(formData, errorsFormDefault) });
    if (!errorsFormDefault.formError) {
      setModal(false);
      if (e) e.preventDefault();
      if (formData.cultureN === 'EMPTY') {
        formData.cultureN = undefined;
      }
      if (formData.cultureN1 === 'EMPTY') {
        formData.cultureN1 = undefined;
      }
      if (!edit) {
        setTextLoader.customText = displayMessage('CREATE_PARCEL_LOADER');
        try {
          const res = await addParcel(exploitationContext?.id, formatData(formData));
          const body = createForecastBody(res);
          if (body) {
            await addForecast(exploitationContext?.id, body);
          }
          toast.success(`Parcelle ${formData.name} créée`);
          setFormData(DEFAULT_FORM);
          onSubmitSuccess();
        } catch (err) {
          console.log('ERR', err);
          toast.error(err.response.data['message']);
        }
      } else {
        if (!checkObjectsEquality(edit, formData)) {
          try {
            const updatedParcelBody = [{ ...formatData(formData), parcelId: formData.id }];
            const res = await updateParcel(exploitationContext?.id, updatedParcelBody);
            const body = createForecastBody(res, true);
            if (body) {
              const result = await addForecast(exploitationContext?.id, body);
              const { data } = result.data;
              updateExploitationContext(setExploitationContext, data);
            }
            toast.success(`Parcelle ${formData.name} modifiée`);
            setFormData(DEFAULT_FORM);
            onSubmitSuccess();
          } catch (err) {
            console.log('ERR', err);
            toast.error(err.response.data['message']);
          }
        }
      }
    }
  };

  const createForecastBody = (res, fromUpdate = false) => {
    if (formData.forecast && formData.forecast !== 0) {
      const { data } = res.data;
      const parcelId = fromUpdate ? data[0].id : data.id;
      return {
        parcels: [
          {
            parcel: parcelId,
            culture: formData.forecast,
          },
        ],
        year: currentYear + 1,
      };
    }
    return null;
  };

  const forecastOptions =
    culture &&
    [...culture, { name: 'Aucun prévisionnel', id: 0 }].sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );

  const formatData = () => ({
    name: formData.name,
    surface: +formData.surface,
    isIrrigable: formData.isIrrigable,
    groundType: formData.groundType,
    ...((formData.cultureN || formData.cultureN1) && {
      parcelCultures: [
        ...(formData?.cultureN
          ? [
              {
                year: currentYear,
                culture: formData.cultureN,
              },
            ]
          : []),
        ,
        ...(formData?.cultureN1
          ? [
              {
                year: currentYear - 1,
                culture: formData.cultureN1,
              },
            ]
          : []),
        ,
      ].filter((object) => object),
    }),
  });

  const parentParcelName = exploitationContext?.parcels.find(
    (parcel) => parcel?.id === formData?.parentId
  )?.name;

  const updateForm = (key, value) => setFormData({ ...formData, [key]: value });

  const isOpen = useMemo(() => errorsFormDefault.formError, [errorsFormDefault]);

  return (
    <ThemeProvider theme={customDialogAddEditParcelsTheme}>
      <Dialog
        maxWidth="md"
        fullWidth
        open={modal}
        ref={ref}
        PaperProps={{ ref: ref }}
        onClose={() => setModal(false)}
      >
        <LoadingScreen open={loadingComplete}></LoadingScreen>
        <Box className="modal_header">
          <DialogTitle>{!edit ? 'Ajouter une parcelle' : 'Modifier la parcelle'}</DialogTitle>
          <ClearIcon className="close-icon" onClick={() => setModal(false)} />
        </Box>
        <DialogContent>
          <Box>
            <InputLabel id="nameLabel" htmlFor="name">
              Nom
            </InputLabel>
            {errorsFormDefault?.name.message && (
              <>
                <StyledTooltipErrorInputCostModal
                  open={isOpen}
                  title={errorsFormDefault?.name.message}
                  placement="top"
                >
                  <span></span>
                </StyledTooltipErrorInputCostModal>
              </>
            )}
            <TextField
              id="name"
              value={formData.name}
              onChange={(e) => {
                updateForm('name', checkTypeStringRegexDatagrid(e.target.value));
              }}
              placeholder="Entrez un nom"
              disabled={loadingComplete}
              className={errorsFormDefault?.name.message ? 'MuiErrorForm' : ''}
            />
          </Box>
          <Box>
            <InputLabel id="surface" htmlFor="surface">
              Surface
            </InputLabel>
            {errorsFormDefault?.surface.message && (
              <>
                <StyledTooltipErrorInputCostModal
                  open={isOpen}
                  title={errorsFormDefault?.surface.message}
                  placement="top"
                >
                  <span></span>
                </StyledTooltipErrorInputCostModal>
              </>
            )}
            <TextField
              id="surface"
              value={formData.surface}
              onKeyDown={(e) => checkNumericOnInputNumber(e)}
              onChange={(e) => {
                if (checkDecimalRegexTwoDigits(e) && checkDigitRegex(e)) {
                  updateForm('surface', e.target.value);
                } else {
                  e.preventDefault();
                }
              }}
              placeholder="Entrez la surface"
              disabled={loadingComplete}
              className={errorsFormDefault?.surface.message ? 'MuiErrorForm' : ''}
              inputProps={{
                'data-type': 'number',
              }}
            />
          </Box>
          {culture && groundTypes && (
            <>
              <Box>
                <InputLabel name="cultureN">
                  {currentYear - 1} - {currentYear}
                </InputLabel>
                <Select
                  id="cultureN"
                  sx={{ width: '300px' }}
                  onChange={(e) => updateForm('cultureN', e.target.value)}
                  value={formData.cultureN}
                  disabled={formData.parentId || formData.isParent ? true : false}
                >
                  {culture.map((cultureItem) => (
                    <MenuItem key={cultureItem?.id} value={cultureItem?.id}>
                      <ListItemText primary={cultureItem.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <InputLabel name="cultureN1">
                  {currentYear - 2} - {currentYear - 1}
                </InputLabel>
                <FormControl>
                  <Select
                    id="cultureN1"
                    sx={{ width: '300px' }}
                    onChange={(e) => updateForm('cultureN1', e.target.value)}
                    value={formData.cultureN1}
                    disabled={formData.parentId || formData.isParent ? true : false}
                  >
                    {culture.map((cultureItem) => (
                      <MenuItem key={cultureItem?.id} value={cultureItem?.id}>
                        <ListItemText primary={cultureItem.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <InputLabel name="groundType">Type de sol</InputLabel>
                <Select
                  id="groundType"
                  sx={{ width: '300px' }}
                  onChange={(e) => updateForm('groundType', e.target.value)}
                  value={formData.groundType}
                  disabled={formData.parentId || formData.isParent ? true : false}
                >
                  {groundTypes.map((groundTypeItem) => (
                    <MenuItem key={groundTypeItem?.id} value={groundTypeItem?.id}>
                      <ListItemText primary={groundTypeItem.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <InputLabel name="forecast">
                  Prévisionnel ({currentYear}-{currentYear + 1})
                </InputLabel>
                <Select
                  id="forecast"
                  sx={{ width: '300px' }}
                  onChange={(e) => updateForm('forecast', e.target.value)}
                  value={formData.forecast}
                  disabled={formData.parentId || formData.isParent ? true : false}
                >
                  {forecastOptions.map((forecast) => (
                    <MenuItem key={forecast?.id} value={forecast?.id}>
                      <ListItemText primary={forecast.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <InputLabel name="irrigable">Irrigable</InputLabel>
                <Checkbox
                  className="checkbox-irrigable"
                  id="irrigable"
                  label="irrigable"
                  checked={!!formData.isIrrigable}
                  onChange={() => {
                    updateForm('isIrrigable', !formData.isIrrigable);
                  }}
                />
              </Box>
            </>
          )}
        </DialogContent>
        {formData.parentId && (
          <p className="text-info-parcel">Cette parcelle est regroupée avec {parentParcelName}</p>
        )}
        {formData.isParent && (
          <p className="text-info-parcel">Cette parcelle est parente d'un regroupement</p>
        )}
        <DialogActions>
          <ThemeProvider theme={customButtonTheme}>
            <Button id="addParcelButton" onClick={onSubmit}>
              {!edit ? 'Ajouter' : 'Enregistrer'}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default AddEditParcelModal;
