const { createTheme } = require('@mui/material');

export const customDialogAddEditParcelsTheme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            border: '3px solid #e69636 !important',
            borderRadius: '20px',
            textAlign: 'center',
            fontSize: '18px',
            color: '#e69636 !important',
            width: '900px',
            height: '500px',
            maxWidth: 'unset !important',
            maxHeight: 'unset !important',
          },
          '& .MuiDialog-paper .MuiBox-root': {
            justifyContent: 'center !important',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          },
          '& .MuiDialog-paper .MuiBox-root .MuiSvgIcon-root': {
            fontSize: '36px',
            color: '#277134 !important',
            cursor: 'pointer',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          color: '#e69636',
          fontFamily: 'Rubik-Bold !important',
          color: '#277134 !important',
          userSelect: 'none',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          flexWrap: 'wrap',
          justifyContent: 'center',
          '& > label': {
            padding: '10px',
            overflow: 'unset !important',
            maxWidth: '160px',
            whiteSpace: 'pre-wrap',
            fontFamily: 'Rubik-Medium !important',
            color: '#277134 !important',
            userSelect: 'none',
          },
          '& .MuiBox-root': {
            width: '46% !important',
            flexWrap: 'wrap',
            margin: '8px',
            label: {
              width: '33%',
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
              maxWidth: '160px',
              overflow: 'unset !important',
              color: '#277134 !important',
              fontFamily: 'Rubik-Medium !important',
              textAlign: 'center',
            },
            '.MuiInputBase-root': {
              width: '60%',
              outline: '1px solid #e69636 !important',
              borderRadius: '4px !important',
              textAlign: 'start',
              ':hover': {
                outline: '2px solid #e69636 !important',
              },
              '.MuiInputBase-root': {
                width: '100% !important',
              },
              '&.Mui-error': {
                border: '2px solid #d32f2f !important',
                borderRadius: '4px !important',
                outline: 'unset !important',

                ':hover': {
                  outline: '2.5px solid #d32f2f !important',
                },
                '&.Mui-focused': {
                  outline: '2.5px solid #d32f2f !important',
                },
              },
            },
            ' .MuiInputBase-root.Mui-disabled': {
              outline: '1px solid #d2d2d2 !important',
              ':hover': {
                outline: '1px solid #d2d2d2 !important',
              },
            },
            '& .MuiOutlinedInput-root.Mui-focused': {
              outline: '2px solid #e69636 !important',
            },
          },
          '& .Mui-disabled': {
            cursor: 'not-allowed !important',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center !important',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '60%',
          '.MuiInputBase-root': {
            width: '100% !important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'unset !important',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          maxWidth: '100%',
          whiteSpace: 'pre-wrap',
        },
      },
    },
  },
});
