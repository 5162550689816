import React from 'react';
import PropTypes from 'prop-types';

const Exploitation = ({ color, fill, width, height }) => (
  <svg width={width} height={height} viewBox="0 0 47.015 32">
    <path
      color={color}
      fill={fill}
      d="M46.572 16.654L43.3 14.675a.918.918 0 00-.949 1.572l1.974 1.192-9.52 5.749-8.815-5.326a6.06 6.06 0 00-.372-.623l6.05-3.654a6.3 6.3 0 002.459 3.565.918.918 0 001 0 6.136 6.136 0 002.615-4.892 6.057 6.057 0 00-2.622-4.869.919.919 0 00-.989 0 5.9 5.9 0 00-1.161 1.047l-6.332-3.824a6.1 6.1 0 00-2.606-4.47.919.919 0 00-.989 0 6.105 6.105 0 00-2.6 4.436l-.018.01-6.394 3.862a5.913 5.913 0 00-1.172-1.06.919.919 0 00-.989 0 6.168 6.168 0 00-2.538 3.9L.444 16.654a.918.918 0 000 1.572l22.589 13.642a.919.919 0 00.949 0l22.589-13.642a.918.918 0 000-1.572zm-23.064.914a4.061 4.061 0 011.28 2.91 4.124 4.124 0 01-1.28 2.929 4.129 4.129 0 01-1.279-2.929 4.062 4.062 0 011.278-2.911zm11.116-8.217a4.061 4.061 0 011.28 2.91 4.124 4.124 0 01-1.28 2.929 4.129 4.129 0 01-1.279-2.929 4.062 4.062 0 011.279-2.911zM23.535 2.103a4.061 4.061 0 011.28 2.91 4.124 4.124 0 01-1.28 2.929 4.129 4.129 0 01-1.279-2.929 4.062 4.062 0 011.279-2.91zM12.358 9.35a4.061 4.061 0 011.28 2.91 4.124 4.124 0 01-1.28 2.929 4.129 4.129 0 01-1.279-2.929 4.062 4.062 0 011.279-2.91zm-9.664 8.09l6.665-4.025a6.278 6.278 0 002.5 3.737.918.918 0 001 0 6.136 6.136 0 002.615-4.892 5.607 5.607 0 00-.467-2.256l5.637-3.4a6.33 6.33 0 002.391 3.305.918.918 0 001 0 6.332 6.332 0 002.383-3.276l5.562 3.359a5.591 5.591 0 00-.423 1.517l-7.163 4.326c-.017.01-.032.021-.048.032a4.182 4.182 0 00-.345-.255.919.919 0 00-.989 0 4.2 4.2 0 00-.343.253l-1.736-1.048a.918.918 0 00-.949 1.572l1.416.855a6.05 6.05 0 00-.372.623l-8.815 5.316zm20.813 12.569l-9.518-5.748 6.4-3.868v.084a6.136 6.136 0 002.615 4.892.918.918 0 001 0 6.136 6.136 0 002.615-4.892v-.084l6.4 3.868z"
    />
    <path
      color={color}
      fill={fill}
      d="M17.767 13.054a.919.919 0 10.649.269.924.924 0 00-.649-.269z"
    />
  </svg>
);

Exploitation.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Exploitation.defaultProps = {
  color: '#000',
  fill: '#707070',
  width: 47.015,
  height: 32,
};

export default Exploitation;
