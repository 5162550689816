import React from 'react';

import './loader.style.scss';
import PropTypes from 'prop-types';

function Loader({ text }) {
  return (
    <div className="lds-container">
      <div className="lds-default">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="text-center" style={{ margin: '20px 0' }}>
        {' '}
        {text}
        {' '}
      </div>
    </div>
  );
}

Loader.propTypes = {
  text: PropTypes.string,
};

Loader.defaultProps = {
  text: 'Veuillez patienter pendant la récupération des données',
};

export default Loader;
