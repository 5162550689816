import { PdfContext } from 'utils/context';
import CoverPage from './Pages/CoverPage';
import './pdfComponent.style.scss';
import ParcelSynthesis from './Pages/ParcelSynthesis';
import GrossMarginGraph from './Pages/GrossMarginGraph';
import KeyNumberPage from './Pages/KeyNumberPage';
import CapPoints from './Pages/CapPoints';
import Constraint from './Pages/Constraint';
import ObligationRestriction from './Pages/ObligationRestriction';
import GraphPerYearPage from './Pages/GraphPerYear';
import CultureList from './Pages/CultureList';
import GraphPreviousYear from './Pages/GraphPreviousYear';
import KeyNumberNitrogenPage from './Pages/KeyNumberNitrogenPage';

const PdfComponent = ({ data, results, totalFooterMargin }) => {
  const { proposal, constraints, pac, obligationRestrictions } = data;

  //update footerMargin with value defined in PdfDocument
  const root = document.documentElement;
  root.style.setProperty('--height-pdf', `calc(21cm - ${totalFooterMargin}px)`);
  root.style.setProperty('--margin-bottom-pdf', `${totalFooterMargin}px`);

  const result = Object.entries(results.suggestions).find(([key, _]) => key === proposal);
  const suggest = result[1];
  data = { ...data, suggest: suggest };

  const hasConstraints =
    constraints.typeCoProduit.length > 0 || constraints.autres.length > 0 || constraints?.pac;
  const hasPacTable = pac.length > 0;
  const hasObligationRestriction = obligationRestrictions.length > 0;
  const hasKeyNumberNitrogen = data?.keyNumberNitrogen?.length > 0;
  return (
    <PdfContext.Provider value={data}>
      <CoverPage />
      {result && (
        <>
          <ParcelSynthesis />
          <GrossMarginGraph />
          <KeyNumberPage />
          {hasKeyNumberNitrogen && <KeyNumberNitrogenPage />}
          {hasPacTable && <CapPoints />}
          <GraphPreviousYear />
          <GraphPerYearPage />
          {hasObligationRestriction && <ObligationRestriction />}
          {hasConstraints && <Constraint />}
        </>
      )}
      <CultureList />
    </PdfContext.Provider>
  );
};

export default PdfComponent;
