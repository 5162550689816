import Button from 'components/generics/Button';
import useAuth from 'providers/Auth/useAuth';
import React, { useContext, useEffect, useState } from 'react';
import { importCsvData, importParcelsResult, importParcelsStatus } from 'services/API/Cooperative';
import { displayErrorMessage } from 'utils/tools_functions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ImportContext } from 'providers/ImportStatus/importStatus.providers';

const CooperativeCsvImport = () => {
  const [file, setFile] = useState(null);
  const { user } = useAuth();
  const [cooperativeId, setCooperativeId] = useState('');
  const navigate = useNavigate();
  const [locationRoute, setLocationRoute] = useState('cooperative');
  const { setStatus, setJobId, result, setResult } = useContext(ImportContext);

  useEffect(() => {
    let coopId = '';
    if (localStorage.getItem('cooperative')) {
      coopId = localStorage.getItem('cooperative');
    } else if (user.cooperative?.id) {
      coopId = user.cooperative.id;
    }
    if (!coopId) {
      toast.info('Selectionnez une cooperative');
      navigate('/cooperative');
    } else {
      setCooperativeId(coopId);
    }
  }, [navigate, user]);

  useEffect(() => {
    setLocationRoute(window.location.pathname.split('/')[1]);
  }, []);

  useEffect(() => {
    if (result) {
      navigate(`/${locationRoute}/import/result`);
    }
  }, [result, navigate, locationRoute]);

  const importDataFromFile = () => {
    let formData = new FormData();
    formData.append('file', file);
    importCsvData(formData, cooperativeId)
      .then((result) => {
        checkStatus(result.data.jobId);
        setJobId(result.data.jobId);
      })
      .catch((e) => {
        toast.error(displayErrorMessage('ERR_IMPORT_CSV'));
      });
  };

  const checkStatus = (jobId) => {
    const interval = setInterval(() => {
      importParcelsStatus(jobId)
        .then((resp) => {
          setStatus(resp.data.message);
          if (resp.data.message !== 'En cours') {
            getJobResult(jobId);
            clearInterval(interval);
            if (resp.data.message === 'Fini sans erreur') {
              toast.success('Importation terminée sans erreur');
            } else {
              toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT'));
            }
          }
        })
        .catch((err) => console.log(err));
    }, 1000);
  };

  const getJobResult = (jobId) => {
    importParcelsResult(jobId)
      .then((resp) => {
        setResult(resp.data);
      })
      .catch((err) => toast.error(displayErrorMessage('ERR_COMPLETE_IMPORT')));
  };

  const formatFileSize = (size) => {
    let gb = Math.trunc(size / 1000000000);
    let mb = Math.trunc(size / 1000000);
    let kb = Math.trunc(size / 1000);
    kb = kb > 9 ? kb - 10 * Math.trunc(kb / 10) : kb;
    mb = mb > 9 ? mb - 10 * Math.trunc(mb / 10) : mb;
    gb = gb > 9 ? gb - 10 * Math.trunc(gb / 10) : gb;

    return `${gb > 0 ? gb + 'go ' : ''} ${mb > 0 ? mb + 'mo ' : ''} ${kb > 0 ? kb + 'ko' : ''}`;
  };

  return (
    <div className="section">
      <div className="main_container full-height">
        <h1 className="title_section">Import des données parcellaires CSV</h1>
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItem: 'center', height: '50%' }}
        >
          {file ? (
            <div className="information-file">
              <span style={{ color: '#707070', fontSize: 20 }}>
                Nom du fichier :{' '}
                <span style={{ fontWeight: 'bold', color: '#ffbe25', fontSize: 20 }}>
                  {file.name}
                </span>
              </span>
              <span style={{ color: '#707070', fontSize: 20, marginTop: 20 }}>
                Taille du fichier :{' '}
                <span style={{ fontWeight: 'bold', color: '#ffbe25', fontSize: 20 }}>
                  {formatFileSize(file.size)}
                </span>
              </span>

              <div
                className="button_upload"
                style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}
              >
                <div style={{ padding: '40px' }}>
                  <Button text="Importer les données" onClick={() => importDataFromFile()} />
                </div>
                <div style={{ padding: '40px' }}>
                  <Button
                    text="Importer un nouveau fichier"
                    onClick={() => setFile(null)}
                    backgroundColor="#e74c3c"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <input
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
                accept="text/csv"
                id="file"
                className="input-file"
              />
              <label htmlFor="file" style={{ margin: 'auto' }}>
                Parcourir
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CooperativeCsvImport;
