import { DataGridPro, GRID_CHECKBOX_SELECTION_COL_DEF, useGridApiRef } from '@mui/x-data-grid-pro';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useSingleClickEditDataGrid from '../../components/pages/Exploitation/Comparator/useSingleClickEditDataGrid';
import { customDataGridResultTheme } from './CustomForecastDataGridUtils';
import { mediaQuery } from './CustomGenericDataGridUtils';
import { ClickAwayListener } from '@mui/material';
import { CustomNoRowsOverlay } from './CustomRotateEffectsDataGridUtils';

const CustomForecastDataGridResult = ({
  rows,
  columns,
  isLoading,
  fullSize,
  theme,
  groupingColDef,
  getTreeDataPath,
  getRowClassName,
  processRowUpdate = undefined,
  sortModel = undefined,
  treeData = false,
  simulationContext = '',
  fromSimulation = false,
  fromRotateEffects = false,
  onCellEditStop = undefined,
  handleRowModesModelChange = undefined,
  rowModesModel = undefined,
  setRowModesModel = undefined,
  fromCapPoints = false,
  isCellEditable = undefined,
  fromKeyNumberNitrogen = false,
}) => {
  const apiRef = useGridApiRef();

  const [cellModesModel, handleCellClick, handleCellModesModelChange] =
    useSingleClickEditDataGrid();

  const closeRowAfterScrollbarClick = () => {
    for (const key in rowModesModel) {
      if (
        rowModesModel[key]?.mode === 'edit' &&
        rowModesModel[key].hasOwnProperty('fromScrollBar')
      ) {
        apiRef.current.stopCellEditMode({ id: key, field: rowModesModel[key].fieldToFocus });
        setRowModesModel({});
      }
    }
  };

  return (
    <ClickAwayListener onClickAway={closeRowAfterScrollbarClick}>
      <Box
        sx={{
          height: 'auto',
        }}
        className={simulationContext ? simulationContext : 'box'}
      >
        <ThemeProvider theme={theme ?? customDataGridResultTheme}>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-main': { maxHeight: fullSize | fromRotateEffects ? 'none' : '400px' },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: fromSimulation | fromRotateEffects ? 'auto !important' : '',
                maxHeight: fromRotateEffects ? '70vh' : '100vh',
                [`@media (max-width: ${mediaQuery.breakpoints.values.lg}px)`]: {
                  maxHeight: '51vh',
                },
              },
            }}
            rows={rows}
            onCellEditStop={(params, event) => {
              if (fromRotateEffects) {
                onCellEditStop(params, event);
              }
            }}
            apiRef={apiRef}
            onRowClick={closeRowAfterScrollbarClick}
            onRowModesModelChange={handleRowModesModelChange}
            columns={columns}
            columnHeaderHeight={fromRotateEffects ? 80 : 56}
            getRowClassName={getRowClassName}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            onCellClick={handleCellClick}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => console.log(error)}
            loading={isLoading}
            disableColumnMenu
            hideFooter
            hideScrollbar
            autoHeight={fullSize ? true : false}
            sortModel={sortModel}
            treeData={treeData}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            disableColumnResize={true}
            disableColumnReorder={true}
            defaultGroupingExpansionDepth={fromKeyNumberNitrogen ? 0 : -1}
            initialState={{
              pinnedColumns: {
                left: fromSimulation ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'Parcelle'] : [],
                left: fromRotateEffects
                  ? [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'cropToPlant']
                  : [],
              },
            }}
            getEstimatedRowHeight={() => 54}
            getRowHeight={({ id, model, densityFactor }) => {
              if (fromRotateEffects && model && model.cropToPlant) {
                return 62;
              }
              if (model && fromSimulation) {
                return 80;
              }
              if (fromCapPoints) {
                return 42;
              }
              if (fromKeyNumberNitrogen) {
                if (model.hierarchy.length > 1) {
                  return 52;
                }
                return 70;
              }
              return 52;
            }}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            isCellEditable={isCellEditable}
          />
        </ThemeProvider>
      </Box>
    </ClickAwayListener>
  );
};

export default CustomForecastDataGridResult;
